import { useEffect, useState, createContext } from "react"
import { connect } from "react-redux"
import { withRouter, Switch, Route, Redirect } from "react-router-dom"
import { Button, Col, Container, Drawer, Header, Row } from "rsuite"
import CloseIcon from "@rsuite/icons/Close"
import { setCurrentUser } from "./common/redux/user/user.actions"
import {
  setCurrentAccount,
  setCurrentUserId,
  setIsAuthenticated,
  setUserPermission,
  setUserToken
} from "./common/redux/authentication/authentication.actions"
import { checkLogin, getAccount, getUserId, setLogin, setUserId } from "./common/constants"
import { GetPixel, GetUserById } from "./common/api-common"
import { getUserPermissions } from "./common/utils/Utils"
import keycloak from "./keycloak"

import Navbar from "./common/components/navbar/navbar.component"
import { useMediaQuery } from "react-responsive"
// This is global css
import "./App.scss"
import LoadingComponent from "./common/components/loading/Loading"
import NotFound from "./components/NotFound/NotFound"
import AdsManagerHeader from "./components/headers/AdsManagerHeader"
import SidebarComponent from "./components/Sidebar/SidebarComponent"
import CreateAds from "./components/CreateAds/CreateAds"
import AdsManagerDashboard from "./components/AdsManagerDashboard/AdsManagerDashboard"
import HowItWorks from "./components/HowItworks/HowItWorks"
import GetStarted from "./components/GetStarted/GetStarted"
import Adlibrary from "./pages/AdLibrary/Adlibrary"
import CreateNativeAdsBody from "./pages/CreateNativeAds/CreateNativeAdsBody"
import Providers from "./pages/provider/Providers.page"
import CampaignList from "./pages/CampaignList/CampaignList"
import { providerListAd } from "./pages/provider/api-provider"
import warningIcon from "../src/assets/svgs/warning.svg"
import UpdateAdRoll from "./pages/UpdateAdRoll/UpdateAdRoll"
import PixelModal from "./common/uploadsModal/PixelModal"
import { Footer } from "./common/components/footer/footer.component"
import UpdateFbAdRoll from "./pages/FacebookAdRoll/UpdateFbAdRoll"
import AdRollDetails from "./pages/AdRollDetails/AdRollDetails"
import WebsiteSiteCommon from "./pages/Websites/WebsiteCommon/WebsiteCommon"
import { ApiRoutes } from "./common/routes"

export const MainContext = createContext({})

function App(props: any) {
  const { setIsAuthenticated, setCurrentUserId, setUserToken, setCurrentAccount, authentication, setCurrentUser, setUserPermission } = props

  const [authenticate, setAuthenticate] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState("dashboard")
  const [pixelStatus, setPixelStatus] = useState(null)
  const [openPixelModal, setOpenPixelModal] = useState(false)
  const [text, setText] = useState("")
  const [isCollapsed, setCollapsed] = useState(false)
  const [hasCreds, setHasCreds] = useState(false)
  const [initializing, setInitializing] = useState(true)
  const [open, setOpen] = useState(false)
  // const [checkApiError, setCheckApiError] = useState(false)

  const handleSelectMenuItem = (eventKey: string) => {
    setSelectedMenuItem(eventKey)
  }

  const params = props.location.pathname
  const userId = getUserId()

  const loginCheck = () => {
    if (!checkLogin()) {
      setLogin()
      window.location.reload()
    }
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const handlePixelSetUp = () => {
    setOpenPixelModal(true)
  }
  const handleClosePixelSetUp = () => setOpenPixelModal(false)

  const checkAuthorize = () => {
    keycloak
      .init({
        onLoad: "login-required",
        promiseType: "native"
      })
      .then(async function (authenticated: any) {
        console.log(authenticated ? "authenticated" : "not authenticated")
        localStorage.setItem("token", keycloak.token)
        await getAccount(keycloak.subject)
        setCurrentUserId(keycloak.subject)
        setUserToken(keycloak.token)
        setCurrentAccount(keycloak.sessionId)
        setUserId(keycloak.subject)
        setAuthenticate(authenticated)
        loginCheck()
      })
      .catch(function () {
        console.log("failed to initialize")
        setAuthenticate(false)
      })
  }

  const getUserInfo = async (userId: string) => {
    await GetUserById(userId)
      .then((response: any) => {
        const userInfo = response.data.userDetails
        localStorage.setItem("email", userInfo.email)

        const user = {
          userId: userInfo.userId,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          avater: ApiRoutes.USER_IMAGE + userInfo.userId
        }

        setCurrentUser(user)
      })
      .catch((error) => {
        // setCheckApiError(true)
        console.log(error)
      })
  }

  const isLessThan1199px = useMediaQuery({ maxWidth: 1700 })

  const handleDrawer = () => {
    setCollapsed(true)
  }

  const handleCollapse = () => {
    setCollapsed(false)
  }

  const fetchUserPermissions = async () => {
    try {
      const permissions = await getUserPermissions()
      setUserPermission(permissions)
    } catch (error: any) {
      // setCheckApiError(true)
    }
  }

  const providerListFunc = async () => {
    try {
      setInitializing(true)
      const result = await providerListAd()
      const result2 = await GetPixel(result.data[0]?.UserProviderAssociation?.provider_id)
      localStorage.setItem("providerId", result.data[0]?.UserProviderAssociation?.provider_id)
      setHasCreds(result.data[0]?.UserProviderAssociation?.provider_id)
      setHasCreds(result.data[0]?.UserProviderAssociation?.provider_id ? true : false)
      setPixelStatus(result2.data.results.status)
      setText(result2.data.results.code)
      setInitializing(false)
    } catch (error) {
      console.log(error)
      setInitializing(false)
      setHasCreds(false)
    }
  }

  useEffect(() => {
    if (params !== "/verify-email" && params !== "/invited-user" && params !== "/user/account-confirmation") {
      checkAuthorize()
      getUserInfo(userId)
      fetchUserPermissions()
    }
  }, [userId])

  useEffect(() => {
    providerListFunc()
  }, [])

  if (authentication.isAuthenticated) {
    checkAuthorize()
    setIsAuthenticated(false)
  }

  if (authenticate)
    return (
      <MainContext.Provider value={{}}>
        {/* {checkApiError === false ? ( */}
        <Container>
          <Header>
            <Navbar />
            <AdsManagerHeader isLessThan1199px={isLessThan1199px} handleDrawer={handleDrawer} />
          </Header>

          <Switch>
            <Route exact path={["/"]}>
              <Providers initializing={initializing} hasCreds={hasCreds} />
            </Route>

            <Route exact path={["/ads"]}>
              <div style={{ display: "flex" }}>
                <div className='main-sideNav'>
                  <SidebarComponent
                    handleOpen={handleOpen}
                    open={open}
                    setCollapsed={setCollapsed}
                    onSelectMenuItem={handleSelectMenuItem}
                    selectedMenuItem={selectedMenuItem}
                  />
                </div>

                {/* <div style={{ width: "100%!", overflow: "auto", padding: "20px" }}> */}
                <div className='main-content-area'>
                  {pixelStatus !== null && (
                    <>
                      {pixelStatus === "placed_code" ? (
                        <></>
                      ) : (
                        <>
                          <div className='alertBackground'>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                              <img src={warningIcon} />
                              <p className='titlePixel'> Pixel Setup Required </p>{" "}
                            </div>

                            <div>Set up your pixel to connect your website to AdRoll. </div>

                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                              <p className='btnSetUp' onClick={handlePixelSetUp}>
                                Set Up Pixel
                              </p>
                              <a
                                style={{ display: "flex", alignItems: "center" }}
                                target='_blank'
                                href='https://help.adroll.com/hc/en-us/articles/211846028-Pixel-Troubleshooting?_gl=1%2Ahrixle%2A_ga%2AMTQ0NDg2NzUzNi4xNzAxMTQ5OTQ1%2A_ga_Z6V9VWD6DL%2AMTcwNzk4Mjc4MS4xNjcuMS4xNzA3OTgyOTcyLjAuMC4yMTE0NDc4MTk2'
                              >
                                <p className='btnSetUp'>Learn More</p>
                              </a>
                            </div>
                          </div>
                          <PixelModal
                            isLessThan1199px={isLessThan1199px}
                            text={text}
                            openPixelModal={openPixelModal}
                            handleClosePixelSetUp={handleClosePixelSetUp}
                          />
                        </>
                      )}
                    </>
                  )}

                  {selectedMenuItem === "create-ads" && <CreateAds />}
                  {selectedMenuItem === "dashboard" && <AdsManagerDashboard />}
                  {selectedMenuItem === "how-it-works" && <HowItWorks />}
                  {selectedMenuItem === "get-started" && <GetStarted />}
                  {selectedMenuItem === "campaign-list" && <CampaignList pixelStatus={pixelStatus}/>}
                  {selectedMenuItem === "native-ad" && <CreateNativeAdsBody />}
                  {selectedMenuItem === "upload-ad" && <CreateNativeAdsBody />}
                  {selectedMenuItem === "ad-library" && <Adlibrary pixelStatus={pixelStatus} hideOptions={false} />}
                </div>
              </div>
            </Route>

            <Route exact path={"/create-native-ads"}>
              <div style={{ display: "flex" }}>
                <div className='main-sideNav'>
                  <SidebarComponent
                    handleOpen={handleOpen}
                    open={open}
                    setCollapsed={setCollapsed}
                    onSelectMenuItem={handleSelectMenuItem}
                    selectedMenuItem={selectedMenuItem}
                  />
                </div>

                <div className='main-content-area'>
                  <CreateNativeAdsBody />
                </div>
              </div>
            </Route>

            <Route exact path={"/websites"}>
              <div style={{ display: "flex" }}>
                <div className='main-sideNav'>
                  <SidebarComponent
                    handleOpen={handleOpen}
                    open={open}
                    setCollapsed={setCollapsed}
                    onSelectMenuItem={handleSelectMenuItem}
                    selectedMenuItem={selectedMenuItem}
                  />
                </div>

                {/* <div>
                <SidebarComponent onSelectMenuItem={handleSelectMenuItem} selectedMenuItem={selectedMenuItem} />
              </div> */}
                <div className='main-content-area'>
                  <WebsiteSiteCommon />
                </div>
              </div>
            </Route>

            <Route path={"/ads/update-campaign"}>
              <div style={{ display: "flex" }}>
                <div className='main-sideNav'>
                  <SidebarComponent
                    handleOpen={handleOpen}
                    open={open}
                    setCollapsed={setCollapsed}
                    onSelectMenuItem={handleSelectMenuItem}
                    selectedMenuItem={selectedMenuItem}
                  />
                </div>

                <div className='main-content-area'>
                  <UpdateAdRoll />
                </div>
              </div>
            </Route>

            <Route path={"/ads/campaign/:id"}>
              <div style={{ display: "flex" }}>
                <div className='main-sideNav'>
                  <SidebarComponent
                    handleOpen={handleOpen}
                    open={open}
                    setCollapsed={setCollapsed}
                    onSelectMenuItem={handleSelectMenuItem}
                    selectedMenuItem={selectedMenuItem}
                  />
                </div>

                {/* <div>
                <SidebarComponent onSelectMenuItem={handleSelectMenuItem} selectedMenuItem={selectedMenuItem} />
              </div> */}
                <div className='main-content-area'>
                  <AdRollDetails />
                </div>
              </div>
            </Route>

            <Route path={"/ads/update-facebook-campaign"}>
              <div style={{ display: "flex" }}>
                <div className='main-sideNav'>
                  <SidebarComponent
                    handleOpen={handleOpen}
                    open={open}
                    setCollapsed={setCollapsed}
                    onSelectMenuItem={handleSelectMenuItem}
                    selectedMenuItem={selectedMenuItem}
                  />
                </div>

                <div className='main-content-area'>
                  <UpdateFbAdRoll />
                </div>
              </div>
            </Route>

            <Route path={"*"}>
              <NotFound />
            </Route>
          </Switch>
          <Footer />
        </Container>
        {/* ) : (
          <>
            <FailedResponse />
          </>
        )} */}

        <Drawer title='Create co' size='xs' placement='left' onHide={handleCollapse} show={isCollapsed}>
          <div className='drawerSidebar'>
            <Drawer.Header className='modal-header-custom'>
              <Drawer.Title style={{ paddingTop: "5px" }}>Ads Manager</Drawer.Title>
            </Drawer.Header>

            <Drawer.Body>
              <div className='main-sideNav'>
                {" "}
                <SidebarComponent
                  handleOpen={handleOpen}
                  open={open}
                  setCollapsed={setCollapsed}
                  onSelectMenuItem={handleSelectMenuItem}
                  selectedMenuItem={selectedMenuItem}
                />
              </div>
            </Drawer.Body>
          </div>
        </Drawer>
      </MainContext.Provider>
    )
  else
    return (
      <>
        {/* {checkApiError === false ? ( */}
        <LoadingComponent message='Loading' />
        {/* ) : (
          <>
            <FailedResponse />
          </>
        )} */}
      </>
    )
}

const mapStateToProps = ({ authentication }: any) => ({
  authentication
})

const mapDispatchToProps = (dispatch: any) => ({
  setIsAuthenticated: (authentication: boolean) => dispatch(setIsAuthenticated(authentication)),
  setCurrentUserId: (userId: string) => dispatch(setCurrentUserId(userId)),
  setUserToken: (token: string) => dispatch(setUserToken(token)),
  setCurrentAccount: (account: string) => dispatch(setCurrentAccount(account)),
  setCurrentUser: (user: string) => dispatch(setCurrentUser(user)),
  setUserPermission: (permission: string[]) => dispatch(setUserPermission(permission))
})

const AppRouter = withRouter(App)
export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
