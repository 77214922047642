import { Button, Col, Row } from "rsuite"
import "./howItWorks.scss"

const HowItWorks = () => {
  return (
    <>
      <div className='createAdsCard'>
        <div className='container'>
          <Row>
            <Col sm={24} md={24} lg={15}>
              <p className='monty-how-it-works-page-header'>How it works</p>
              <p className='monty-how-it-works-body-text intro-text'>
                Here's a quick peek at the campaign creation process. Our platform is powered by 10+ years of data from hundreds of
                thousands of brands and billions of shoppers. Use it to run all your campaigns, and make our expertise yours.
              </p>

              <div className='monty-how-it-works-body-text'>
                <div className='lineEnd'>
                  <span className='intro-pages-list-item-number'>1</span>
                  <span className='intro-pages-list-item-label'>Select your marketing goal or use one of our recipes</span>
                </div>
                <div className='lineEnd'>
                  <span className='intro-pages-list-item-number'>2</span>
                  <span className='intro-pages-list-item-label'>Add audiences and ads</span>
                </div>
                <div className='lineEnd'>
                  <span className='intro-pages-list-item-number'>3</span>
                  <span className='intro-pages-list-item-label'>Submit your campaign for review (up to 48hrs)</span>
                </div>
                <div className='lineEnd'>
                  <span className='intro-pages-list-item-number'>4</span>
                  <span className='intro-pages-list-item-label'>Upon approval, your campaign goes live!</span>
                </div>
              </div>
            </Col>
            <Col sm={24} md={24} lg={3}></Col>
            <Col sm={24} md={24} lg={6} style={{ paddingTop: "6%" }}>
              <img
                width='300px'
                src='https://assets1.adroll.com/rollup/ar-style-base/13.9.2/images/all-products-intro.png'
                alt='how-it-works'
              />

              <div className='monthly-how-it-works-buttons-container'>
                <Button color='cyan' appearance='ghost' to={"#!"}>
                  Cancel
                </Button>
                <Button to={"#!"} color='cyan' appearance='primary'>
                  Get Started
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default HowItWorks
