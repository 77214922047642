import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import AuthenticationReducer from "./authentication/authentication.reducer"
import UserReducer from "./user/user.reducer"
import DrawerReducer from "./drawer/drawer.reducer"
import SidebarReducer from "./sidebar/sidebar.reducer"
import Goalreducer from "./goal/goal.reducer"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["campaign", "emailState"]
}

const rootReducer = combineReducers({
  authentication: AuthenticationReducer,
  user: UserReducer,
  drawer: DrawerReducer,
  sidebar: SidebarReducer,
  goal: Goalreducer
})

export default persistReducer(persistConfig, rootReducer)
