import axios from "src/common/axios-config"

const AdTechBaseUrlDev = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  POST_PROVIDER: AdTechBaseUrlDev + "/api/v1/provider/",
  DASHBOARD: AdTechBaseUrlDev + "/api/v1/adtech/reporting-health",
  SUMMARY: AdTechBaseUrlDev + "/api/v1/adtech/campaign-summary"
}

export const postProviderAd = async (formData: any) => {
  const data = await axios.post(AdTechEndPoints.POST_PROVIDER, formData)
  return data
}

export const getProviderAd = async (id: number) => {
  try {
    const data = await axios.get(AdTechEndPoints.POST_PROVIDER + id)
    return data
  } catch (error) {}
}

export const updateProviderAd = async (id: number, formData: any) => {
  const data = await axios.put(AdTechEndPoints.POST_PROVIDER + id, formData)
  return data
}

export const disconnectProviderAd = async (id: number, payload: any) => {
  const data = await axios.patch(AdTechEndPoints.POST_PROVIDER + id, payload)
  return data
}

export const getDashboardData = async () => {
  try {
    const { data } = await axios.get(AdTechEndPoints.POST_PROVIDER)
    if (data[0]?.AdsProvider?.id) {
      const { data: dashboardData } = await axios.get(AdTechEndPoints.DASHBOARD, {
        params: {
          provider: data[0]?.AdsProvider?.id,
          date_filter: "This month"
        }
      })

      return dashboardData
    } else {
      return {}
    }
  } catch (error) {
    console.error("getting dashboard data")
  }
}

export const getCampaignStatsForDashboard = async (type: string, fromDate: string, toDate: string) => {
  try {
    const { data } = await axios.get(AdTechEndPoints.POST_PROVIDER)

    if (data[0]?.id) {
      const { data: dashboardData } = await axios.get(AdTechEndPoints.DASHBOARD, {
        params: {
          provider: data[0]?.id,
          type: type,
          from_date: fromDate,
          to_date: toDate
        }
      })

      return dashboardData
    } else {
      return {
        hasError: true
      }
    }
  } catch (error) {
    console.error("getting dashboard data")
  }
}

export const getCampaignTableStatsForDashboard = async (fromDate: string, toDate: string) => {
  try {
    const { data } = await axios.get(AdTechEndPoints.POST_PROVIDER)

    if (data[0]?.id) {
      const { data: dashboardData } = await axios.get(AdTechEndPoints.SUMMARY + `/${data[0]?.id}`, {
        params: {
          from_date: fromDate,
          to_date: toDate
        }
      })

      return dashboardData
    } else {
      return {
        hasError: true
      }
    }
  } catch (error) {
    console.error("getting dashboard data")
  }
}
