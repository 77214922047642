import DataTable from "react-data-table-component"
import audienceSvg from "../../../assets/svgs/audience.svg"
import { Toggle } from "rsuite"

const SegmentsList = (props: any) => {
  const { selectedRowsAud, handleRowSelectedAud,checkCampaignType } = props

  const columnsForAudienceList = [
    {
      name: "Audience Name",
      sortable: true,
      selector: (row: any) => {
        return <div>{row?.name}</div>
      },
      width: "580px"
    },
    {
      name: "Target of Exclude",
      sortable: true,
      selector: (row: any) => {
        return (
          <div>
            {" "}
            <Toggle defaultChecked={row?.is_active} /> Targeting{" "}
          </div>
        )
      }
    },

    {
      name: "Audience Size",
      sortable: true,
      selector: (row: any) => {
        return <div>{row?.total_emails ? <> {row?.total_emails} </> : <> - </>}</div>
      }
    },

    {
      name: "Duration",
      sortable: true,
      selector: (row: any) => {
        return <div>{row?.duration}</div>
      }
    }
  ]

  return (
    <>
      <div style={{ marginTop: "2%" }}>
        {selectedRowsAud !== undefined && selectedRowsAud.length > 0 ? (
          <>
            <DataTable columns={columnsForAudienceList as any} onSelectedRowsChange={handleRowSelectedAud} data={selectedRowsAud} />
          </>
        ) : (
          <div className='audienceBoxStyle'>
            {checkCampaignType !== "web-retargeting" ? (
              <div className='audienceBoxFlex'>
              <div>
                {" "}
                <img src={audienceSvg} alt='audience svg' />{" "}
              </div>
              <div>
                {" "}
                <h2 className='title'>Audience is Selected</h2>
                <p className='pTag'> We have automatically selected the best audience for you.</p>
              </div>
            </div>
            ) : (
              <div className='audienceBoxFlex'>
                <div>
                  {" "}
                  <img src={audienceSvg} alt='audience svg' />{" "}
                </div>
                <div>
                  {" "}
                  <h2 className='title'>Your Customized Audiences Here</h2>
                  <p className='pTag'>
                    {" "}
                    Target your customized audiences by creating new ones or choose existing ones from the buttons above.{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default SegmentsList
