import { drawerType } from "./drawer.types";

export const toggleDrawer = (toggle) => ({
  type: drawerType.TOGGLE_DRAWER,
  payload: toggle,
});

export const setDrawerActivePanel = (panel) => ({
  type: drawerType.ACTIVE_PANEL,
  payload: panel,
});

export const setDrawerName = (name) => ({
  type: drawerType.DRAWER_NAME,
  payload: name,
});
