import { useCallback, useEffect, useRef, useState } from "react"
import "./createNativeAds.scss"
import "rsuite/dist/styles/rsuite-default.css"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import webIcon from "../../assets/svgs/webIcon.svg"
import fbInstagramIcon from "../../assets/svgs/facebookInsta.svg"
import imgIcon from "../../assets/svgs/imgIcon.svg"
import imgLowOpacity from "../../assets/svgs/imgLowOpacity.svg"
import circleIcon from "../../assets/svgs/cricleBackground.svg"
import flagIcon from "../../assets/svgs/flagIcon.svg"
import earthIcon from "../../assets/svgs/earth.svg"
import likeIcon from "../../assets/svgs/like.svg"
import commentIcon from "../../assets/svgs/comment.svg"
import shareIcon from "../../assets/svgs/share.svg"
import { Alert, Button, Col, Dropdown, Form, Input, Panel, Row, Schema, Uploader } from "rsuite"
import { adChannel } from "src/components/mocks/mock-data"
import CustomField from "src/components/custom-field/custom-field.component"
import { Constants } from "src/common/constants"
import { AspectRatio } from "react-aspect-ratio"
import "react-aspect-ratio/aspect-ratio.css"
import { useHistory } from "react-router-dom"
import { convertImageToBase64 } from "src/common/utils/Utils"
import { createNativeAd, postImage } from "./api-nativeAdCreate"
import Cropper from "react-easy-crop"

interface channelNameType {
  channelName: string
}

interface CroppedAreaPixels {
  x: number
  y: number
  width: number
  height: number
}

interface CroppedAreaPixels1 {
  x: number
  y: number
  width: number
  height: number
}

interface CroppedAreaPixels3 {
  x: number
  y: number
  width: number
  height: number
}

interface Props {
  croppedAreaPixels: CroppedAreaPixels | null
  imgUploadForDisplay: string | null
  setCroppedImage: (url: string) => void
  setCrop: (crop: boolean) => void
  setDoneImgCroppedFlag: (flag: boolean) => void
}

const model = Schema.Model({
  title: Schema.Types.StringType().isRequired("Title field is required."),

  description: Schema.Types.StringType().isRequired("Description field is required."),

  destinationUrl: Schema.Types.StringType()
    .addRule((value, data) => {
      if (!value.startsWith("https://")) {
        return false
      }
      return true
    }, "Destination URL must start with 'https://'")
    .isRequired("Destination Url field is required."),

  brandName: Schema.Types.StringType().isRequired("Brand name field is required.")
})

const CreateNativeAdsBody = (props: any) => {
  const { flag, handleClosenativeAd } = props

  const initialValue: any = {
    user_id: "",
    cart_info: "",
    customer_add: "",
    customer_phone: "",
    currency: ""
  }

  const formRef: any = useRef(null)
  const [selectedGoal, setSelectedGoal] = useState("web")
  const [, setChannelNameState] = useState<channelNameType[] | undefined>()
  const [formError, setFormError] = useState<any>({})
  const [formValue, setFormValue] = useState(initialValue)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [destinationUrl, setDestinationUrl] = useState("")
  const [imgUploadForDisplay, setImgUploadForDisplay] = useState("")
  const [brandName, setBrandName] = useState("")
  const [imgUpload, setImgUpload] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)
  const [crop, setCrop] = useState(false)
  const [crop1, setCrop1] = useState(false)
  const [crop3, setCrop3] = useState(false)
  const [imgCropImg, setImgCropImg] = useState(false)
  const [cropImg, setCropImg] = useState({ x: 0, y: 0 })
  const [cropImg1, setCropImg1] = useState({ x: 0, y: 0 })
  const [cropImg3, setCropImg3] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [zoom1, setZoom1] = useState(1)
  const [zoom3, setZoom3] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedAreaPixels | null>()
  const [croppedAreaPixels1, setCroppedAreaPixels1] = useState<CroppedAreaPixels1 | null>(null)
  const [croppedAreaPixels3, setCroppedAreaPixels3] = useState<CroppedAreaPixels3 | null>(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedImage1, setCroppedImage1] = useState(null)
  const [croppedImage3, setCroppedImage3] = useState(null)
  const [doneImgCroppedFlag, setDoneImgCroppedFlag] = useState(false)
  const [doneImgCroppedFlag1, setDoneImgCroppedFlag1] = useState(false)
  const [doneImgCroppedFlag3, setDoneImgCroppedFlag3] = useState(false)
  const [fileListImg, setFileListImg] = useState<any>([])
  const history = useHistory()

  async function fetchBlobFromUrl(url: any) {
    const response = await fetch(url)
    const blob = await response.blob()
    const file = new File([blob], "croppedImage.png", { type: blob.type })
    return file
  }

  const handleGoalClick = (goal: any) => {
    setSelectedGoal(goal)
  }

  const handleTitleChange = (value: string) => {
    setTitle(value)
  }

  const handleDescriptionChange = (value: string) => {
    setDescription(value)
  }

  const handleDestinationUrlChange = (value: string) => {
    setDestinationUrl(value)
  }

  const handleBrandNameChange = (value: string) => {
    setBrandName(value)
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onCropComplete1 = useCallback((croppedArea, croppedAreaPixels1) => {
    setCroppedAreaPixels1(croppedAreaPixels1)
  }, [])

  const onCropComplete3 = useCallback((croppedArea, croppedAreaPixels3) => {
    setCroppedAreaPixels3(croppedAreaPixels3)
  }, [])

  const getChannelName = async () => {
    setChannelNameState(adChannel)
  }

  const handleCancelButtonClick = () => {
    window.location.reload()
  }

  const handleImageUpload = async (fileListImg: any) => {
    setCrop1(true)
    setCrop(true)
    setCrop3(true)
    setImgCropImg(true)
    if (fileListImg && fileListImg.length > 0) {
      const image = fileListImg[fileListImg.length - 1]
      const imageUrl = URL.createObjectURL(image.blobFile)
      setImgUploadForDisplay(imageUrl)

      if (fileListImg.length > 1) {
        setFileListImg([fileListImg[fileListImg.length - 1]])
      } else {
        setFileListImg(fileListImg)
      }

      // const base64Image = (await convertImageToBase64(image.blobFile)) as string
      // setImgUpload(base64Image)
      // uploadImage(base64Image)
    } else {
      Alert.info("No image selected.")
      setImgUploadForDisplay("")
      setImgUpload("")
    }
  }

  const handleSelect = (eventKey: any, event: any) => {
    if (eventKey === "nativeAds") {
      history.push("/#!")
    }
  }

  const SizeDropdown = (props: any) => (
    <Dropdown appearance='default' onSelect={handleSelect} {...props}>
      <Dropdown.Item eventKey='thirdPartyAds'>Desktop Right Column</Dropdown.Item>
      <Dropdown.Item eventKey='nativeAds'>Mobile News Feed</Dropdown.Item>
      <Dropdown.Item eventKey='dynamicAds'>Desktop Marketplace Feed</Dropdown.Item>
    </Dropdown>
  )

  const handleSubmit = async () => {
    setIsLoading(true)
    if (!formRef.current.check()) {
      Alert.error("form invalid", Constants.Error_Alert_Constant_Time)
      return
    }
    setIsLoading(true)
    const croppedImageVar = await fetchBlobFromUrl(crop ? imgUploadForDisplay : croppedImage)
    const croppedImageVar1 = await fetchBlobFromUrl(crop1 ? imgUploadForDisplay : croppedImage1)
    const croppedImageVar3 = await fetchBlobFromUrl(crop3 ? imgUploadForDisplay : croppedImage3)

    const imgDimension1Payload = await convertImageToBase64(croppedImageVar1, 600, 600)
    const imgDimension2Payload = await convertImageToBase64(croppedImageVar, 600, 500)
    const imgDimension3Payload = await convertImageToBase64(croppedImageVar3, 600, 315)

    const { destinationUrl, title, description, brandName } = formValue

    const subtypes = ["NATIVE_AD_600x600", "NATIVE_AD_600x500", "NATIVE_AD_600x315"]
    try {
      for (const subtype of subtypes) {
        const payload = {
          name: title,
          status: "created",
          type: "native",
          subtype: subtype,
          is_multi_share_optimized: false,
          multiple_products: 0,
          read_only: false,
          is_dynamic: false,
          do_not_forward: false,
          assets: [
            {
              name: "Native ad destination URL",
              type: "text",
              subtype: "url",
              value: destinationUrl
            },
            {
              name: "Native ad image",
              type: "image",
              subtype: "ad",
              file:
                subtype === "NATIVE_AD_600x600"
                  ? imgDimension1Payload
                  : subtype === "NATIVE_AD_600x500"
                    ? imgDimension2Payload
                    : imgDimension3Payload
            },
            {
              name: "Native ad title",
              type: "text",
              subtype: "title",
              value: title
            },
            {
              name: "Native ad description",
              type: "text",
              subtype: "message",
              value: description
            },
            {
              name: "Native ad brand name",
              type: "text",
              subtype: "brand_name",
              value: brandName
            }
          ],
          ad_parameters: []
        }

        const formSubmit = await createNativeAd(payload)

        if (!(formSubmit.status === 201 || formSubmit.status === 200)) {
          Alert.error("error")
          setIsLoading(false)
          return
        }
      }

      Alert.success("Property create successfully")
      setIsLoading(false)
      if (!flag) {
        history.push("/ads")
      } else {
        handleClosenativeAd(false)
      }
    } catch (error) {
      Alert.error("Error in API request")
      console.log("Error")
      setIsLoading(false)
    }
  }

  const handleCrop = () => {
    setCrop(true)
  }

  const handleCrop1 = () => {
    setCrop1(true)
  }

  const handleCrop3 = () => {
    setCrop3(true)
  }

  const generateCroppedImage = () => {
    if (!croppedAreaPixels || !imgUploadForDisplay) return
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const image = new Image()

    if (!ctx) {
      console.error("Failed to get 2D context")
      return
    }

    image.src = imgUploadForDisplay

    image.onload = () => {
      canvas.width = croppedAreaPixels.width
      canvas.height = croppedAreaPixels.height
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      )

      canvas.toBlob((blob: any) => {
        const croppedImageUrl: any = URL.createObjectURL(blob)
        setCroppedImage(croppedImageUrl)
      })
    }
    setCrop(false)
    setDoneImgCroppedFlag(true)
  }

  const generateCroppedImage1 = () => {
    if (!croppedAreaPixels1 || !imgUploadForDisplay) return
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const image = new Image()

    image.src = imgUploadForDisplay

    if (!ctx) {
      console.error("Failed to get 2D context")
      return
    }

    image.onload = () => {
      canvas.width = croppedAreaPixels1.width
      canvas.height = croppedAreaPixels1.height
      ctx.drawImage(
        image,
        croppedAreaPixels1.x,
        croppedAreaPixels1.y,
        croppedAreaPixels1.width,
        croppedAreaPixels1.height,
        0,
        0,
        croppedAreaPixels1.width,
        croppedAreaPixels1.height
      )

      canvas.toBlob((blob: any) => {
        const croppedImageUrl1: any = URL.createObjectURL(blob)
        setCroppedImage1(croppedImageUrl1)
      })
    }
    setCrop1(false)
    setDoneImgCroppedFlag1(true)
  }

  const generateCroppedImage3 = () => {
    if (!croppedAreaPixels3 || !imgUploadForDisplay) return
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const image = new Image()

    image.src = imgUploadForDisplay

    if (!ctx) {
      console.error("Failed to get 2D context")
      return
    }

    image.onload = () => {
      canvas.width = croppedAreaPixels3.width
      canvas.height = croppedAreaPixels3.height
      ctx.drawImage(
        image,
        croppedAreaPixels3.x,
        croppedAreaPixels3.y,
        croppedAreaPixels3.width,
        croppedAreaPixels3.height,
        0,
        0,
        croppedAreaPixels3.width,
        croppedAreaPixels3.height
      )

      canvas.toBlob((blob: any) => {
        const croppedImageUrl3: any = URL.createObjectURL(blob)
        setCroppedImage3(croppedImageUrl3)
      })
    }
    setCrop3(false)
    setDoneImgCroppedFlag3(true)
  }

  useEffect(() => {}, [formValue])

  useEffect(() => {
    getChannelName()
  }, [])

  return (
    <>
      <div className='parentNativeAds'>
        <p className={flag === "flag" ? "adtextModal" : "adtext"}>Ad Library </p>

        <div className='NativeAdCard' style={flag === "flag" ? { marginTop: "0px" } : { marginTop: "19px" }}>
          <br />
          <Row>
            <Col sm={24} md={24} lg={10}>
              <div className='nativeOptions'>
                <div
                  className={`nativeOptions-btn goal-web ${selectedGoal === "web" ? "active" : ""}`}
                  onClick={() => handleGoalClick("web")}
                >
                  <Row>
                    <Col sm={11} md={11} lg={7}>
                      {" "}
                      <img src={webIcon} alt='web icon' />{" "}
                    </Col>
                    <Col sm={11} md={11} lg={16}>
                      <div className='cardName'> Web </div>

                      <div className='connectText'>Connect</div>
                    </Col>
                    <Col sm={1} md={1} lg={1}>
                      <div className='tick'></div>
                    </Col>
                  </Row>
                </div>
              </div>

              <Panel style={{ width: "100%" }}>
                <Form
                  model={model}
                  className='form-c'
                  fluid
                  ref={formRef}
                  onCheck={(formError) => {
                    setFormError(formError)
                  }}
                  onChange={(formValue) => {
                    setFormValue({
                      ...formValue
                    })
                  }}
                >
                  <div className='imgUploadClass'>
                    <Uploader
                      listType='picture-text'
                      name='imgUpload'
                      className='uploadImg'
                      onChange={handleImageUpload}
                      autoUpload={false}
                      fileList={fileListImg}
                      accept='.jpg, .jpeg, .png, image/jpg, image/jpeg, image/png'
                    >
                      <div>
                        <Button>Upload Image...</Button>
                        {/* {!imgUploadForDisplay && (
                          <span>
                            {" "}
                            <img src={imgIcon} alt='img icon' />
                            Add your images{" "}
                          </span>
                        )} */}
                      </div>
                    </Uploader>
                    {/* <img height='40px' width='100px' style={{objectFit:'cover'}} src={imgUploadForDisplay}/> */}
                  </div>
                  <CustomField
                    name='title'
                    placeholder='Title'
                    accepter={Input}
                    size='lg'
                    value={title}
                    error={formError.title}
                    onChange={handleTitleChange}
                  />

                  <CustomField
                    name='description'
                    placeholder='Description'
                    accepter={Input}
                    componentClass='textarea'
                    rows={5}
                    value={description}
                    error={formError.description}
                    onChange={handleDescriptionChange}
                  />

                  <CustomField
                    name='destinationUrl'
                    placeholder='Destination url (eg: https://example.com)'
                    accepter={Input}
                    size='lg'
                    value={destinationUrl}
                    error={formError.destinationUrl}
                    onChange={handleDestinationUrlChange}
                  />

                  <CustomField
                    name='brandName'
                    placeholder='Brand Name'
                    accepter={Input}
                    size='lg'
                    value={brandName}
                    error={formError.brandName}
                    onChange={handleBrandNameChange}
                  />

                  <Row style={{ marginTop: "22px" }}>
                    {flag !== "flag" && (
                      <>
                        <Col sm={11} md={11} lg={11}>
                          <Button onClick={handleCancelButtonClick} className='btnBack'>
                            Cancel
                          </Button>
                        </Col>
                        <Col sm={2} md={2} lg={2}>
                          <br />
                        </Col>
                      </>
                    )}
                    <Col sm={11} md={11} lg={11}>
                      <Button className='btnCreate' onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? "Creating..." : "Create"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Col>

            <Col sm={24} md={24} lg={1} className='verticalLine'>
              <div className='vl'></div>
            </Col>

            <Col sm={24} md={24} lg={13} style={{ padding: "20px" }}>
              {selectedGoal === "web" ? (
                <>
                  <p className='imgReSizeP'>Native Ads</p>
                  {/* <img src={imgUploadForDisplay}/> */}
                  <hr />

                  <div className='cardFlex'>
                    <div>
                      <p className='imgReSizeTitle1'>Image Size 600x600</p>

                      <div className='cardImg1'>
                        <div className='cardImg1Child'>
                          <div>
                            <div className='secondBorder'>
                              {imgUploadForDisplay && crop1 === false && doneImgCroppedFlag1 === false ? (
                                <div className='imgOnly'>
                                  {" "}
                                  <img src={imgUploadForDisplay} className='imgCss' alt='' />
                                </div>
                              ) : crop1 ? (
                                <div className='imgOnly'>
                                  <Cropper
                                    image={imgUploadForDisplay}
                                    crop={cropImg1}
                                    zoom={zoom1}
                                    aspect={600 / 600}
                                    onCropChange={setCropImg1}
                                    onCropComplete={onCropComplete1}
                                    onZoomChange={setZoom1}
                                  />
                                </div>
                              ) : doneImgCroppedFlag1 ? (
                                <>
                                  <div className='imgOnlyForFinalImg'>
                                    {" "}
                                    {croppedImage1 && <img src={croppedImage1} width='300px' height='300px' alt='croppedImageUrl' />}{" "}
                                  </div>{" "}
                                </>
                              ) : (
                                <div className='iconPosition'>{/* <img src={imgLowOpacity} alt='' /> */}</div>
                              )}
                              <div className='cropBtn'>
                                <div className='insideFormDesc'>
                                  <div>
                                    <p className='titleAd' title={title}>
                                      {title.length > 0 ? (title.length > 22 ? `${title.slice(0, 21)}...` : title) : "Title"}
                                    </p>
                                  </div>
                                  <div>
                                    <p className='adBy'>Ad By {brandName.length > 0 ? brandName : "BrandName"}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {!crop1 && imgCropImg && (
                          <div className='roboTitle'>
                            {" "}
                            <div style={{ textAlign: "center", cursor: "pointer" }} onClick={handleCrop1}>
                              Crop
                            </div>
                          </div>
                        )}
                        {crop1 && (
                          <div className='roboTitle'>
                            {" "}
                            <div style={{ textAlign: "center", cursor: "pointer" }} onClick={generateCroppedImage1}>
                              Done
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className='imgReSizeTitle2'>Image Size 600x500</p>

                      <div className='cardImg2'>
                        <div className='cardImg2Child'>
                          <div>
                            <div className='secondBorder'>
                              {imgUploadForDisplay && crop === false && doneImgCroppedFlag === false ? (
                                <div className='imgOnly'>
                                  {" "}
                                  <img src={imgUploadForDisplay} className='imgCss' alt='' />
                                </div>
                              ) : crop ? (
                                <div className='imgOnly'>
                                  <Cropper
                                    image={imgUploadForDisplay}
                                    crop={cropImg}
                                    zoom={zoom}
                                    aspect={600 / 500}
                                    onCropChange={setCropImg}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                  />
                                </div>
                              ) : doneImgCroppedFlag ? (
                                <>
                                  <div className='imgOnlyForFinalImg' style={{ overflow: "hidden" }}>
                                    {" "}
                                    {croppedImage && <img src={croppedImage} width='300px' height='250px' alt='croppedImageUrl' />}{" "}
                                  </div>{" "}
                                </>
                              ) : (
                                <div className='iconPosition'>{/* <img src={imgLowOpacity} alt='' /> */}</div>
                              )}
                              <div className='cropBtn'>
                                {!crop && imgCropImg && <div onClick={handleCrop}>Crop</div>}
                                {crop && <div onClick={generateCroppedImage}>Done</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='insideFormDesc'>
                          <div>
                            <p className='titleAd' title={title}>
                              {title.length > 0 ? (title.length > 22 ? `${title.slice(0, 21)}...` : title) : "Title"}
                            </p>

                            <p className='descriptionAd' title={description}>
                              {description.length > 0
                                ? description.length > 50
                                  ? `${description.slice(0, 50)}...`
                                  : description
                                : "Description"}
                            </p>
                          </div>
                          <div>
                            <p className='adBy'>Ad By {brandName.length > 0 ? brandName : "BrandName"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className='imgReSizeTitle3'>Image Size 600x315</p>
                  <div className='cardImg3'>
                    <div className='cardImg3Child'>
                      <div>
                        <div className='secondBorder'>
                          {imgUploadForDisplay && crop3 === false && doneImgCroppedFlag3 === false ? (
                            <div className='imgOnly'>
                              {" "}
                              <img src={imgUploadForDisplay} className='imgCss' alt='' />
                            </div>
                          ) : crop3 ? (
                            <div className='imgOnly'>
                              <Cropper
                                image={imgUploadForDisplay}
                                crop={cropImg3}
                                zoom={zoom3}
                                aspect={600 / 315}
                                onCropChange={setCropImg3}
                                onCropComplete={onCropComplete3}
                                onZoomChange={setZoom3}
                              />
                            </div>
                          ) : doneImgCroppedFlag3 ? (
                            <>
                              <div className='imgOnlyForFinalImg'>
                                {" "}
                                {croppedImage3 && <img src={croppedImage3} width='300px' height='155px' alt='croppedImageUrl' />}{" "}
                              </div>{" "}
                            </>
                          ) : (
                            <div className='iconPosition'>{/* <img src={imgLowOpacity} alt='' /> */}</div>
                          )}
                          <div className='cropBtn'>
                            {!crop3 && imgCropImg && <div onClick={handleCrop3}>Crop</div>}
                            {crop3 && <div onClick={generateCroppedImage3}>Done</div>}
                          </div>
                        </div>
                      </div>
                      {/* <div className='roboTitle'>
                          {" "}
                          <p>Ad By Roboket</p>
                        </div> */}
                    </div>
                  </div>

                  <div className='roboTile'>
                    <div>
                      <p className='titleAd' title={title}>
                        {title.length > 0 ? (title.length > 22 ? `${title.slice(0, 21)}...` : title) : "Title"}
                      </p>

                      <p className='descriptionAd' title={description}>
                        {description.length > 0
                          ? description.length > 50
                            ? `${description.slice(0, 50)}...`
                            : description
                          : "Description"}
                      </p>
                    </div>
                    <div>
                      <p className='adByAd'>Ad By {brandName.length > 0 ? brandName : "BrandName"}</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className='imgReSizeP'>Facebook & Instagram</p>
                  <hr />

                  <SizeDropdown className='dropdownSize' title='Desktop news feed' size='lg' />
                  <div className='fbSponsor' style={{ padding: "20px 0px" }}>
                    <div className='iconTool'>
                      <img className='circleAshColor' src={circleIcon} alt='circleAshColor' />
                      <img
                        className='flagIconStyle'
                        src={flagIcon}
                        alt='flagIcon'
                        style={{
                          position: "absolute",
                          top: 11,
                          left: 11,
                          width: "30px",
                          height: "30px"
                        }}
                      />
                    </div>

                    <div>
                      <p className='fbTitle'>Roboket</p>
                      <div className='fbSponsor'>
                        {" "}
                        <div>
                          {" "}
                          <p className='sponsorText'>Sponsored</p>
                        </div>{" "}
                        <div>
                          <img src={earthIcon} alt='earth icon' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Panel shaded bordered bodyFill style={{ display: "inline-block", width: 512 }}>
                    {/* <p className='cardTitle'>Sponsored</p> */}
                    <Panel>
                      <img src={imgUploadForDisplay} width='470' height='278' alt='card-img' />
                    </Panel>
                    <hr />
                    <Panel header='WWW.ROBOKET.COM'>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <p className='tilePanel'>Title</p>

                        <Button>Apply now</Button>
                      </div>
                    </Panel>

                    <Panel>
                      <div className='likeShareIcon'>
                        <div className='displayed'>
                          <img src={likeIcon} alt='likeIcon' />
                          Like
                        </div>
                        <div className='displayed'>
                          <img src={commentIcon} alt='commentIcon' />
                          Comment
                        </div>
                        <div className='displayed'>
                          <img src={shareIcon} alt='shareIcon' />
                          Share
                        </div>
                      </div>
                    </Panel>
                  </Panel>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
export default CreateNativeAdsBody
