import axios from "src/common/axios-config"
import { useProviderId } from "src/common/constants"

const AdTechBaseUrl = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  GET_ALL_ADS: AdTechBaseUrl + "/api/v1/adtech/ads/"
}

export const fetchAllAds = async (currentPage: number, page_size: number, sort_by: string) => {
  const providerId = await useProviderId()
  try {
    let data = await axios.get(AdTechEndPoints.GET_ALL_ADS + providerId, {
      params: {
        page: currentPage,
        page_size: page_size,
        sort_by: sort_by
      }
    })
    return {
      response: data.data
    }
  } catch (error) {
    return {
      response: []
    }
  }
}
