import { AxiosResponse } from "axios";
import axios from "../../common/axios-config";
import { ApiRoutes } from "../../common/routes";
import { INotification } from "./notification-type";

export async function GetPaginatedNotification(
  page: number,
  itemPerPage: number
) {
  try {
    const params = {
      "items-per-page": itemPerPage,
      "page-number": page,
    };
    const response: AxiosResponse<INotification> = await axios.get(
      ApiRoutes.GET_NOTIFICATION,
      { params: params }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {} as INotification;
  }
}
