import React, { useEffect, useState } from "react"
import "./websiteCommon.scss"
import { Alert, CheckPicker, DatePicker, DateRangePicker, Nav } from "rsuite"
import WebsiteSiteAudience from "../WebsiteSiteAudience/WebsiteSiteAudience"
import ConnectedAudience from "../ConnectedAudience/ConnectedAudience"
import DeviceInsights from "../DeviceInsights/DeviceInsights"
import Insights from "../Insights/Insights"
import { formatDate, formatedStringDate, getLastNDaysDate, getTodayDate } from "src/common/utils/Utils"
import { getInsights, getSegmentsReporting, getVisitorsReporting } from "./webApiList"
import siteAudIcon from "../../../assets/svgs/siteAudience.svg"
import insightIcon from "../../../assets/svgs/insight.svg"
import CommonDateRangePicker from "src/common/commonDateRangePicker/common-dateRangePicker"

type Props = {}
const styles = {
  //   marginBottom: 50
}
const { afterToday } = DateRangePicker
const audienceTitle: any = {
  site_audience: "Website Audience",
  connected_audience: "Connected Audience ",
  insights: "Insights",
  device_insights: "Cross-Device Insights"
}

const WebsiteSiteCommon = (props: Props) => {
  const [selectTabs, setSelectedTabs] = useState("site_audience")
  const [dateFilterRange, setDateFilterRange] = useState<any>({ fromDate: getLastNDaysDate(30), toDate: getTodayDate() })
  const [load, setLoad] = useState<any>({ allowChart: false, allowTable: false })
  const [allowDateRange, setAllowDateRange] = useState(false)
  const [chartData, setChartData] = useState<any>([])
  const [audienceData, setAdudienceData] = useState<any>([])
  const [audienceTableData, setAdudiencTableData] = useState([])

  const [loading, setLoading] = useState<any>({
    device: false,
    multi: false,
    os: false,
    web: false,
    referrer: false,
    language: false,
    country: false
  })
  const [deviceData, setDeviceData] = useState<any[]>([])
  const [multipleData, setMultipleData] = useState<any[]>([])
  const [osData, setOsData] = useState<any[]>([])
  const [webData, setWebData] = useState<any[]>([])
  const [referrerData, setReferrerData] = useState<any[]>([])
  const [languageData, setLanguageData] = useState<any[]>([])
  const [countryData, setCountryData] = useState<any[]>([])

  const handleSelect = (activeKey: string) => {
    setSelectedTabs(activeKey)
  }
  const handleDateRange = (dateRange: any) => {
    if (dateRange && dateRange?.length === 2) {
      const formattedFromDate = formatDate(dateRange[0])
      const formattedToDate = formatDate(dateRange[1])
      setDateFilterRange({
        fromDate: formattedFromDate,
        toDate: formattedToDate
      })
    }
  }

  const getVisitorReporting = async (q_data: any) => {
    try {
      setLoad((prevLoad: any) => ({ ...prevLoad, allowChart: true }))
      const result = await getVisitorsReporting(q_data)
      let data = result.data || []
      setAdudienceData(data)
      const chartData = data?.label?.map((date: string, index: number) => ({
        name: date,
        visitors: data?.visitors[index],
        page_view_visitors: data?.page_view_visitors[index]
      }))
      setChartData(chartData)
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoad((prevLoad: any) => ({ ...prevLoad, allowChart: false }))
    }
  }
  const getSegmentReporting = async () => {
    try {
      setLoad((prevLoad: any) => ({ ...prevLoad, allowTable: true }))
      const result: any = await getSegmentsReporting()
      let data = result.data || []
      setAdudiencTableData(data?.segments)
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoad((prevLoad: any) => ({ ...prevLoad, allowTable: false }))
    }
  }

  useEffect(() => {
    if (dateFilterRange.fromDate?.length > 0 && dateFilterRange.toDate?.length) {
      getVisitorReporting(dateFilterRange)
    }
    getSegmentReporting()
  }, [...Object.values(dateFilterRange)])

  const getDevice = async (q_data: string) => {
    try {
      setLoading((prevLoad: any) => ({ ...prevLoad, device: true }))
      const result = await getInsights(q_data)
      let data = result.data?.data?.data?.device || []
      setDeviceData(data?.map((item: any) => ({ name: item?.category, value: item["%"] })))
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoading((prevLoad: any) => ({ ...prevLoad, device: false }))
    }
  }
  const getMultiple = async (q_data: string) => {
    try {
      setLoading((prevLoad: any) => ({ ...prevLoad, multi: true }))
      const result = await getInsights(q_data)
      let data = result.data?.data?.data?.devicetype || []
      setMultipleData(data?.map((item: any) => ({ name: item?.category, value: item["%"] })))
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoading((prevLoad: any) => ({ ...prevLoad, multi: false }))
    }
  }
  const getOs = async (q_data: string) => {
    try {
      setLoading((prevLoad: any) => ({ ...prevLoad, os: true }))
      const result = await getInsights(q_data)
      let data = result.data?.data?.data?.os || []
      setOsData(data?.map((item: any) => ({ name: item?.category, value: item["%"] })))
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoading((prevLoad: any) => ({ ...prevLoad, os: false }))
    }
  }
  const getBrowser = async (q_data: string) => {
    try {
      setLoading((prevLoad: any) => ({ ...prevLoad, web: true }))
      const result = await getInsights(q_data)
      let data = result.data?.data?.data?.browser || []
      setWebData(data?.map((item: any) => ({ name: item?.category, value: item["%"] })))
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoading((prevLoad: any) => ({ ...prevLoad, web: false }))
    }
  }
  const getReferrer = async (q_data: string) => {
    try {
      setLoading((prevLoad: any) => ({ ...prevLoad, referrer: true }))
      const result = await getInsights(q_data)
      let data = result.data?.data?.data?.referrer || []
      setReferrerData(data?.map((item: any) => ({ name: item?.category, value: item["%"] })))
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoading((prevLoad: any) => ({ ...prevLoad, referrer: false }))
    }
  }
  const getLanguage = async (q_data: string) => {
    try {
      setLoading((prevLoad: any) => ({ ...prevLoad, language: true }))
      const result = await getInsights(q_data)
      let data = result.data?.data?.data?.lang || []
      setLanguageData(data?.map((item: any) => ({ name: item?.category, value: item["%"] })))
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoading((prevLoad: any) => ({ ...prevLoad, language: false }))
    }
  }
  const getCountry = async (q_data: string) => {
    try {
      setLoading((prevLoad: any) => ({ ...prevLoad, country: true }))
      const result = await getInsights(q_data)
      let data = result.data?.data?.data?.countrycode || []
      setCountryData(data?.map((item: any) => ({ name: item?.category, value: item["%"] })))
    } catch (error) {
      Alert.error("Error")
    } finally {
      setLoading((prevLoad: any) => ({ ...prevLoad, country: false }))
    }
  }
  useEffect(() => {
    getDevice("device")
    getMultiple("devicetype")
    getOs("os")
    getBrowser("browser")
    getReferrer("referrer")
    getLanguage("lang")
    getCountry("countrycode")
  }, [])

  return (
    <div className='web__websiteCommon'>
      <div className='web__websiteTabsArea'>
        <Nav appearance='subtle' activeKey={selectTabs} onSelect={handleSelect} style={styles}>
          <Nav.Item eventKey='site_audience'>
            {" "}
            <div className='iconTextDashboard'>
              {" "}
              <img src={siteAudIcon} alt='site audience' /> Site Audience{" "}
            </div>
          </Nav.Item>
          {/* <Nav.Item eventKey='connected_audience'>Connected Audience</Nav.Item> */}
          <Nav.Item eventKey='insights'>
            {" "}
            <div className='iconTextDashboard'>
              {" "}
              <img src={insightIcon} alt='insight audience' /> Insights{" "}
            </div>
          </Nav.Item>
          {/* <Nav.Item eventKey='device_insights'>Cross-Device Insights</Nav.Item> */}
        </Nav>
      </div>
      <div className='web__siteCommonHeader'>
        <div className='ads-metrics-title ads-matrics-left'>
          <h4 className='adtext'>{audienceTitle[selectTabs]}</h4>
          {selectTabs === "site_audience" && (
            <p className='subAdtext'>
              *Showing data for {formatedStringDate(dateFilterRange?.fromDate)} ~ {formatedStringDate(dateFilterRange?.toDate)}
            </p>
          )}
        </div>
        <div className='web__siteCommonRightSiteHeader'>
          {selectTabs === "site_audience" && (
            <>
              <div className='ads-matrics-right'>
                <button onClick={() => setAllowDateRange(!allowDateRange)} className='transparent-button-new'>
                  <svg
                    style={{ marginRight: "10px" }}
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                  >
                    <path
                      d='M1 2.3C1 2.01997 1 1.87996 1.0545 1.773C1.10243 1.67892 1.17892 1.60243 1.273 1.5545C1.37996 1.5 1.51997 1.5 1.8 1.5H10.2C10.48 1.5 10.62 1.5 10.727 1.5545C10.8211 1.60243 10.8976 1.67892 10.9455 1.773C11 1.87996 11 2.01997 11 2.3V2.6347C11 2.7691 11 2.83629 10.9836 2.89878C10.969 2.95415 10.9451 3.00662 10.9128 3.05388C10.8763 3.10722 10.8255 3.15123 10.7239 3.23925L7.52606 6.01075C7.42449 6.09877 7.37371 6.14278 7.33725 6.19612C7.30493 6.24338 7.28098 6.29585 7.26642 6.35122C7.25 6.41371 7.25 6.4809 7.25 6.6153V9.22919C7.25 9.32697 7.25 9.37586 7.23423 9.41813C7.22029 9.45548 7.19763 9.48895 7.16813 9.51576C7.13474 9.54611 7.08934 9.56426 6.99856 9.60058L5.29856 10.2806C5.11478 10.3541 5.0229 10.3908 4.94914 10.3755C4.88463 10.3621 4.82803 10.3238 4.79163 10.2689C4.75 10.2061 4.75 10.1071 4.75 9.90919V6.6153C4.75 6.4809 4.75 6.41371 4.73358 6.35122C4.71902 6.29585 4.69507 6.24338 4.66275 6.19612C4.62629 6.14278 4.57551 6.09877 4.47394 6.01075L1.27606 3.23925C1.17449 3.15123 1.12371 3.10722 1.08725 3.05388C1.05493 3.00662 1.03098 2.95415 1.01642 2.89878C1 2.83629 1 2.7691 1 2.6347V2.3Z'
                      stroke='#1D3D70'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                  Filter
                </button>
                {allowDateRange && (
                  <CommonDateRangePicker
                    allowDateRange={allowDateRange}
                    fromDate={dateFilterRange.fromDate}
                    toDate={dateFilterRange.toDate}
                    handleDateRange={handleDateRange}
                  />
                )}
              </div>
            </>
          )}
          {/* <CheckPicker placeholder='New Audience' disabled placement='bottomEnd' className='audienceBtn' data={[]} style={{ width: 180 }} /> */}
        </div>
      </div>
      {selectTabs === "site_audience" && (
        <WebsiteSiteAudience load={load} chartData={chartData} audienceData={audienceData} audienceTableData={audienceTableData} />
      )}
      {selectTabs === "connected_audience" && <ConnectedAudience />}
      {selectTabs === "insights" && (
        <Insights
          loading={loading}
          deviceData={deviceData}
          multipleData={multipleData}
          osData={osData}
          webData={webData}
          referrerData={referrerData}
          countryData={countryData}
          languageData={languageData}
        />
      )}
      {selectTabs === "device_insights" && <DeviceInsights />}
    </div>
  )
}

export default WebsiteSiteCommon
