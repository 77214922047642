import Axios from "axios"

export const access_token = localStorage.getItem("token")
const axios = Axios.create({
  headers: {
    Authorization: `Bearer ${access_token}`
  }
})
export const AdRoll = Axios.create({
  headers: {
    Authorization: `Bearer ${"Token y+I+tTcfDIjnAkwKNsgXDoawvJx8YhtpB+f1JfYqUgg="}`
  }
})

export default axios

export const axiosMotic = Axios.create({
  auth: {
    username: "theadmin",
    password: "12343210"
  }
})
