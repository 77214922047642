import { drawerType } from "./drawer.types";

export const INITIAL_STATE = {
  isDrawerShow: false,
  activePanel: "",
  drawerName: "",
};

const DrawerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case drawerType.TOGGLE_DRAWER:
      return {
        ...state,
        isDrawerShow: action.payload,
      };
    case drawerType.ACTIVE_PANEL:
      return {
        ...state,
        activePanel: action.payload,
      };
    case drawerType.DRAWER_NAME:
      return {
        ...state,
        drawerName: action.payload,
      };
    default:
      return state;
  }
};

export default DrawerReducer;
