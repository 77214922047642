import React from "react"

type WebsiteAudienceMicroCardType = {
  audienceData: any
  headerName: string
  width: string
  height: string
  minWidth: string
  audienceWhisperData?: any
}
import infoIcon from "../../../assets/svgs/info.svg"
import { Button, Icon, Tooltip, Whisper } from "rsuite"
import { information } from "src/assets"
// import "./websiteSiteAudience.scss"
export default function WebsiteAudienceMicroCard(props: WebsiteAudienceMicroCardType) {
  const { audienceData, headerName, width, height, minWidth, audienceWhisperData } = props

  const style = {
    width: width,
    height: height,
    minWidth: minWidth
  }

  return (
    <div className='web__audience-cardCotnent' style={style}>
      <div
        style={{
          height: "35px",
          background: "#F2F7FF",
          borderBottom: "0.7px solid #d1d8e3",
          display: "flex",
          justifyContent: "center",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px"
        }}
      >
        {" "}
        <p className='textWeb'>{headerName} </p>
        <Whisper trigger='click' placement={"topEnd"} speaker={<Tooltip className='tolltipArea-tooltip'>{audienceWhisperData}</Tooltip>}>
          <button>
            {" "}
            <img src={information} alt='info Icon' />
          </button>
        </Whisper>{" "}
      </div>
      <div className='dataCountBox'>
        <h5 className='dataCount'>{audienceData}</h5>
      </div>
    </div>
  )
}
