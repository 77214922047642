import React from "react"
import { connect } from "react-redux"
import { Badge, Icon, IconButton, Notification } from "rsuite"
import DrawerComponentState from "../../common/components/adn-drawer/adn-drawer.component"
import { setDrawerName, toggleDrawer } from "../../common/redux/drawer/drawer.actions"
import { getCurrentUserToken } from "../../common/utils/Utils"
import { GetPaginatedNotification } from "./api-notification"
import BaseNotification from "./notification-component/base-notification.component"
import { Item } from "./notification-type"

enum NotificationEnum {
  DrawerName = "Notification Drawer"
}

const DesktopNotification = (props: any) => {
  const { drawerName, toggleDrawer, setDrawerName } = props

  const [ststus, setStatus] = React.useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageNumber, setPageNumber] = React.useState<number>(1)
  const [notificationItem, setNotificationItem] = React.useState<Item[]>([])

  const durationTime = process.env.REACT_APP_NOTIFICATION_DISPLAY_TIME ? parseInt(process.env.REACT_APP_NOTIFICATION_DISPLAY_TIME) : 5000

  const handelDrawerShow = () => {
    setStatus(false)
    setDrawerName(NotificationEnum.DrawerName)
    toggleDrawer(true)
  }

  const getNotification = async (page: number = 1, itemPerPage: number = 50) => {
    const response = await GetPaginatedNotification(page, itemPerPage)

    if (response && response.items && response.items.length > 0) {
      setNotificationItem(response.items)
    } else {
      setNotificationItem([])
    }
  }

  React.useEffect(() => {
    getNotification(pageNumber, 20)
  }, [pageNumber])

  // Web Scoket Section
  const ws: any = React.useRef(null)
  const token = getCurrentUserToken()
  React.useEffect(() => {
    ws.current = new WebSocket(process.env.REACT_APP_NOTIFICATION_SOCKET_URL_DEV + "?token=" + token)
    ws.current.onopen = () => console.log("ws opened")
    ws.current.onclose = () => console.log("ws closed")
    return () => {
      ws.current.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (!ws.current) return
    ws.current.onmessage = (e: any) => setStateMessage(e)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setStateMessage = (e: any) => {
    getNotification(1, 20)
    setStatus(true)
    const message = JSON.parse(e.data)
    console.log(message)
    Notification.info({
      title: message.header,
      duration: durationTime,
      placement: "bottomEnd",
      description: (
        <div>
          <p>{message.message}</p>
        </div>
      )
    })
  }
  // End Web Scoket Section

  const notificationPanel = () => {
    if (notificationItem && notificationItem.length > 0) {
      return notificationItem.map((item, index) => {
        return <BaseNotification item={item} toggleDrawer={toggleDrawer} />
      })
    } else {
      return <h6>No notifications found</h6>
    }
  }

  return (
    <React.Fragment>
      <div className='rs-badge mr-3 rs-badge-wrapper'>
        <button
          type='button'
          onClick={handelDrawerShow}
          style={{
            height: "22px",
            width: "24px"
          }}
        >
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M9 0.75C6.23885 0.75 3.75 2.67409 3.75 5.25V8.65417C3.75 9.1354 3.60756 9.60585 3.34062 10.0063L1.69116 12.4804C1.56651 12.6674 1.5 12.8871 1.5 13.1118C1.5 13.7404 2.00959 14.25 2.63819 14.25H6.375C6.375 15.6997 7.55025 16.875 9 16.875C10.4497 16.875 11.625 15.6997 11.625 14.25H15.3618C15.9904 14.25 16.5 13.7404 16.5 13.1118C16.5 12.8871 16.4335 12.6674 16.3088 12.4804L14.6594 10.0063C14.3924 9.60585 14.25 9.1354 14.25 8.65417V5.25C14.25 2.67409 11.7611 0.75 9 0.75ZM4.875 5.25C4.875 3.47667 6.66191 1.875 9 1.875C11.3381 1.875 13.125 3.47667 13.125 5.25V8.65417C13.125 9.3575 13.3332 10.0451 13.7233 10.6303L15.3728 13.1045C15.3742 13.1067 15.375 13.1092 15.375 13.1118C15.375 13.1147 15.3742 13.1168 15.3742 13.1168C15.3742 13.1168 15.3727 13.1195 15.3711 13.1211C15.3695 13.1227 15.3668 13.1242 15.3668 13.1242C15.3668 13.1242 15.3647 13.125 15.3618 13.125H2.63819C2.63533 13.125 2.63323 13.1242 2.63323 13.1242C2.63323 13.1242 2.63047 13.1227 2.62886 13.1211C2.62726 13.1195 2.62581 13.1168 2.62581 13.1168C2.62581 13.1168 2.625 13.1147 2.625 13.1118C2.625 13.1092 2.62577 13.1067 2.62722 13.1045L4.27668 10.6303C4.66681 10.0451 4.875 9.3575 4.875 8.65417V5.25ZM10.5 14.25H7.5C7.5 15.0784 8.17157 15.75 9 15.75C9.82843 15.75 10.5 15.0784 10.5 14.25Z'
              fill='#484848'
            />
          </svg>
        </button>
        <div
          className='rs-badge-content'
          style={{
            backgroundColor: notificationItem.length > 0 ? "red" : "gray"
          }}
        >
          {ststus ? "New" : ""}
        </div>
      </div>

      {drawerName === NotificationEnum.DrawerName ? (
        <DrawerComponentState backdrop={true} title='Notifications'>
          {notificationPanel()}
        </DrawerComponentState>
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = ({ drawer: { drawerName } }: any) => ({
  drawerName
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleDrawer: (toggle: boolean) => dispatch(toggleDrawer(toggle)),
  setDrawerName: (name: string) => dispatch(setDrawerName(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopNotification)
