import { goalType } from "./goal.type"

const initialState = {
  selectedGoal: "retargeting"
}

const Goalreducer = (state = initialState, action) => {
  switch (action.type) {
    case goalType.SET_SELECTED_GOAL:
      return {
        ...state,
        selectedGoal: action.payload
      }
    default:
      return state
  }
}

export default Goalreducer
