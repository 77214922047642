import { providerListAd } from "src/pages/provider/api-provider"
import { GetActiveAccount } from "./api-common"
import { decode } from "html-entities"

export const Constants = {
  Alert_Constant_Time: 3000,
  Error_Alert_Constant_Time: 10000,
  Name_Regex_Pattern: /^[a-zA-Z]+[a-zA-Z0-9 .-]*$/,
  Budget_Regex_pattern: /^\d*\.?\d+$/
}

export const CampaignEmailTableColumn = {
  fields: [
    {
      name: "NAME",
      selector: "name",
      sortable: false
    },
    {
      name: "EMAIL",
      selector: "contactEmail",
      sortable: false
    },
    {
      name: "ACTION",
      selector: "action",
      sortable: false
    }
  ]
}

export const useProviderId = async () => {
  const providerId = localStorage.getItem("providerId")
  if (providerId) {
    return providerId
  } else {
    const result = await providerListAd()
    localStorage.setItem("providerId", result.data[0]?.UserProviderAssociation?.provider_id)
    return result.data[0]?.UserProviderAssociation?.provider_id
  }
}

// for draftJs
export const getLastFourCharFromString = (value: string) => {
  if (value) {
    return value.substr(value.length - 4)
  }
  return null
}

export const getFirstThreeCharFromString = (value: string) => {
  if (value) {
    return value.substr(0, 3)
  }
  return null
}

export const removeLastFourCharFromString = (value: string) => {
  if (value) {
    return value.substr(0, value.length - 4)
  }
  return null
}

export const removeFirstThreeCharFromString = (value: string) => {
  if (value) {
    return value.substr(3, value.length)
  }
  return null
}

export const removeLineBreakFromEndOfString = (value: string) => {
  if (value) {
    return value.replace(/[\r\n]+/gm, "")
  }
  return null
}

export const removeSnippetsTextFromString = (value: string) => {
  if (value) {
    return value.split("#")[0]
  }
  return null
}

export const decodeHtml = (text: string) => {
  let html = decode(text, { level: "html5" })
  return html
}
// end draftJs

export const CampaignEmailAssociatedGroupTableColumn = {
  fields: [
    {
      name: "GROUP NAME",
      selector: "name",
      sortable: false
    },
    {
      name: "CONTACTS",
      selector: "contact_count",
      sortable: false
    }
  ]
}

export const MockEmailData = [
  {
    title: "email 1",
    lastUpdate: "2021-01-01",
    clicks: "0",
    status: "Sent"
  }
]

export const ContactHistoryTableColumn = {
  fields: [
    {
      name: "PROPERTY",
      selector: "property",
      sortable: true
    },
    {
      name: "CHANGED TO",
      selector: "changedTo",
      sortable: false
    },
    {
      name: "MADE ON",
      selector: "madeOn",
      sortable: true
    },
    {
      name: "SOURCE",
      selector: "sourceType",
      sortable: true
    }
  ]
}

export const MockCampaignTableColumn = {
  fields: [
    {
      name: "CAMPAIGN",
      selector: "name",
      sortable: false
    },
    {
      name: "Is for testing",
      selector: "is_for_testing",
      sortable: false
    },
    {
      name: "CREATED DATE",
      selector: "created_datetime",
      sortable: false
    },
    {
      name: "Action",
      selector: "action",
      sortable: false
    }
  ]
}

const userIdKey = "userId"
const userAccountKey = "active_account_id"
const activeAccountId = "active_account_id"

export const clearLocalStorage = () => {
  localStorage.removeItem(userIdKey)
  localStorage.removeItem(activeAccountId)
  localStorage.removeItem("token")
  localStorage.removeItem("login")
  localStorage.removeItem("providerId")
}

export const getUserId = () => {
  const userId = localStorage.getItem(userIdKey)
  if (userId) {
    return userId
  }
  return ""
}

export const setUserId = (userId: string) => {
  localStorage.setItem(userIdKey, userId)
}

export const getCurrentAccountId = () => {
  const accountId = localStorage.getItem(userAccountKey)
  if (accountId) {
    return parseInt(accountId)
  }
  return 0
}

export const setLogin = () => {
  localStorage.setItem("login", "1")
}

export const checkLogin = () => {
  const data = localStorage.getItem("login")
  if (data) {
    return true
  } else {
    return false
  }
}

export const getAccount = async (userId: string) => {
  await GetActiveAccount(userId)
    .then((response) => {
      localStorage.setItem("active_account_id", response.data.accountId)
    })
    .catch((error) => console.log(error))
}

const accountIdKey = "active_account_id"
export const getAccountId = () => {
  const accountId = localStorage.getItem(accountIdKey)
  if (accountId) {
    return accountId
  }
  return "0"
}

export const convertedDateTime = (value: any) => {
  const date = new Date(value)
  var timezoneOffsetInHours = -(date.getTimezoneOffset() / 60) //UTC minus local time
  var correctedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())

  correctedDate.setHours(date.getHours() + timezoneOffsetInHours)
  var isoFinalDate = correctedDate.toISOString()
  return isoFinalDate
}
