import React, { useEffect, useState } from "react"
import { Col, Input, Row } from "rsuite"
import recipeSvg from "../../assets/svgs/recipes.svg"
import webAdCampSvg from "../../assets/svgs/webAdCamp.svg"
import recipeDescSvg from "../../assets/svgs/recipeDesc.svg"
import "./createAds.scss"
import CreateAdCard from "./CreateAdCard"
import reEnageSvg from "../../assets/svgs/reEnageLarge.svg"
import tickGreen from "../../assets/svgs/tickGreen.svg"
import callRecipe from "../../assets/svgs/callRecipe.svg"
import ReactFlowDiagram from "../React-flow/ReactFlowDiagram"
import webAdCampDesc from "../../assets/svgs/webAdCampDesc.svg"
import GetStarted from "../GetStarted/GetStarted"
import AddRoll from "src/pages/AddRoll/AddRoll"
import { userInfoApi } from "./api-create-ad"
import FacebookAddRoll from "src/pages/FacebookAdRoll/FacebookAdRoll"
import GetStartedFacebook from "../GetStarted/GetStartedFacebok"
const CreateAds = () => {
  const [selectedCard, setSelectedCard] = useState("webAdCampaign")
  const [selectedCardGoal, setSelectedCardGoal] = useState("retargeting")
  const [selectedCardForTemp, setSelectedCardForTemp] = useState(null)
  const [step, setStep] = useState(0)
  const [userFullName, setUserFullName] = useState("")

  const handleCardClick = (card: any) => {
    setSelectedCard(card)
  }

  const handleCardSelectStep2 = (selectedCard2: any) => {
    setSelectedCardGoal(selectedCard2)
  }

  const isNextButtonVisible = selectedCard !== null

  const onChange = (nextStep: any) => {
    setStep(nextStep < 0 ? 0 : nextStep > 5 ? 5 : nextStep)
  }

  const userInfo = async () => {
    const result = await userInfoApi()
    setUserFullName(result?.userDetails.firstName + " " + result?.userDetails.lastName)
  }

  const onNext = () => {
    onChange(step + 1)
    // localStorage.setItem("selectedMenuItem", "create-campaign")
  }

  const onPrevious = () => onChange(step - 1)

  useEffect(() => {
    userInfo()
  }, [])

  return (
    <>
      <div className='containerParentDiv'>
        <div className={step === 0 ? "" : "hidden"}>
          <p className='welcomeText'>Good Day, {userFullName} !</p>
          <p className='welcomeSubText'>Choose a template to get started</p>

          <div className='campaignType'>
            <div
              className={`createAdsCard ${selectedCard === "webAdCampaign" ? "selectedCard" : ""}`}
              onClick={() => handleCardClick("webAdCampaign")}
            >
              <div className='createAdsCardChild'>
                <img src={webAdCampSvg} alt='web Ad Campaign svg' />
                <p className='recipeText'>Web Ad Campaign</p>
              </div>

              <p className='descriptions'>Show ads across the web on leading ad exchanges and publishers.</p>
            </div>

            <div
              className={`createAdsCard ${selectedCard === "facebook" ? "selectedCard" : ""}`}
              onClick={() => handleCardClick("facebook")}
            >
              <div className='createAdsCardChild'>
                <img src={recipeSvg} alt='recipes svg' />
                <p className='recipeText'>Facebook</p>
              </div>
              <p className='descriptions'>
                A guided set-up experience for creating automated campaigns across web ads, social ads (Facebook, Instagram, Pinterest, and
                TikTok), and email
              </p>
            </div>
          </div>

          <div className='btnPosition'>
            {isNextButtonVisible && (
              <button className='nextButton' onClick={onNext}>
                Next
              </button>
            )}
          </div>

          <div className='parent2Container'>
            <div className='anotherClass' style={{ display: "flex", gap: "15px" }}>
              <div className='imgStyle'>
                <img src={recipeDescSvg} alt='recipe description svg' />
              </div>

              <div>
                <p className='descriptionList'>
                  {" "}
                  01. Create retargeting campaigns across ads and email to turn browsers into customers. Shoppers influenced by both ads and
                  emails are 2x as likely to convert than shoppers who only see ads alone.
                  <br />
                  <br />
                  02. Set up your default Sending Identity. Shoppers influenced by both ads and emails are 2x as likely to convert than
                  shoppers who only see ads alone.
                  <br />
                  <br />
                  03. Increase the likelihood of your emails landing in the inbox. to turn browsers into customers. Shoppers influenced by
                  both ads and emails.
                </p>
              </div>

              {/* {selectedCard === "recipes" ? (
                    <>
                      <div className='imgStyle'>
                        <img src={recipeDescSvg} alt='recipe description svg' />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='imgStyle'>
                        <img src={webAdCampDesc} alt='web ad campaign svg' />
                      </div>
                    </>
                  )}
                

                
                  {selectedCard === "recipes" ? (
                    <>
                      <p className='descriptionList'>
                        {" "}
                        01. Create retargeting campaigns across ads and email to turn browsers into customers. Shoppers influenced by both
                        ads and emails are 2x as likely to convert than shoppers who only see ads alone.
                        <br />
                        <br />
                        02. Set up your default Sending Identity. Shoppers influenced by both ads and emails are 2x as likely to convert
                        than shoppers who only see ads alone.
                        <br />
                        <br />
                        03. Increase the likelihood of your emails landing in the inbox. to turn browsers into customers. Shoppers
                        influenced by both ads and emails.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className='descriptionList'>
                        {" "}
                        01. Create retargeting campaigns across ads and email to turn browsers into customers. Shoppers influenced by both
                        ads and emails are 2x as likely to convert than shoppers who only see ads alone.
                        <br />
                        <br />
                        02. Set up your default Sending Identity. Shoppers influenced by both ads and emails are 2x as likely to convert
                        than shoppers who only see ads alone.
                        <br />
                        <br />
                        03. Increase the likelihood of your emails landing in the inbox. to turn browsers into customers. Shoppers
                        influenced by both ads and emails.
                      </p>
                    </>
                  )} */}
            </div>
          </div>
        </div>

        {/* {selectedCard === "recipes" ? (
          <>
            <div className={step === 1 && selectedCard === "recipes" ? "" : "hidden"}>
              <>
                <p className='welcomeText'>Good Morning, Gavano !</p>
                <p className='welcomeSubText'>Choose a template to get started</p>

                <Row>
                  <Col sm={24} md={24} lg={8}>
                    <CreateAdCard
                      title={"Re-engage Browse"}
                      id={1}
                      selectedCardForTemp={selectedCardForTemp}
                      setSelectedCardForTemp={setSelectedCardForTemp}
                      desc={"Create retargeting campaigns across ads and email to turn browsers into customers."}
                    />
                  </Col>
                  <Col sm={24} md={24} lg={8}>
                    <CreateAdCard
                      title={"Convert Cart Abandoners"}
                      id={2}
                      selectedCardForTemp={selectedCardForTemp}
                      setSelectedCardForTemp={setSelectedCardForTemp}
                      desc={"Drive visitors with products in their cart back to your website and over the finish line."}
                    />
                  </Col>
                  <Col sm={24} md={24} lg={8}>
                    <CreateAdCard
                      title={"Post Purchase Thank You"}
                      id={3}
                      selectedCardForTemp={selectedCardForTemp}
                      setSelectedCardForTemp={setSelectedCardForTemp}
                      desc={
                        "Post purchase touch points are essential for growing your brand. They turn a shopper into a loyal and repeat customer."
                      }
                    />
                  </Col>
                  <Col sm={24} md={24} lg={8}>
                    <CreateAdCard
                      title={"Win Back"}
                      id={4}
                      selectedCardForTemp={selectedCardForTemp}
                      setSelectedCardForTemp={setSelectedCardForTemp}
                      desc={
                        "Connect with customers who previously engaged with your brand, but have not interacted with you for a certain period of time."
                      }
                    />
                  </Col>
                  <Col sm={24} md={24} lg={8}>
                    <CreateAdCard
                      title={"Attract New Visitors "}
                      id={5}
                      selectedCardForTemp={selectedCardForTemp}
                      setSelectedCardForTemp={setSelectedCardForTemp}
                      desc={"Find new people outside of your network and introduce them to your brand."}
                    />
                  </Col>
                </Row>
              </>
            </div>

            <div className={step === 2 && selectedCard === "recipes" ? "" : "hidden"}>
              <p className='welcomeText'>Re-engage Browse Abandoners</p>
              <p className='subTextReEnage'>
                Create retargeting campaigns across ads and email to turn browsers into customers. Shoppers influenced by both ads and
                emails are 2x as likely to convert than shoppers who only see ads alone.
              </p>

              <div className='reEngageCard'>
                <div className='reEngageCardChild'>
                  <div>
                    <img src={reEnageSvg} alt='Re-engage svg' />
                  </div>

                  <div>
                    <p>
                      Create retargeting campaigns across ads and email to turn browsers into customers. Shoppers influenced by both ads and
                      emails are 2x as likely to convert than shoppers who only see ads alone.{" "}
                    </p>
                  </div>
                </div>

                <p className='title'>Getting Started</p>

                <Row>
                  <Col sm={24} md={24} lg={8}>
                    <div className='gettingStarted'>
                      <div>
                        <img src={tickGreen} alt='tick svg' />
                      </div>
                      <div>
                        <p className='subTitle'>Connect your website to Robokat</p>
                        <p className='descp'>Track your website visitors</p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={24} md={24} lg={8}>
                    <div className='gettingStarted'>
                      <div>
                        <img src={tickGreen} alt='tick svg' />
                      </div>
                      <div>
                        <p className='subTitle'>CSet up your default Sending Identity</p>
                        <p className='descp'>Comply with the CAN-SPAM act</p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={24} md={24} lg={8}>
                    <div className='gettingStarted'>
                      <div>
                        <img src={tickGreen} alt='tick svg' />
                      </div>
                      <div>
                        <p className='subTitle'>Configure Email Authentication</p>
                        <p className='descp'>Increase the likelihood of your emails landing in the inbox</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className={step === 3 && selectedCard === "recipes" ? "" : "hidden"}>
              <p className='welcomeText'>Re-engage Browse Abandoners</p>
              <p className='subTextReEnage'>
                Create retargeting campaigns across ads and email to turn browsers into customers. Shoppers influenced by both ads and
                emails are 2x as likely to convert than shoppers who only see ads alone.
              </p>

              <div className='reEngageCard'>
                <div className='text-center'>
                  <img src={callRecipe} alt='callRecipe svg' />
                </div>
                <p className='pRecipe'>What should we call your recipe?</p>
                <Row>
                  <Col lg={6}></Col>
                  <Col lg={12}>
                    <p className='recTitle'>Recipe Name</p>

                    <div className='inputField'>
                      {" "}
                      <Input placeholder='Re-engage Browse Abandoners - 2023-12-05' />
                    </div>
                  </Col>
                  <Col lg={6}></Col>
                </Row>
              </div>
            </div>

            <div className={step === 4 && selectedCard === "recipes" ? "" : "hidden"}>
              <p className='welcomeText'>Re-engage Browse Abandoners</p>
              <p className='subTextReEnage'>
                Create retargeting campaigns across ads and email to turn browsers into customers. Shoppers influenced by both ads and
                emails are 2x as likely to convert than shoppers who only see ads alone.
              </p>

              <div className='triggerExitCriteriaCard'>
                <ReactFlowDiagram />
              </div>
            </div>
          </> */}
        {selectedCard === "facebook" ? (
          <>
            <div className={step === 1 && selectedCard === "facebook" ? "" : "hidden"}>
              <GetStartedFacebook onGoalSelect={handleCardSelectStep2} />
            </div>

            <div className={step === 2 && selectedCard === "facebook" ? "" : "hidden"}>
              <FacebookAddRoll selectedCardGoal={selectedCardGoal} onGoalSelect={handleCardSelectStep2} />
            </div>
          </>
        ) : (
          <>
            <div className={step === 1 && selectedCard === "webAdCampaign" ? "" : "hidden"}>
              <GetStarted onGoalSelect={handleCardSelectStep2} />
            </div>

            <div className={step === 2 && selectedCard === "webAdCampaign" ? "" : "hidden"}>
              <AddRoll selectedCardGoal={selectedCardGoal} onGoalSelect={handleCardSelectStep2} />
            </div>
          </>
        )}

        {/* {step > 0 && selectedCard === "recipes" && (
          <div className='btnTwo'>
            <div>
              <button className='backButton' onClick={onPrevious}>
                Back
              </button>
            </div>

            <div>
              <button className='nextButton' onClick={onNext}>
                Next
              </button>
            </div>
          </div>
        )} */}

        {step > 0 && selectedCard === "facebook" && step < 2 && (
          <div className='btnTwo'>
            <div>
              <button className='backButton' onClick={onPrevious}>
                Back
              </button>
            </div>

            <div>
              <button className='nextButton' onClick={onNext}>
                Next
              </button>
            </div>
          </div>
        )}

        {step > 0 && selectedCard === "webAdCampaign" && step < 2 && (
          <div className='btnTwo'>
            <div>
              <button className='backButton' onClick={onPrevious}>
                Back
              </button>
            </div>

            <div>
              <button className='nextButton' onClick={onNext}>
                Next
              </button>
            </div>
          </div>
        )}

        <div style={{ marginBottom: "50px" }} />
      </div>
    </>
  )
}

export default CreateAds
