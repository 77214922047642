import { Loader } from "rsuite"

interface CommonLoaderProps {
  height?: string | number // Specify the type of height prop
}

function CommonLoader({ height = 300 }: CommonLoaderProps) {
  return (
    <div>
      <div
        style={{
          height: `${height}px`,
          background: "#fff",
          textAlign: "center",
          paddingTop: "30px",
          borderRadius: "10px",
          marginBottom: "30px"
        }}
      >
        <Loader content='loading' size='md' />
      </div>
    </div>
  )
}

export default CommonLoader
