import { Button, Col, Dropdown, Form, Icon, Input, InputGroup, Modal, Panel, Row, SelectPicker } from "rsuite"
import "./adlibraryCardHeader.scss"
import pencilIcon from "../../assets/svgs/pencil.svg"
import crossIcon from "../../assets/svgs/cross.svg"
import cloneIcon from "../../assets/svgs/clone.svg"
import { useEffect, useState } from "react"
import CustomField from "../custom-field/custom-field.component"
import { AdTechAdvertisableEID, AdTechToken } from "src/common/utils/Utils"
import flagIcon from "../../assets/svgs/flagIcon.svg"
import earthIcon from "../../assets/svgs/earth.svg"
import likeIcon from "../../assets/svgs/like.svg"
import commentIcon from "../../assets/svgs/comment.svg"
import shareIcon from "../../assets/svgs/share.svg"
import circleIcon from "../../assets/svgs/cricleBackground.svg"
import threeDot from "../../assets/svgs/threeDotHorizon.svg"
import {
  getDynamicBrandName,
  getDynamicCallAction,
  getDynamicDescription,
  getDynamicImageSrc,
  getDynamicTitle,
  getDynamicUrl
} from "src/common/helpers/helpers"
import AudiNetWorkInter from "../UploadAdd/Preset/AudiNetWorkInter"
import MarketPlace from "../UploadAdd/Preset/MarketPlace"
import AudiNetWorkNative from "../UploadAdd/Preset/AudiNetWorkNative"
import AudiNetBanner from "../UploadAdd/Preset/AudiNetBanner"
import InstaFeed from "../UploadAdd/Preset/InstaFeed"
import InstagramStory from "../UploadAdd/Preset/InstagramStory"
import InstagramStoryFeed from "../UploadAdd/Preset/InstagramExploreFeed"
import InstantArticle from "../UploadAdd/Preset/InstantArticle"
import MessengerStory from "../UploadAdd/Preset/MessengerStory"
import previousBtn from "../../assets/svgs/previousBtnSvg.svg"
import nextBtn from "../../assets/svgs/nextBtnsvg.svg"

const AdlibraryCardHeader = (props: any) => {
  const initialValue: any = {
    name: "",
    headline: "",
    message: "",
    destination_url: "",
    brand_name: "",
    call_to_action: ""
  }

  const viewData = [
    "Desktop News Feed",
    "Desktop Right Column",
    "Mobile News Feed",
    "Desktop Marketplace Feed",
    "Desktop Marketplace Details Page",
    "Mobile Marketplace Feed",
    "General Search Results (Mobile)",
    "Marketplace Search Results (Mobile)",
    "Audience Network Interstitial",
    "Audience Network Native",
    "Audience Network Banner",
    "Instagram Feed",
    "Instagram Stories",
    "Instagram Explore Feed",
    "Instant Articles",
    "Facebook Stories",
    "Messenger Stories",
    "Marketplace Category Feed & Details Page"
  ].map((item) => ({ label: item, value: item }))

  const {
    adType,
    adStatus,
    setOpen,
    open,
    formRef,
    selectedRows,
    handleDelete,
    handleSubmit,
    isLoading,
    searchText,
    setSearchText,
    action,
    setAction,
    setTypeState,
    setSizeState,
    setStatusState
  } = props

  const [, setFormError] = useState<any>({})
  const [formValue, setFormValue] = useState(initialValue)
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [destinationUrl, setDestinationUrl] = useState("")
  const [brandName, setBrandName] = useState("")
  const [callAction, setCallAction] = useState("")
  const [object, setObject] = useState(selectedRows)
  const [calltoAction, setCalltoAction] = useState("")
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [previewState, setPreviewState] = useState(viewData[0].value)
  const [currentIndex, setCurrentIndex] = useState(0)

  const handlePreviewChange = (value: string) => {
    setPreviewState(value)
  }

  const handleTitleChange = (value: string) => {
    setTitle(value)
  }

  const handleDescriptionChange = (value: string) => {
    setDescription(value)
  }

  const handleDestinationUrlChange = (value: string) => {
    setDestinationUrl(value)
  }

  const handleSelectChange = (value: string) => {
    setCalltoAction(value)
  }

  const handleBrandNameChange = (value: string) => {
    setBrandName(value)
  }

  const handleNameChange = (value: string) => {
    setName(value)
  }

  const typeData = ["Web", "Facebook"].map((item) => ({ label: item, value: item }))

  const sizeData = ["300x250", "300x600", "600x600", "160x600", "600x315", "600x500"].map((item) => ({ label: item, value: item }))

  const statusData = ["Created", "Approved", "Not found"].map((item) => ({ label: item, value: item }))

  const handleTypeChange = (value: string) => {
    setTypeState(value)
  }

  const dataCallAction = [
    "None",
    "Apply Now",
    "Book Now",
    "Contact Us",
    "Download",
    "Get Offer",
    "Learn More",
    "Listen Now",
    "Send Message",
    "Shop Now",
    "Sign Up",
    "Watch More",
    "Buy Now"
  ].map((item) => ({
    label: item,
    value: mapLabelToValue(item)
  }))

  function mapLabelToValue(label: string) {
    switch (label) {
      case "None":
        return "NO_BUTTON"
      case "Apply Now":
        return "APPLY_NOW"
      case "Book Now":
        return "BOOK_TRAVEL"

      case "Contact Us":
        return "CONTACT_US"

      case "Download":
        return "DOWNLOAD"

      case "Get Offer":
        return "GET_OFFER"

      case "Learn More":
        return "LEARN_MORE"
      case "Listen Now":
        return "LISTEN_MUSIC"

      case "Send Message":
        return "MESSAGE_PAGE"

      case "Shop Now":
        return "SHOP_NOW"

      case "Sign Up":
        return "SIGN_UP"

      case "Watch More":
        return "WATCH_MORE"

      case "Buy Now":
        return "BUY_NOW"
      default:
        return label
    }
  }

  const handleSizeChange = (value: string) => {
    setSizeState(value)
  }

  const handleStatusChange = (value: string) => {
    setStatusState(value)
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1
      setPreviewState(viewData[prevIndex].value)
      setCurrentIndex(prevIndex)
    }
  }

  const handleNext = () => {
    if (currentIndex < viewData.length - 1) {
      const nextIndex = currentIndex + 1
      setPreviewState(viewData[nextIndex].value)
      setCurrentIndex(nextIndex)
    }
  }

  useEffect(() => {
    setObject(selectedRows)
  }, [selectedRows])

  useEffect(() => {
    if (action !== "") {
      if (action === "edit") {
        handleOpen()
      }
      if (action === "delete") {
        handleDelete(selectedRows?.adroll_ad_eid)
      }
    }
    setAction("")
  }, [action])

  useEffect(() => {
    setCalltoAction(getDynamicCallAction(selectedRows))
  }, [selectedRows])

  useEffect(() => {
    if (selectedRows.hasOwnProperty("name")) {
      setName(selectedRows?.name)
      setTitle(getDynamicTitle(selectedRows) ?? "")
      setDescription(getDynamicDescription(selectedRows) ?? "")
      setDestinationUrl(getDynamicUrl(selectedRows) ?? "")
      setBrandName(getDynamicBrandName(selectedRows) ?? "")
      setCallAction(getDynamicCallAction(selectedRows) ?? "")
    }
  }, [object])

  return (
    <>
      <div className='childDisplay'>
        <div>
          <SelectPicker data={typeData} searchable={false} placeholder='Type' onChange={handleTypeChange} className="selectPickForAdTable"/>
        </div>

        <div>
          <SelectPicker data={statusData} searchable={false} placeholder='Status' onChange={handleStatusChange}  className="selectPickForAdTable"/>
        </div>
      </div>

      <div>
        <div className='AdlibraryHeaderCard'>
          <div className='parentDisplay'>
            <div>
              <InputGroup inside className='searchInput'>
                <Input
                  placeholder='Search'
                  style={{ width: 284, height: 32 }}
                  value={searchText}
                  onChange={(value) => setSearchText(value)}
                />
                <InputGroup.Button>
                  <Icon icon='search' />
                </InputGroup.Button>
              </InputGroup>
            </div>
          </div>

          <Modal className='__generic_modal' backdrop={"static"} overflow full show={open} onHide={handleClose}>
            <Modal.Header className='modal-header-custom'>
              <Modal.Title className='title'>Edit Ads</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal__body' style={{ overflow: "auto", maxHeight: "90vh" }}>
              {adStatus === "approved" && (
                <>
                  <p className='instruction'>
                    **Any edits made to the ads will trigger the Ad Review Process, and so the changes may not reflect immediately, it may
                    take a couple of hours for the updates to be reflected on the dashboard.
                  </p>
                </>
              )}
              <div className='modalPreview'>
                <Row>
                  <Col lg={12}>
                    <p className='adDetails'>Ad Details</p>

                    <Panel>
                      <Form
                        className='form-c'
                        fluid
                        ref={formRef}
                        onCheck={(formError) => {
                          setFormError(formError)
                        }}
                        onChange={(formValue) => {
                          setFormValue({
                            ...formValue
                          })
                        }}
                      >
                        <label className='labelFont'>Name</label>
                        <CustomField name='name' placeholder='name' accepter={Input} size='lg' value={name} onChange={handleNameChange} />

                        {adType === "native" ||
                          (adType === "facebook" && (
                            <>
                              <label className='labelFont'>Title</label>
                              <CustomField
                                name='headline'
                                placeholder='Title'
                                accepter={Input}
                                size='lg'
                                value={title}
                                onChange={handleTitleChange}
                              />
                            </>
                          ))}

                        {adType === "native" ||
                          (adType === "facebook" && (
                            <>
                              <label className='labelFont'>Descriptions</label>
                              <CustomField
                                name='message'
                                placeholder='Descriptions'
                                accepter={Input}
                                size='lg'
                                value={description}
                                onChange={handleDescriptionChange}
                              />
                            </>
                          ))}

                        <label className='labelFont'>Destination url</label>
                        <CustomField
                          name='destination_url'
                          placeholder='Destination url (eg: https://example.com)'
                          accepter={Input}
                          size='lg'
                          value={destinationUrl}
                          onChange={handleDestinationUrlChange}
                        />

                        {adType === "facebook" && (
                          <>
                            <label className='labelFont'>Call to Action</label>
                            <CustomField
                              name='selectedOption'
                              accepter={SelectPicker}
                              data={dataCallAction}
                              size='lg'
                              value={calltoAction}
                              onChange={handleSelectChange}
                              searchable={false}
                              cleanable={false}
                            />
                          </>
                        )}

                        {/* <label className='labelFont'>Call to Action</label>
                        <CustomField
                          name='call_to_action'
                          accepter={SelectPicker}
                          size='lg'
                          value={callAction}
                          onChange={handleCallActionChange}
                        /> */}

                        {adType === "native" && (
                          <>
                            <label className='labelFont'>Brand Name</label>
                            <CustomField
                              name='brand_name'
                              placeholder='Brand Name'
                              accepter={Input}
                              size='lg'
                              value={brandName}
                              onChange={handleBrandNameChange}
                            />
                          </>
                        )}
                      </Form>
                    </Panel>
                  </Col>

                  <Col lg={12}>
                    <p className='adPreview'>Ad Preview</p>
                    <div className='displayImgPad'>
                      {adType === "facebook" && (
                        <>
                          <div className='adPreviewSelectPickerAndBtn'>
                            <SelectPicker
                              searchable={false}
                              cleanable={false}
                              value={previewState}
                              data={viewData}
                              style={{ width: 394 }}
                              onChange={handlePreviewChange}
                            />

                            <Button onClick={handlePrevious}>
                              <img src={previousBtn} alt='Previous' />
                            </Button>
                            <Button onClick={handleNext}>
                              <img src={nextBtn} alt='Next' />
                            </Button>
                          </div>
                          <div style={{ marginTop: "15px" }}>
                            {previewState === "Desktop News Feed" && (
                              <Panel shaded bordered bodyFill className='panelForm'>
                                <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
                                  <div className='ar-ad-manager-fb-preview-placeholder'>
                                    <img
                                      src={flagIcon}
                                      alt='flagIcon'
                                      style={{
                                        alignItems: "center"
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <p className='fbTitle'>Roboket</p>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                      <div>
                                        <p className='sponsorText'>Sponsored</p>
                                      </div>
                                      <div>
                                        <img src={earthIcon} alt='earth icon' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                                  {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                                </div>
                                <Panel>
                                  <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                                </Panel>
                                <hr />
                                <Panel>
                                  <div style={{ overflow: "hidden" }}>
                                    {destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px"
                                    }}
                                  >
                                    <p className='tilePanel' style={{ overflow: "auto" }}>
                                      {title.length === 0 ? "Your Title" : title}
                                    </p>
                                    <div style={{ flex: "0.3" }}>
                                      {calltoAction === "NO_BUTTON" && <></>}

                                      {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                                      {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                                      {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                                      {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                                      {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                                      {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                                      {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                                      {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                                      {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                                      {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                                      {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                                      {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                                    </div>
                                  </div>
                                </Panel>

                                <Panel>
                                  <div className='likeShareIcon'>
                                    <div className='displayed'>
                                      <img src={likeIcon} alt='likeIcon' />
                                      Like
                                    </div>
                                    <div className='displayed'>
                                      <img src={commentIcon} alt='commentIcon' />
                                      Comment
                                    </div>
                                    <div className='displayed'>
                                      <img src={shareIcon} alt='shareIcon' />
                                      Share
                                    </div>
                                  </div>
                                </Panel>
                              </Panel>
                            )}

                            {previewState === "Desktop Right Column" && (
                              <Panel shaded bordered bodyFill className='panelForm'>
                                <div className='adDiffPreview'>
                                  <div className='flexDir'>
                                    <div style={{ width: "171px" }}>
                                      <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                                    </div>

                                    <div>
                                      <p style={{ fontSize: "14px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
                                      <p style={{ fontSize: "11px", fontWeight: "300" }}>
                                        {destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}
                                      </p>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                              </Panel>
                            )}

                            {previewState === "Mobile News Feed" && (
                              <Panel shaded bordered bodyFill className='panelFormMobile'>
                                <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
                                  <div className='ar-ad-manager-fb-preview-placeholder'>
                                    <img
                                      src={flagIcon}
                                      alt='flagIcon'
                                      style={{
                                        alignItems: "center"
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <p className='fbTitle'>Roboket</p>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                      <div>
                                        <p className='sponsorText'>Sponsored</p>
                                      </div>
                                      <div>
                                        <img src={earthIcon} alt='earth icon' />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                                  {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                                </div>
                                <Panel>
                                  <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                                </Panel>
                                <hr />
                                <Panel>
                                  <div>{destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

                                    {calltoAction === "NO_BUTTON" && <></>}

                                    {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                                    {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                                    {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                                    {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                                    {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                                    {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                                    {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                                    {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                                    {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                                    {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                                    {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                                    {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                                  </div>
                                </Panel>

                                <Panel>
                                  <div className='likeShareIcon'>
                                    <div className='displayed'>
                                      <img src={likeIcon} alt='likeIcon' />
                                      Like
                                    </div>
                                    <div className='displayed'>
                                      <img src={commentIcon} alt='commentIcon' />
                                      Comment
                                    </div>
                                    <div className='displayed'>
                                      <img src={shareIcon} alt='shareIcon' />
                                      Share
                                    </div>
                                  </div>
                                </Panel>
                              </Panel>
                            )}

                            {previewState === "Desktop Marketplace Feed" && (
                              <>
                                <div className='adDiffPreview'>
                                  <div style={{ width: "171px" }}>
                                    <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                                  </div>
                                </div>
                                <div style={{ padding: "0px 20px" }}>
                                  <p style={{ fontSize: "12px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
                                  <p style={{ fontSize: "11px", fontWeight: "300" }}>
                                    {destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}
                                  </p>
                                </div>
                              </>
                            )}

                            {previewState === "Desktop Marketplace Details Page" && (
                              <>
                                <div className='adDiffPreview'>
                                  <div className='iconTool'>
                                    <img className='circleAshColor' style={{ width: "51px" }} src={circleIcon} alt='circleAshColor' />
                                    <img
                                      className='flagIconStyle'
                                      src={flagIcon}
                                      alt='flagIcon'
                                      style={{
                                        position: "absolute",
                                        top: 201,
                                        left: 112,
                                        width: "25px",
                                        height: "25px"
                                      }}
                                    />
                                  </div>

                                  <div style={{ width: "271px" }}>
                                    <p className='fbTitleDesktop'>Roboket</p>
                                    <img
                                      style={{ borderRadius: "19px" }}
                                      src={getDynamicImageSrc(selectedRows)}
                                      alt='file_preview'
                                      className='imgFbAd'
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            {previewState === "Mobile Marketplace Feed" && (
                              <Panel shaded bordered bodyFill className='panelFormMobile'>
                                <div style={{ padding: "10px 15px", display: "flex", flex: "1", alignItems: "center" }}>
                                  <div className='ar-ad-manager-fb-preview-placeholder'>
                                    <img
                                      src={flagIcon}
                                      alt='flagIcon'
                                      style={{
                                        alignItems: "center"
                                      }}
                                    />
                                  </div>

                                  <div style={{ flex: "0.9" }}>
                                    <p className='fbTitle'>Roboket</p>
                                    <div className='fbSponsor'>
                                      <div>
                                        <p className='sponsorTextInstagram'>Sponsored</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='sponsor' style={{ flex: "0.1", display: "flex", alignItems: "center" }}>
                                    Sponsored <img src={threeDot} alt='three dot' />
                                  </div>
                                </div>
                                <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                                  {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                                </div>
                                <Panel>
                                  <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                                </Panel>
                                <hr />
                                <Panel>
                                  <div>{destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>
                                  </div>
                                  <p style={{ textAlign: "center", fontSize: "16px" }}>
                                    {calltoAction === "NO_BUTTON" && <></>}
                                    {calltoAction === "APPLY_NOW" && <span>Apply Now</span>}
                                    {calltoAction === "BOOK_TRAVEL" && <span>Book Now</span>}
                                    {calltoAction === "DOWNLOAD" && <span>Download</span>}
                                    {calltoAction === "CONTACT_US" && <span>Contact Us</span>}
                                    {calltoAction === "GET_OFFER" && <span>Get Offer</span>}
                                    {calltoAction === "LEARN_MORE" && <span>Learn More</span>}
                                    {calltoAction === "LISTEN_MUSIC" && <span>Listen Now</span>}
                                    {calltoAction === "MESSAGE_PAGE" && <span>Send Message</span>}
                                    {calltoAction === "SHOP_NOW" && <span>Shop Now</span>}
                                    {calltoAction === "SIGN_UP" && <span>Sign Up</span>}
                                    {calltoAction === "WATCH_MORE" && <span>Watch More</span>}
                                    {calltoAction === "BUY_NOW" && <span>Buy Now</span>} &nbsp;
                                    {">"}
                                  </p>
                                </Panel>
                              </Panel>
                            )}

                            {previewState === "General Search Results (Mobile)" && (
                              <Panel shaded bordered bodyFill className='panelFormGeneral'>
                                <div className='fbSponsorNew' style={{ padding: "0px 25px" }}>
                                  <div className='iconTool'>
                                    <img className='circleAshColor' src={circleIcon} alt='circleAshColor' />
                                    <img
                                      className='flagIconStyle'
                                      src={flagIcon}
                                      alt='flagIcon'
                                      style={{
                                        position: "absolute",
                                        top: 193,
                                        left: 135,
                                        width: "25px",
                                        height: "22px"
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <p className='fbTitle'>Roboket</p>
                                    <div className='fbSponsorNew'>
                                      <div>
                                        <p className='sponsorText'>Sponsored</p>
                                      </div>
                                      <div>
                                        <img src={earthIcon} alt='earth icon' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div style={{ padding: "6px 25px", overflow: "hidden" }}>
                                  {description.length === 0 ? <>Your primary text</> : <>{description}</>}
                                </div>
                                <div>
                                  <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAdGen' />
                                </div>

                                <Panel>
                                  <div>{destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

                                    {calltoAction === "NO_BUTTON" && <></>}

                                    {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                                    {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                                    {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                                    {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                                    {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                                    {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                                    {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                                    {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                                    {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                                    {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                                    {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                                    {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                                  </div>
                                </Panel>
                              </Panel>
                            )}

                            {previewState === "Marketplace Search Results (Mobile)" && (
                              <MarketPlace flag={"flag"} file={getDynamicImageSrc(selectedRows)} title={title} />
                            )}

                            {previewState === "Audience Network Interstitial" && (
                              <AudiNetWorkInter
                                description={description}
                                title={title}
                                calltoAction={calltoAction}
                                file={getDynamicImageSrc(selectedRows)}
                                flag={"flag"}
                              />
                            )}

                            {previewState === "Audience Network Native" && (
                              <AudiNetWorkNative
                                flag={"flag"}
                                title={title}
                                calltoAction={calltoAction}
                                file={getDynamicImageSrc(selectedRows)}
                              />
                            )}

                            {previewState === "Audience Network Banner" && (
                              <AudiNetBanner calltoAction={calltoAction} description={description} />
                            )}

                            {previewState === "Instagram Feed" && (
                              <InstaFeed
                                file={getDynamicImageSrc(selectedRows)}
                                flag={"flag"}
                                description={description}
                                calltoAction={calltoAction}
                              />
                            )}

                            {previewState === "Instagram Stories" && (
                              <InstagramStory
                                file={getDynamicImageSrc(selectedRows)}
                                description={description}
                                calltoAction={calltoAction}
                                flag={"flag"}
                              />
                            )}

                            {previewState === "Instagram Explore Feed" && (
                              <InstagramStoryFeed
                                file={getDynamicImageSrc(selectedRows)}
                                description={description}
                                calltoAction={calltoAction}
                                flag={"flag"}
                              />
                            )}

                            {previewState === "Instant Articles" && (
                              <InstantArticle
                                file={getDynamicImageSrc(selectedRows)}
                                description={description}
                                title={title}
                                calltoAction={calltoAction}
                                flag={"flag"}
                              />
                            )}

                            {previewState === "Facebook Stories" && (
                              <InstagramStory
                                file={getDynamicImageSrc(selectedRows)}
                                description={description}
                                calltoAction={calltoAction}
                                flag={"flag"}
                              />
                            )}

                            {previewState === "Messenger Stories" && (
                              <MessengerStory
                                file={getDynamicImageSrc(selectedRows)}
                                description={description}
                                calltoAction={calltoAction}
                                flag={"flag"}
                              />
                            )}

                            {previewState === "Marketplace Category Feed & Details Page" && (
                              <MarketPlace file={getDynamicImageSrc(selectedRows)} widthI={"width"} title={title} flag={"flag"} />
                            )}
                          </div>
                        </>
                      )}
                      {adType !== "facebook" && (
                        <div className='displayImg'>
                          <img
                            src={getDynamicImageSrc(selectedRows)}
                            height='100%'
                            width='100%'
                            style={{ objectFit: "contain" }}
                            alt='NativeImg'
                          />
                          <p className='title'>{getDynamicTitle(selectedRows)}</p>
                          <p>{getDynamicDescription(selectedRows)}</p>
                          <p style={{ fontSize: "12px", marginTop: "-2px" }}>Ad By {getDynamicBrandName(selectedRows)}</p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ padding: "20px" }}>
              <Button
                color='green'
                // disabled={isLoading}
                appearance='primary'
                onClick={() => handleSubmit(selectedRows?.adroll_ad_eid, formValue)}
              >
                {/* {isLoading ? "Updating..." : "Update"} */}
                Update
              </Button>

              <Button color='red' appearance='primary' onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default AdlibraryCardHeader
