import { Message } from "rsuite"
interface CommonErrorMessageProps {
  msgType?: any
  description?: string
}
function CommonErrorMessage({ msgType, description }: CommonErrorMessageProps) {
  return (
    <div className='errorMessageArea'>
      <Message closable showIcon size={"md"} type={msgType} description={description} />
    </div>
  )
}

export default CommonErrorMessage
