// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web__connectedAppsPageArea {
  padding: 20px 20px;
  background-color: white;
  height: auto;
  border-radius: 10px;
}

.web__connectedApps-area {
  border: 1px solid #eceef6;
}

.web__connectedAppsPageArea h5 {
  color: #636363;
  font-weight: 500;
}

.web__connectedApps-area {
  border: 1px solid #eceef6;
  margin: 20px 0px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web__connectedApps-area h5 {
  color: black;
  /* line-height: 29px; */
  margin: 17px 0px 5px 0px;
}

.web__connectedApps-area p {
  font-size: 16px;
  line-height: 34px;
}

.web__connectedApps-area span {
  color: #3c8f7c;
}`, "",{"version":3,"sources":["webpack://./src/pages/Websites/ConnectedAudience/connectedAudience.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,cAAA;EACA,gBAAA;AAGF;;AADA;EACE,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAIF;;AAFA;EACE,YAAA;EACA,uBAAA;EACA,wBAAA;AAKF;;AAHA;EACE,eAAA;EACA,iBAAA;AAMF;;AAJA;EACE,cAAA;AAOF","sourcesContent":[".web__connectedAppsPageArea {\n  padding: 20px 20px;\n  background-color: white;\n  height: auto;\n  border-radius: 10px;\n}\n.web__connectedApps-area {\n  border: 1px solid #eceef6;\n}\n.web__connectedAppsPageArea h5 {\n  color: #636363;\n  font-weight: 500;\n}\n.web__connectedApps-area {\n  border: 1px solid #eceef6;\n  margin: 20px 0px;\n  padding: 20px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.web__connectedApps-area h5 {\n  color: black;\n  /* line-height: 29px; */\n  margin: 17px 0px 5px 0px;\n}\n.web__connectedApps-area p {\n  font-size: 16px;\n  line-height: 34px;\n}\n.web__connectedApps-area span {\n  color: #3c8f7c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
