import React, { useEffect, useState } from "react"
import { Button, Drawer, Dropdown } from "rsuite"

import "./adsManagerHeader.scss"
import { useHistory } from "react-router-dom"
import UploadsModal from "src/common/uploadsModal/UplaodModal"
import MenuIcon from "@rsuite/icons/Menu"
import hamburgerMenu from "../../assets/svgs/hembergerMenu.svg"
import { roboketCrmUi } from "src/common/routes"

const AdsManagerHeader = (props: any) => {
  const { handleDrawer, isLessThan1199px } = props
  const [open, setOpen] = useState(false)

  const currentLocation = localStorage.getItem("selectedMenuItem")
  const history = useHistory()

  const handleSelect = (eventKey: any, event: any) => {
    if (eventKey === "nativeAds") {
      history.push("/create-native-ads")
    }
  }

  const params = location.pathname

  const handleUpload = () => {
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <div className='inbox-top-section shadow-sm'>
      <div className='top-elements-wrapper ___dashboard_lists_header'>
        <div className='__dashboard_lists__title' style={{ display: "flex", gap: "18px" }}>
          {params === "/"
            ? "Providers"
            : (currentLocation === "websites" && "Websites") ||
              (currentLocation === "dashboard" && "Ads Manager") ||
              (currentLocation === "create-ads" && "Ads Manager") ||
              (currentLocation === "campaign-list" && "Ads Manager") ||
              (currentLocation === "ad-library" && "Ads Manager")}
        </div>

        <div className='top-elements-right-side'>
          {/* {isLessThan1199px && <MenuIcon style={{ fontSize: "30px", color: "white" }} onClick={handleDrawer} />} */}

          {currentLocation !== undefined && currentLocation === "dashboard" && (
            <Button
              className='transparent-button-new'
              onClick={() => {
                localStorage.setItem("activeSidebar", "ad_provider_setting_access")
                const redirectUrl = roboketCrmUi.BASE_URL + "/settings"
                window.location.href = redirectUrl
              }}
            >
              Provider
            </Button>
          )}

          {currentLocation !== undefined && currentLocation === "create-ads" && (
            <>
              <button className='transparent-button-new' onClick={handleUpload}>
                Uploads
              </button>
              <Dropdown
                onSelect={handleSelect}
                title='Create Ads'
                renderTitle={(children) => {
                  return (
                    <Button className='createAdsDropdown'>
                      {children}{" "}
                      <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16' fill='none'>
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M13.1666 5.81442C13.3898 6.00968 13.3898 6.32626 13.1666 6.52152L8.40471 10.6882C8.18156 10.8835 7.81975 10.8835 7.59659 10.6882L2.83469 6.52152C2.61153 6.32626 2.61153 6.00968 2.83469 5.81442C3.05784 5.61915 3.41965 5.61915 3.64281 5.81442L8.00065 9.62753L12.3585 5.81442C12.5817 5.61915 12.9435 5.61915 13.1666 5.81442Z'
                          fill='white'
                        />
                      </svg>{" "}
                    </Button>
                  )
                }}
              >
                <Dropdown.Item disabled eventKey='thirdPartyAds'>
                  Create Third Party Ads
                </Dropdown.Item>
                <Dropdown.Item eventKey='nativeAds'>Create Native Ads</Dropdown.Item>
                <Dropdown.Item disabled eventKey='dynamicAds'>
                  Create Dynamic Ads
                </Dropdown.Item>
              </Dropdown>
            </>
          )}

          {currentLocation !== undefined && currentLocation === "ad-library" && (
            <>
              <button onClick={handleUpload} className='transparent-button-new'>
                Uploads
              </button>
              <Dropdown
                onSelect={handleSelect}
                title='Create Ads'
                renderTitle={(children) => {
                  return (
                    <Button className='createAdsDropdown'>
                      {children}{" "}
                      <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16' fill='none'>
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M13.1666 5.81442C13.3898 6.00968 13.3898 6.32626 13.1666 6.52152L8.40471 10.6882C8.18156 10.8835 7.81975 10.8835 7.59659 10.6882L2.83469 6.52152C2.61153 6.32626 2.61153 6.00968 2.83469 5.81442C3.05784 5.61915 3.41965 5.61915 3.64281 5.81442L8.00065 9.62753L12.3585 5.81442C12.5817 5.61915 12.9435 5.61915 13.1666 5.81442Z'
                          fill='white'
                        />
                      </svg>{" "}
                    </Button>
                  )
                }}
              >
                <Dropdown.Item disabled eventKey='thirdPartyAds'>
                  Create Third Party Ads
                </Dropdown.Item>
                <Dropdown.Item eventKey='nativeAds'>Create Native Ads</Dropdown.Item>
                <Dropdown.Item disabled eventKey='dynamicAds'>
                  Create Dynamic Ads
                </Dropdown.Item>
              </Dropdown>
            </>
          )}
        </div>
      </div>

      <UploadsModal open={open} handleClose={handleClose} />
    </div>
  )
}

export default AdsManagerHeader
