import React, { useEffect, useState } from "react"
import { Alert, Button, ButtonToolbar, Content, FlexboxGrid, Header, Input, Modal, Radio, RadioGroup, SelectPicker } from "rsuite"
import settingsIcon from "../../assets/svgs/settingsIcon.svg"
import BoxPanel from "src/components/AddRollPanel/BoxPanel/BoxPanel"
import "../FacebookAdRoll/facebookAdRoll.scss"
import { retargetingCamp } from "src/components/AddRollPanel/BoxPanel/api-panel"
import channelSvg from "../../assets/svgs/channel.svg"
import chooseScheduleSvg from "../../assets/svgs/chooseSchedule.svg"
import webSvg from "../../assets/svgs/webForCampaign.svg"
import adgroupSvg from "../../assets/svgs/adGroup.svg"
import budget from "../../assets/svgs/budgetIcon.svg"
import { useHistory, useLocation } from "react-router-dom"
import { campaignObjectiveData, campaignObjectiveDataRetarget, campaignOptimizationGoal, campaignOptimizationGoalLink, campaignOptimizationGoalRetarget } from "src/components/mocks/mock-data"
import { FbAdsType } from "src/common/utils/types"
import { useProviderId } from "src/common/constants"
import { campaignUpdate, getCampaign, getSelectedAds, getSelectedSegment } from "../UpdateAdRoll/api-updateAdroll"
import BoxPanelUpdated from "src/components/AddRollPanel/BoxPanel/Boxpanel.updated"
import LoadingComponent from "src/common/components/loading/Loading"

interface selectedRowsImgType {
  image_url: string
  height: number
  width: number
  adroll_ad_eid: string
}

interface selectedRowsAudType {
  eid: string
}

interface suggestionType {
  eid: string
}

const UpdateFbAdRoll = (props: any) => {
  const { selectedCardGoal } = props
  const [campName, setCampName] = useState("")
  const [campChannel, setCampChannel] = useState("")
  const [metric, setMetric] = useState("")
  const [selectedAds, setSelectedAds] = useState([])
  const [checkCampaignType, setCheckCampaignType] = useState("")
  const [campEid, setCampEid] = useState("")
  const [optimizationGoal, setOptimizationGoal] = useState("")
  const [inputNumberValue, setInputNumberValue] = useState(50)
  const [inputValue, setInputValue] = useState("AdGroup")
  const [selectedRowsImg, setSelectedRowsImg] = useState<selectedRowsImgType[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedRowsAud, setSelectedRowsAud] = useState<selectedRowsAudType[]>([])
  const [bidInput, setBidInput] = useState()
  const [selectedValue, setSelectedValue] = useState("Impressions")
  const [selectedObjValue, setSelectedObjValue] = useState("CONVERSIONS")
  const [bidStrategyValue, setBidStrategyValue] = useState("")
  const [suggestions, setSuggestions] = useState<suggestionType[]>([])
  const [inputValueModal, setInputValueModal] = useState("Facebook Campaign - " + new Date().toLocaleDateString())
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
  const [open, setOpen] = React.useState(false)
  const [minAge, setMinAge] = useState(18)
  const [maxAge, setMaxAge] = useState(60)
  const [selectedAdPlacement, setSelectedAdPlacement] = useState("Automatic (Recommended)")
  const [deviceValue, setDeviceValue] = useState()
  const [fbValue, setFbValue] = useState()
  const [instagramValue, setInstagramValue] = useState()
  const [messengerValue, setMessengerValue] = useState()
  const [audienceNetworkValue, setAudienceNetworkValue] = useState()
  const [draft, setDraft] = useState(false)
  const [targetingType, setTargetingType] = useState("")
  const [selectedBid, setSelectedBid] = useState("Maximize clicks")
  const [fbAds, setFbAds] = useState<FbAdsType[]>([])
  const [selectedBidFlag, setSelectedBidFlag] = useState(false)
  const [selectedOptValue, setSelectedOptValue] = useState("")
  const [bidValue, setBidValueData] = useState("")
  const [audTree, setAudTree] = useState([])

  const handleAdPlacementChange = (value: string) => {
    setSelectedAdPlacement(value)
  }


  const handleBidValueChange = (value: string) => {
    setBidValueData(value)
  }

  const getKeyValue = (selectedOption: any) => {
    switch (selectedOption) {
      case "age1":
        return 1
      case "age2":
        return 2
      case "age3":
        return 3
      case "age4":
        return 4
      case "age5":
        return 5
      case "age6":
        return 6
      case "gender1":
        return 1
      case "gender2":
        return 2
      default:
        return 0
    }
  }

  const getNameValue = (selectedOption: any) => {
    switch (selectedOption) {
      case "age1":
        return "age"
      case "age2":
        return "age"
      case "age3":
        return "age"
      case "age4":
        return "age"
      case "age5":
        return "age"
      case "age6":
        return "age"
      case "gender1":
        return "gender"
      case "gender2":
        return "gender"
      default:
        return 0
    }
  }


  const demography = audTree.map((selectedOption) => {
    return {
      name: getNameValue(selectedOption),
      key: getKeyValue(selectedOption)
    }
  })

  const handleBidStrategyChange = (value: any) => {
    setSelectedBid(value)
  }

  const handleOptChange = (value: string) => {
    setSelectedOptValue(value)
  }

  const handleObjChange = (value: string) => {
    setSelectedObjValue(value)
  }

  const history = useHistory()

  const handleNumberChange = (event: any) => {
    const newValue = event
    setInputNumberValue(newValue)
  }

  const handleCampStatus = (value: string) => {
    if (value === "Live") {
      setDraft(false)
    } else {
      setDraft(true)
    }
  }

  const handleTargetStatus = (value: string) => {
    if (value === "lookalike") {
      setTargetingType("lookalike")
    } else {
      setTargetingType("attribute")
    }
  }

  const handleChangeAudTree = (value: any) => {
    setAudTree(value)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const cardGoaldata = ["Re-targeting", "Lookalike Campaign", "Contextual Campaign"].map((item) => ({
    label: item,
    value: mapLabelToValue(item)
  }))

  function mapLabelToValue(label: string) {
    switch (label) {
      case "Re-targeting":
        return "retargeting"
      case "Lookalike Campaign":
        return "lookalike"
      case "Contextual Campaign":
        return "contextual"
      default:
        return label
    }
  }

  const handleClose = () => setOpen(false)

  const handleNameChange = (event: any) => {
    const newValue = event
    setInputValue(newValue)
  }

  const handleBidInput = (event: any) => {
    const newValue = event
    setBidInput(newValue)
  }

  const handleSelectChange = (value: any) => {
    setSelectedValue(value)
    setBidStrategyValue(value)
    setMetric(value)
  }

  const handleSelectedRowsChange = (selectedRows: any) => {
    setSelectedRowsImg(selectedRows)
  }

  const handleMinAgeChange = (value: number) => {
    setMinAge(value)
  }

  const handleMaxAgeChange = (value: number) => {
    setMaxAge(value)
  }

  const handleRowSelectedAud = (state: any) => {
    setSelectedRowsAud(state.selectedRows)
  }

  const handleCancel = () => {
    localStorage.setItem("selectedMenuItem", "create-ads")
    window.location.href = "/ads"
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      let payload

      payload = {
        name: campName,
        start_date: selectedStartDate,
        end_date: selectedEndDate,
        campaigns: [
          {
            name: campName,
            key: "2_fb",
            service: "facebook",
            objective: selectedObjValue,
            goal: campChannel,
            adgroups: [
              {
                name: inputValue,
                service: "facebook",
                campaign_eid: "2_fb",
                ads: fbAds.map((selectedRowsImgEach) => {
                  return {
                    name: selectedRowsImgEach.name,
                    eid: selectedRowsImgEach.eid,
                    // eid: 'KCTRAIAEFJEFBIMHCMTP3F',
                    status: "live"
                  }
                }),
                segments: selectedRowsAud.map((selectedRowsImgEach) => {
                  return {
                    eid: selectedRowsImgEach.eid,
                    targeted: true
                  }
                }),
                geotargets: [
                  {
                    // eid: suggestions[0]?.eid,
                    eid: "WHSRSKRCDBBI3DTR6Q8C4G",
                    targeted: true
                  }
                ],
                optimization_goal: "OFFSITE_CONVERSIONS",
                min_age: minAge,
                max_age: maxAge,
                targeting: {
                  placements:
                    selectedAdPlacement === "Manual"
                      ? {
                          messenger_positions: messengerValue,
                          audience_network_positions: audienceNetworkValue,
                          facebook_positions: fbValue,
                          instagram_positions: instagramValue,
                          device_platforms: deviceValue
                        }
                      : {
                          automatic: true
                        }
                }
              }
            ],
            targeting_type: targetingType,
            daily_budget: inputNumberValue
          }
        ],
        budget: {
          type: "daily",
          goal: inputNumberValue
        },
        currency: "USD",
        budget_balancing_automated: false,
        source: "automation",
        is_draft: draft
      }

      if (selectedBid === "Set your own performance target") {
        payload.campaigns = payload.campaigns.map((campaign) => ({
          ...campaign,
          kpi: {
            metric:
              bidStrategyValue === "Impressions"
                ? "CPM"
                : bidStrategyValue === "Conversion"
                  ? "CPA"
                  : bidStrategyValue === "Clicks"
                    ? "CPC"
                    : "",
            goal: bidInput
          }
        }))
      } else {
      }

      const providerId = await useProviderId()

      const campSubmit = await campaignUpdate(providerId, campEid, payload)

      if (campSubmit.status === 201 || campSubmit.status === 200) {
        Alert.success("Campaign create successfully")
      } else {
        Alert.error("error")
      }
    } catch (error) {
      Alert.error("Error in API request")
    } finally {
      setLoading(false)
      history.push("/ads")
    }
  }

  const handleModalInputChange = (event: any) => {
    const newValue = event
    setCampName(newValue)
  }

 
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  const getFbCampaignInfo = async () => {
    const providerId = await useProviderId()
    setLoading(true)
    try {
      const result = await getCampaign(providerId, queryParameters.get("eid"))
      const dateObject = result?.data?.data.start_date ? new Date(result?.data?.data.start_date) : null
      const endDateObject = result?.data?.data.end_date ? new Date(result?.data?.data.end_date) : null
      setCampName(result?.data?.data.name)
      setCampChannel(result?.data?.data.campaigns[0]?.goal)
      setSelectedStartDate(dateObject)
      setSelectedEndDate(endDateObject)
      setMinAge(result?.data?.data.campaigns[0]?.adgroups[0]?.min_age)
      setMaxAge(result?.data?.data.campaigns[0]?.adgroups[0]?.max_age)
      setInputNumberValue(result?.data?.data.budget.goal)
      setInputValue(result?.data?.data.campaigns[0]?.adgroups[0].name)

      if (result?.data?.data.campaigns[0].hasOwnProperty("kpi")) {
        setBidInput(result?.data?.data.campaigns[0]?.kpi.goal)
        setSelectedBidFlag(true)
        setMetric(result?.data?.data.campaigns[0]?.kpi.metric)
      }
      setSelectedAds(result?.data?.data.campaigns[0]?.adgroups[0]?.ads)
      setCampEid(result?.data?.data.eid)
      setCheckCampaignType(result?.data?.data.campaigns[0]?.adgroups[0].service)
      setTargetingType(result?.data?.data.campaigns[0]?.targeting_type)
      setDraft(result?.data?.data.is_draft)
      setSelectedObjValue(result?.data?.data.campaigns[0]?.objective)

      setSelectedOptValue(result?.data?.data.campaigns[0]?.adgroups[0]?.optimization_goal)

      setBidValueData(result?.data?.data.campaigns[0]?.bid_strategy)
      
      const segmentListDataGetEid = result?.data?.data.campaigns[0]?.adgroups[0]?.segments?.map((segment: any) => segment.eid)

      let segmentList = []

      for (const segmentId of segmentListDataGetEid) {
        const segmentListItem = await getSelectedSegment(providerId, segmentId)
        segmentList.push(segmentListItem.data)
        setSelectedRowsAud(segmentList)
      }

      const adListDataGetEid = result?.data?.data.campaigns[0]?.adgroups[0]?.ads?.map((ad: any) => ad.eid)
      let adList = []

      for (const adId of adListDataGetEid) {
        const adListItem = await getSelectedAds(providerId, adId)

        adList.push(adListItem.data)
        setSelectedRowsImg(adList)
      }
    } catch (error) {
      Alert.error("Error")
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFbCampaignInfo()
  }, [])

  useEffect(() => {
    if (metric === "CPM") {
      setBidStrategyValue("Impressions")
    } else if (metric === "CPA") {
      setBidStrategyValue("Conversion")
    } else if (metric === "CPC") {
      setBidStrategyValue("Clicks")
    }
  }, [metric])

  return (
    <>
      <Content className='main_content'>
        {loading === true ? (
          <>
            <LoadingComponent />
          </>
        ) : (
          <>
            <Header>
              <div className='cardTitle'>
                <div>
                  <p style={{ color: "#8c8c8c", fontSize: "16px" }}>Facebook Campaign</p>

                  <div className='titleEdit'>
                    <p className='titleCampHeader'> {campName} </p>

                    <div onClick={handleOpen} className='monty-proxy-edit-name'>
                      Edit Name
                    </div>
                  </div>
                </div>
              </div>
            </Header>
            <div className='__dashboard_container'>
              <div className='addRoll__container-area'>
                <Content className='addRoll__container-area-content'>
                  <div className='show-grid'>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          selectedCardChanel={"Facebook"}
                          boxSubTitle={"Facebook"}
                          facebookFlag={"facebookFlag"}
                          handleOpen={handleOpen}
                          imgS={channelSvg}
                          title={"Campaign Settings"}
                          boxBody={"ChannelsBody"}
                          boxtitle={"Channels"}
                          handleModalInputChange={handleModalInputChange}
                          buttonToolbar={
                            <div className='settingsBox'>
                              <img src={settingsIcon} alt='s' style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                            </div>
                          }
                          selectedCardGoal={campChannel}
                          setInputValueModal={setInputValueModal}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid'>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={chooseScheduleSvg}
                          boxSubTitle={"Starts immediately upon approval, no end date"}
                          boxtitle={"Choose Schedule"}
                          boxBody={"ScheduleBody"}
                          loading={loading}
                          setLoading={setLoading}
                          selectedStartDate={selectedStartDate}
                          selectedEndDate={selectedEndDate}
                          setSelectedStartDate={setSelectedStartDate}
                          setSelectedEndDate={setSelectedEndDate}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid' style={{ paddingTop: "2%" }}>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={webSvg}
                          boxSubTitle={"Optimized for conversions, automatic CPA"}
                          title={"Facebook & Instagram Channels"}
                          boxtitle={"Choose Facebook Settings"}
                          boxBody={"facebookSettingsBody"}
                          handleBidInput={handleBidInput}
                          selectedValue={selectedValue}
                          handleSelectChange={handleSelectChange}
                          selectedBid={selectedBid}
                          handleBidStrategyChange={handleBidStrategyChange}
                          bidInput={bidInput}
                          bidStrategyValue={bidStrategyValue}
                          selectedBidFlag={selectedBidFlag}
                          handleBidValueChange={handleBidValueChange}
                          bidValue={bidValue}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid'>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={adgroupSvg}
                          boxSubTitle={"Ad group settings, audience and ads"}
                          boxtitle={"Configure" + " " + inputValue}
                          boxBody={"FacebookConfigureAdBody"}
                          handleNameChange={handleNameChange}
                          inputValue={inputValue}
                          handleSelectedRowsChange={handleSelectedRowsChange}
                          selectedRowsImg={selectedRowsImg}
                          handleRowSelectedAud={handleRowSelectedAud}
                          setSuggestions={setSuggestions}
                          suggestions={suggestions}
                          selectedCardGoal={campChannel}
                          loading={loading}
                          setLoading={setLoading}
                          handleMinAgeChange={handleMinAgeChange}
                          handleMaxAgeChange={handleMaxAgeChange}
                          handleAdPlacementChange={handleAdPlacementChange}
                          setFbAds={setFbAds}
                          selectedAdPlacement={selectedAdPlacement}
                          deviceValue={deviceValue}
                          setDeviceValue={setDeviceValue}
                          setFbValue={setFbValue}
                          setInstagramValue={setInstagramValue}
                          setMessengerValue={setMessengerValue}
                          setAudienceNetworkValue={setAudienceNetworkValue}
                          fbValue={fbValue}
                          instagramValue={instagramValue}
                          messengerValue={messengerValue}
                          audienceNetworkValue={audienceNetworkValue}
                          selectedRowsAud={selectedRowsAud}
                          minAge={minAge}
                          maxAge={maxAge}
                          selectedAds={selectedAds}
                          targetingType={targetingType}
                          handleChangeAudTree={handleChangeAudTree}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid' style={{ paddingTop: "2%" }}>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={budget}
                          boxSubTitle={"Smart Budget optimization across channels or separate budgets"}
                          boxtitle={"Choose Budget"}
                          title={"Campaign Budget"}
                          boxBody={"ChooseBudgetBody"}
                          handleNumberChange={handleNumberChange}
                          inputNumberValue={inputNumberValue}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>
                </Content>
              </div>
            </div>
            <div className='finishBtnTop'>
              <ButtonToolbar>
                <Button appearance='default' className='cancelBtn' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} className='finishBtn' disabled={loading}>
                  {loading ? "Updating..." : "Update"}
                </Button>
              </ButtonToolbar>
            </div>
          </>
        )}
      </Content>

      <Modal className='__generic_modal' show={open} onHide={handleClose}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Manage Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body_camp'>
          <div>
            <p className='titleCamp'>Campaign Name</p>
            <Input value={campName} onChange={handleModalInputChange} />
            <br />
            <p className='titleCamp'>Campaign Type</p>
            <SelectPicker size='md' appearance='default' defaultValue={selectedCardGoal} disabled cleanable={false} data={cardGoaldata} />
            <br />
            <br />

            {/* <p className='titleCamp' style={{ marginBottom: "1%" }}>
              Campaign Objective
            </p>
            <SelectPicker
              searchable={false}
              cleanable={false}
              data={campaignObjectiveData}
              value={selectedObjValue}
              style={{ width: 240 }}
              onChange={handleObjChange}
            /> */}

<p className='titleCamp' style={{ marginBottom: "1%", marginTop: "2%" }}>
              Campaign Objective
            </p>
{ campChannel==='retarget' ? (
              <>
            <SelectPicker
              searchable={false}
              cleanable={false}
              data={campaignObjectiveData}
              defaultValue={selectedObjValue}
              style={{ width: 184 }}
              onChange={handleObjChange}
            />

            <p className='titleCamp' style={{ marginBottom: "1%", marginTop: "2%" }}>
              Optimization Goal
            </p>


            <SelectPicker
              searchable={false}
              cleanable={false}
              data={selectedObjValue === "CONVERSIONS" ? campaignOptimizationGoal: campaignOptimizationGoalLink}
              defaultValue={selectedOptValue}
              style={{ width: 184 }}
              onChange={handleOptChange}
            />
            </>
            ):
            (
              <>
              <SelectPicker
              searchable={false}
              cleanable={false}
              data={campaignObjectiveDataRetarget}
              defaultValue={selectedObjValue}
              style={{ width: 184 }}
              onChange={handleObjChange}
            />

            <p className='titleCamp' style={{ marginBottom: "1%", marginTop: "2%" }}>
              Optimization Goal
            </p>


            <SelectPicker
              searchable={false}
              cleanable={false}
              data={selectedObjValue === "CONVERSIONS" ? campaignOptimizationGoalRetarget : campaignOptimizationGoalLink}
              defaultValue={selectedOptValue}
              style={{ width: 184 }}
              onChange={handleOptChange}
            />
              </>
            )
            
            }

            <div style={{ marginTop: "4%", display: "flex", alignItems: "center" }}>
              <p className='titleCamp'>Targeting Type : </p>

              <RadioGroup name='radioList' inline value={targetingType} onChange={handleTargetStatus}>
                <Radio value='lookalike'>
                  <p style={{ fontWeight: "500" }}>Lookalike</p>
                </Radio>
                <Radio value='attribute'>
                  <p style={{ fontWeight: "500" }}>Attribute</p>
                </Radio>
              </RadioGroup>
            </div>

            <div style={{ marginTop: "4%", display: "flex", alignItems: "center" }}>
              <p className='titleCamp'>Campaign Status : </p>

              <RadioGroup name='radioList' inline value={draft === true ? "Draft" : "Live"} onChange={handleCampStatus}>
                <Radio value='Live'>
                  <p style={{ fontWeight: "500" }}>Live</p>
                </Radio>
                <Radio value='Draft'>
                  <p style={{ fontWeight: "500" }}>Draft</p>
                </Radio>
              </RadioGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "10px" }}>
          <Button onClick={handleClose} style={{ backgroundColor: "#0fae8c", color: "white", padding: "8px 28px" }}>
            Ok
          </Button>
          <Button onClick={handleClose} color='red' appearance='primary'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateFbAdRoll
