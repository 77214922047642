// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createAdsCard .container {
  padding-top: 90px;
}
.createAdsCard .container .monthly-how-it-works-buttons-container {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  padding-top: 20%;
}

.monty-how-it-works-page-header {
  font-size: 30px;
  line-height: 48px;
  color: #3c4858;
}

.monty-how-it-works-body-text.intro-text {
  margin-bottom: 54px;
  margin-top: 36px;
  font-size: 18px;
  line-height: 28px;
  color: #3c4858;
}

.monty-how-it-works-body-text {
  font-size: 18px;
  line-height: 28px;
  color: #3c4858;
  text-align: left;
}
.monty-how-it-works-body-text .lineEnd {
  margin-bottom: 20px;
}
.monty-how-it-works-body-text .intro-pages-list-item-number {
  color: #ccc;
  background-color: #3c4858;
  margin-right: 12px;
  border-radius: 16px;
  font-style: normal;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}
.monty-how-it-works-body-text .intro-pages-list-item-label {
  display: inline-block;
  width: 85%;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/HowItworks/howItWorks.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AACI;EACE,aAAA;EACA,SAAA;EACA,yBAAA;EACA,gBAAA;AACN;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AADF;;AAGA;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;AAEJ;AAAE;EACE,qBAAA;EACA,UAAA;EACA,sBAAA;AAEJ","sourcesContent":[".createAdsCard {\n  .container {\n    padding-top: 90px;\n    .monthly-how-it-works-buttons-container {\n      display: flex;\n      gap: 14px;\n      justify-content: flex-end;\n      padding-top: 20%;\n    }\n  }\n}\n\n.monty-how-it-works-page-header {\n  font-size: 30px;\n  line-height: 48px;\n  color: #3c4858;\n}\n.monty-how-it-works-body-text.intro-text {\n  margin-bottom: 54px;\n  margin-top: 36px;\n  font-size: 18px;\n  line-height: 28px;\n  color: #3c4858;\n}\n.monty-how-it-works-body-text {\n  font-size: 18px;\n  line-height: 28px;\n  color: #3c4858;\n  text-align: left;\n  .lineEnd {\n    margin-bottom: 20px;\n  }\n  .intro-pages-list-item-number {\n    color: #ccc;\n    background-color: #3c4858;\n    margin-right: 12px;\n    border-radius: 16px;\n    font-style: normal;\n    display: inline-block;\n    font-size: 16px;\n    line-height: 30px;\n    width: 30px;\n    text-align: center;\n  }\n  .intro-pages-list-item-label {\n    display: inline-block;\n    width: 85%;\n    vertical-align: middle;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
