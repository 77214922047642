import React, { useEffect, useState } from "react"

import "./websiteSiteAudience.scss"
import { Alert, Button, Icon, Input, InputGroup, SelectPicker } from "rsuite"
import dotSvg from "../../../assets/svgs/dot.svg"
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts"
import DataTable from "react-data-table-component"
// import { useHistory } from "react-router-dom"
import { getSegmentsReporting, getVisitorsReporting } from "../WebsiteCommon/webApiList"
import CommonLoader from "src/common/commonLoader"
import WebsiteAudienceMicroCard from "./WebsiteAudienceMicroCard"
import { stringToSubstringSpreed } from "src/utils"

const chartDefaultData = [
  {
    name: "Jan 01, 2024",
    visitors: 0,
    page_view_visitors: 0
  },
  {
    name: "Jan 02, 2024",
    visitors: 0,
    page_view_visitors: 0
  },
  {
    name: "Jan 03, 2024",
    visitors: 0,
    page_view_visitors: 0
  },
  {
    name: "Jan 04, 2024",
    visitors: 0,
    page_view_visitors: 0
  },
  {
    name: "Jan 05, 2024",
    visitors: 0,
    page_view_visitors: 0
  },
  {
    name: "Jan 06, 2024",
    visitors: 0,
    page_view_visitors: 0
  },
  {
    name: "Jan 07, 2024",
    visitors: 0,
    page_view_visitors: 0
  },
  {
    name: "Jan 08, 2024",
    visitors: 0,
    page_view_visitors: 0
  }
]
const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      borderRadius: "0px",
      backgroundColor: "white"
    }
  },
  headCells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "12px"
    }
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  }
}
type ChartDataItem = string
const WebsiteSiteAudience = (props: any) => {
  const { load, chartData, audienceData, audienceTableData } = props
  // const [audienceData, setAdudienceData] = useState<any>([])
  // const [audienceTableData, setAdudiencTableData] = useState([])
  const initialValue: ChartDataItem[] = ["visitors", "page_view_visitors"]
  const [searchText, setSearchText] = useState("")
  const [tData, setTData] = useState([])
  // const [load, setLoad] = useState({ allowChart: false, allowTable: false })

  const [chartLine, setChartLine] = useState<ChartDataItem[]>(initialValue)
  // const [chartData, setChartData] = useState<any>([])

  const columnsForAdList = [
    {
      name: "Name",
      sortable: false,
      selector: (row: any) => {
        return <div className='nameProperty'>{row.name ? stringToSubstringSpreed(row.name, 35) : "-"}</div>
      },
      width: "350px"
    },

    {
      name: "Source",
      sortable: false,
      selector: (row: any) => {
        return <div className='nameProperty'>&nbsp;{row.source ?? "-"}</div>
      },
      width: "200px"
    },
    {
      name: "Duration",
      sortable: false,
      selector: (row: any) => {
        return <div className='nameProperty'>&nbsp;{row.duration ?? "-"}</div>
      },
      width: "200px"
    },
    {
      name: "Pattern",
      sortable: false,
      selector: (row: any) => {
        return <div className='nameProperty'>&nbsp;{row.pattern ?? "-"}</div>
      },
      width: "450px"
    },
    {
      name: "Status",
      sortable: false,
      selector: (row: any) => {
        return (
          <div className='nameProperty'>
            {row.is_active ? (
              <>
                <img src={dotSvg} alt='review svg' /> &nbsp;{row.is_active === true ? <>Active</> : <>Inactive</>}{" "}
              </>
            ) : (
              <>{row.is_active}</>
            )}
          </div>
        )
      },
      width: "200px"
    }
  ]

  const handleActiveCharLine = (key: string) => {
    const keyIndex = chartLine.indexOf(key)
    if (keyIndex !== -1) {
      setChartLine(chartLine.filter((item) => item !== key))
    } else {
      setChartLine([...chartLine, key])
    }
  }

  useEffect(() => {
    setTData(audienceTableData?.filter((item: any) => item?.name.toLowerCase().includes(searchText.toLowerCase())))
  }, [searchText])

  return (
    <>
      {load.allowChart ? (
        <CommonLoader />
      ) : (
        <>
          {" "}
          <div className='web__siteAudienceMainArea'>
            <div className='web__audienceCardContentArea'>
              <div style={{ display: "flex", gap: "20px", alignItems: "center", width: "100%" }}>
                <WebsiteAudienceMicroCard
                  height={"108px"}
                  minWidth={"211px"}
                  width={"auto"}
                  headerName={"Unique Website Visitors"}
                  audienceData={audienceData?.page_view_summary?.visitors.toLocaleString()}
                  audienceWhisperData={"The number of unique visitors who entered an audience during the selected date range."}
                />

                <WebsiteAudienceMicroCard
                  height={"108px"}
                  minWidth={"211px"}
                  width={"auto"}
                  headerName={"Current Visitors"}
                  audienceData={audienceData?.unique_visitor_summary?.current_visitors.toLocaleString()}
                  audienceWhisperData={
                    "Current Audience Size is the number of unique visitors in a segment based on segment duration in days."
                  }
                />

                <WebsiteAudienceMicroCard
                  height={"108px"}
                  minWidth={"211px"}
                  width={"ayto"}
                  headerName={"All Visitors"}
                  audienceData={audienceData?.page_view_summary?.all_visitors.toLocaleString()}
                  audienceWhisperData={
                    '"All Visitors" is one of the three default audience segments created when you create an AdRoll account.'
                  }
                />
              </div>
            </div>
            <div className='web__audienceChartArea'>
              <div className='web__charHeader'>
                <div className='web__chartHeaderContent'>
                  {audienceData &&
                    Object.keys(audienceData)
                      .filter((key) => key === "visitors" || key === "page_view_visitors")
                      .map((key: any) => (
                        <Button onClick={() => handleActiveCharLine(key)} className='addRoll__campaignChartHeaderLeftSide_btn' key={key}>
                          <img src={dotSvg} className={chartLine?.includes(key) ? "visible" : "invisible"} alt='review svg' /> &nbsp;{" "}
                          {key.replace(/_/g, " ").toUpperCase()}
                        </Button>
                      ))}
                </div>
                <p>*Metrics in the last 48 hours are not finalized</p>
              </div>
              <div className='web__chartArea'>
                <ResponsiveContainer aspect={3}>
                  <AreaChart
                    data={chartData?.length > 0 ? chartData : chartDefaultData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id='colorVisitors' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                        <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id='colorPage_view' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
                        <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey='name' />
                    <YAxis />
                    <CartesianGrid strokeDasharray='3 3' />
                    <Tooltip />
                    <Legend />
                    {chartLine?.includes("visitors") && chartData?.length > 0 && (
                      <Area type='monotone' dataKey='visitors' stroke='#8884d8' fillOpacity={1} fill='url(#colorVisitors)' />
                    )}
                    {chartLine?.includes("page_view_visitors") && chartData?.length > 0 && (
                      <Area type='monotone' dataKey='page_view_visitors' stroke='#82ca9d' fillOpacity={1} fill='url(#colorPage_view)' />
                    )}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          {/* <div className='web__siteAudiencePageArea'>
      <h5>62 Page Views</h5>
      <h5>Active (Since 2023-12-03)</h5>
      <Button>View Pixel</Button>
    </div> */}
        </>
      )}

      {load.allowTable ? (
        <CommonLoader />
      ) : (
        <>
          <div className='web__siteCommonHeader'>
            <h4>Audience</h4>
            {/* <div className='web__siteCommonRightSiteHeader web__siteAudienceRight-site'>
              <InputGroup
                //  inside
                style={{ width: 220 }}
              >
                <Input placeholder='Search' />
                <InputGroup.Addon>
                  <Icon icon='search' />
                </InputGroup.Addon>
              </InputGroup>
            </div> */}
          </div>
          <div className='website_audienceHeaderCard'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div className='searchPad'>
                <InputGroup inside style={{ width: 294,height:32 }}>
                  <Input placeholder='Search' value={searchText} onChange={(value) => setSearchText(value)} />
                  <InputGroup.Button>
                    <Icon icon='search' />
                  </InputGroup.Button>
                </InputGroup>
              </div>
            </div>
          </div>
          <div className='web__siteAudienceTable'>
            <div className='tableWidth dashCampTable audienceTableWidth'>
              <DataTable
                columns={columnsForAdList as any}
                data={searchText?.length > 0 ? tData : audienceTableData}
                pagination={true}
                customStyles={customStyles}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default WebsiteSiteAudience
