import { DateRangePicker } from "rsuite"
import * as dateFns from "date-fns"
import "./common-dateRangePicker.scss"
interface CommonDateRangePickerProps {
  allowDateRange?: boolean
  dateFilterRange?: any
  handleDateRange?: any
  fromDate?: any
  toDate?: any
  additionalParameter?: any
}
const Ranges: any = [
  {
    label: "today",
    value: [dateFns.startOfDay(new Date()), dateFns.endOfDay(new Date())]
  },
  {
    label: "yesterday",
    value: [dateFns.startOfDay(dateFns.subDays(new Date(), 1)), dateFns.endOfDay(dateFns.subDays(new Date(), 1))]
  },
  {
    label: "this week",
    value: [
      dateFns.startOfWeek(new Date(), { weekStartsOn: 0 }), // Sunday
      dateFns.endOfWeek(new Date(), { weekStartsOn: 0 })
    ]
  },
  {
    label: "last week",
    value: [
      dateFns.startOfWeek(dateFns.subWeeks(new Date(), 1), { weekStartsOn: 0 }),
      dateFns.endOfWeek(dateFns.subWeeks(new Date(), 1), { weekStartsOn: 0 })
    ]
  },
  {
    label: "this month",
    value: [dateFns.startOfMonth(new Date()), dateFns.endOfMonth(new Date())]
  },
  {
    label: "last month",
    value: [dateFns.startOfMonth(dateFns.subMonths(new Date(), 1)), dateFns.endOfMonth(dateFns.subMonths(new Date(), 1))]
  }
]
function CommonDateRangePicker({
  allowDateRange,
  dateFilterRange,
  fromDate,
  toDate,
  handleDateRange,
  additionalParameter
}: CommonDateRangePickerProps) {
  return (
    <div className='customStyle-dateRangePicker'>
      {" "}
      <DateRangePicker
        // onClean={() => setDateFilterRange({ formDate: "", toDate: "" })}
        placement='bottomEnd'
        cleanable={false}
        locale={{
          sunday: "Su",
          monday: "Mo",
          tuesday: "Tu",
          wednesday: "We",
          thursday: "Th",
          friday: "Fr",
          saturday: "Sa",
          ok: "Done",
          today: "Today",
          yesterday: "Yesterday",
          last7Days: "Last 7 days"
        }}
        defaultOpen={allowDateRange}
        value={fromDate?.length > 0 && toDate?.length > 0 ? [new Date(fromDate), new Date(toDate)] : []}
        // onChange={(value: any) => handleDateRange(value)}
        onChange={(value: any) => {
          if (additionalParameter && additionalParameter?.length > 0) {
            handleDateRange(value, additionalParameter)
          } else {
            handleDateRange(value)
          }
        }}
        ranges={Ranges}
      />
    </div>
  )
}

export default CommonDateRangePicker
