import { userType } from "./user.types"

export const INITIAL_STATE = {
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  avater: "",
  userStatus: {},
  availabilityLoad: false
}

const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userType.CURRENT_USER:
      return {
        ...state,
        userId: action.payload.userId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        avater: action.payload.avater
      }
    case userType.CURRENT_STATUS:
      return {
        ...state,
        userStatus: action.payload
      }
    case userType.LOAD_AVAILABILITY:
      return {
        ...state,
        availabilityLoad: action.payload
      }
    default:
      return state
  }
}

export default UserReducer
