import React, { useState } from "react"
import { Button, Panel, SelectPicker } from "rsuite"
import previousBtn from "../../assets/svgs/previousBtnSvg.svg"
import nextBtn from "../../assets/svgs/nextBtnsvg.svg"
import flagIcon from "../../assets/svgs/flagIcon.svg"
import earthIcon from "../../assets/svgs/earth.svg"
import likeIcon from "../../assets/svgs/like.svg"
import commentIcon from "../../assets/svgs/comment.svg"
import shareIcon from "../../assets/svgs/share.svg"
import circleIcon from "../../assets/svgs/cricleBackground.svg"
import threeDot from "../../assets/svgs/threeDotHorizon.svg"
import AudiNetWorkInter from "./Preset/AudiNetWorkInter"
import AudiNetWorkNative from "./Preset/AudiNetWorkNative"
import MarketPlace from "./Preset/MarketPlace"
import AudiNetBanner from "./Preset/AudiNetBanner"
import InstaFeed from "./Preset/InstaFeed"
import InstagramStory from "./Preset/InstagramStory"
import InstagramStoryFeed from "./Preset/InstagramExploreFeed"
import InstantArticle from "./Preset/InstantArticle"
import MessengerStory from "./Preset/MessengerStory"

const viewData = [
  "Desktop News Feed",
  "Desktop Right Column",
  "Mobile News Feed",
  "Desktop Marketplace Feed",
  "Desktop Marketplace Details Page",
  "Mobile Marketplace Feed",
  "General Search Results (Mobile)",
  "Marketplace Search Results (Mobile)",
  "Audience Network Interstitial",
  "Audience Network Native",
  "Audience Network Banner",
  "Instagram Feed",
  "Instagram Stories",
  "Instagram Explore Feed",
  "Instant Articles",
  "Facebook Stories",
  "Messenger Stories",
  "Marketplace Category Feed & Details Page"
].map((item) => ({ label: item, value: item }))

const FbImgDisplay18 = (props: any) => {
  const { file,title,description,descriptionUrl,calltoAction } = props
  const [previewState, setPreviewState] = useState(viewData[0].value)
  const [currentIndex, setCurrentIndex] = useState(0)

  const handlePreviewChange = (value: any) => {
    setPreviewState(value)
    const newIndex = viewData.findIndex((item) => item.value === value)
    setCurrentIndex(newIndex)
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1
      setPreviewState(viewData[prevIndex].value)
      setCurrentIndex(prevIndex)
    }
  }

  const handleNext = () => {
    if (currentIndex < viewData.length - 1) {
      const nextIndex = currentIndex + 1
      setPreviewState(viewData[nextIndex].value)
      setCurrentIndex(nextIndex)
    }
  }

  return (
    <div>
      <div className='adPreviewSelectPickerAndBtn'>
        <SelectPicker
          searchable={false}
          cleanable={false}
          value={previewState}
          data={viewData}
          style={{ width: 394 }}
          onChange={handlePreviewChange}
        />
        <Button onClick={handlePrevious}>
          <img src={previousBtn} alt='Previous' />
        </Button>
        <Button onClick={handleNext}>
          <img src={nextBtn} alt='Next' />
        </Button>
      </div>

      {previewState === "Desktop News Feed" && (
        <Panel shaded bordered bodyFill className='panelForm'>
          <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
            <div className='ar-ad-manager-fb-preview-placeholder'>
              <img
                src={flagIcon}
                alt='flagIcon'
                style={{
                  alignItems: "center"
                }}
              />
            </div>

            <div>
              <p className='fbTitle'>Roboket</p>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div>
                  <p className='sponsorText'>Sponsored</p>
                </div>
                <div>
                  <img src={earthIcon} alt='earth icon' />
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "0px 25px", overflow: "hidden" }}>
            {description.length === 0 ? (
              <>This is your Description</>
            ) : (
              <>
                {description.split("\n").map((line: string, index: number) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </>
            )}
          </div>
          <Panel>
            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
          </Panel>
          <hr />
          <Panel>
            <div style={{ overflow: "hidden" }}>
              {descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl.split(".com")[0] + ".com"}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <p className='tilePanel' style={{ overflow: "hidden" }}>
                {title.length === 0 ? "Your Title" : title}
              </p>

              {calltoAction === "NO_BUTTON" && <></>}

              {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

              {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

              {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

              {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

              {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

              {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

              {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

              {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

              {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

              {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

              {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

              {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
            </div>
          </Panel>

          <Panel>
            <div className='likeShareIcon'>
              <div className='displayed'>
                <img src={likeIcon} alt='likeIcon' />
                Like
              </div>
              <div className='displayed'>
                <img src={commentIcon} alt='commentIcon' />
                Comment
              </div>
              <div className='displayed'>
                <img src={shareIcon} alt='shareIcon' />
                Share
              </div>
            </div>
          </Panel>
        </Panel>
      )}

      {previewState === "Desktop Right Column" && (
        <Panel shaded bordered bodyFill className='panelForm'>
          <div className='adDiffPreview'>
            <div className='flexDir'>
              <div style={{ width: "171px" }}>
                <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
              </div>

              <div>
                <p style={{ fontSize: "14px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
                <p style={{ fontSize: "11px", fontWeight: "300" }}>
                  {descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl.split(".com")[0] + ".com"}
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </Panel>
      )}

      {previewState === "Mobile News Feed" && (
        <Panel shaded bordered bodyFill className='panelFormMobile'>
          <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
            <div className='ar-ad-manager-fb-preview-placeholder'>
              <img
                src={flagIcon}
                alt='flagIcon'
                style={{
                  alignItems: "center"
                }}
              />
            </div>

            <div>
              <p className='fbTitle'>Roboket</p>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div>
                  <p className='sponsorText'>Sponsored</p>
                </div>
                <div>
                  <img src={earthIcon} alt='earth icon' />
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "0px 25px", overflow: "hidden" }}>
            {description.length === 0 ? (
              <>This is your Description</>
            ) : (
              <>
                {description.split("\n").map((line: string, index: number) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </>
            )}
          </div>
          <Panel>
            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
          </Panel>
          <hr />
          <Panel>
            <div>{descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl}</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

              {calltoAction === "NO_BUTTON" && <></>}

              {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

              {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

              {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

              {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

              {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

              {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

              {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

              {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

              {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

              {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

              {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

              {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
            </div>
          </Panel>

          <Panel>
            <div className='likeShareIcon'>
              <div className='displayed'>
                <img src={likeIcon} alt='likeIcon' />
                Like
              </div>
              <div className='displayed'>
                <img src={commentIcon} alt='commentIcon' />
                Comment
              </div>
              <div className='displayed'>
                <img src={shareIcon} alt='shareIcon' />
                Share
              </div>
            </div>
          </Panel>
        </Panel>
      )}

      {previewState === "Desktop Marketplace Feed" && (
        <>
          <div className='adDiffPreview'>
            <div style={{ width: "171px" }}>
              <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
            </div>
          </div>
          <div style={{ padding: "0px 20px" }}>
            <p style={{ fontSize: "12px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
            <p style={{ fontSize: "11px", fontWeight: "300" }}>
              {descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl.split(".com")[0] + ".com"}
            </p>
          </div>
        </>
      )}

      {previewState === "Desktop Marketplace Details Page" && (
        <>
          <div className='adDiffPreview'>
            <div className='iconTool'>
              <img className='circleAshColor' style={{ width: "51px" }} src={circleIcon} alt='circleAshColor' />
              <img
                className='flagIconStyle'
                src={flagIcon}
                alt='flagIcon'
                style={{
                  position: "absolute",
                  top: 138,
                  left: 47,
                  width: "25px",
                  height: "25px"
                }}
              />
            </div>

            <div style={{ width: "271px" }}>
              <p className='fbTitleDesktop'>Roboket</p>
              <img style={{ borderRadius: "19px" }} src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
            </div>
          </div>
        </>
      )}

      {previewState === "Mobile Marketplace Feed" && (
        <Panel shaded bordered bodyFill className='panelFormMobile'>
          <div style={{ padding: "10px 15px", display: "flex", flex: "1", alignItems: "center" }}>
            <div className='ar-ad-manager-fb-preview-placeholder'>
              <img
                src={flagIcon}
                alt='flagIcon'
                style={{
                  alignItems: "center"
                }}
              />
            </div>

            <div style={{ flex: "0.9" }}>
              <p className='fbTitle'>Roboket</p>
              <div className='fbSponsor'>
                <div>
                  <p className='sponsorTextInstagram'>Sponsored</p>
                </div>
              </div>
            </div>

            <div className='sponsor' style={{ flex: "0.1", display: "flex", alignItems: "center" }}>
              Sponsored <img src={threeDot} alt='three dot' />
            </div>
          </div>
          <div style={{ padding: "0px 25px", overflow: "hidden" }}>
            {description.length === 0 ? (
              <>This is your Description</>
            ) : (
              <>
                {description.split("\n").map((line: string, index: number) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </>
            )}
          </div>
          <Panel>
            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
          </Panel>
          <hr />
          <Panel>
            <div>{descriptionUrl.length === 0 ? "adndiginet.com" : descriptionUrl.split(".com")[0] + ".com"}</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>
            </div>
            <p style={{ textAlign: "center", fontSize: "16px" }}>
              {calltoAction === "NO_BUTTON" && <></>}
              {calltoAction === "APPLY_NOW" && <span>Apply Now</span>}
              {calltoAction === "BOOK_TRAVEL" && <span>Book Now</span>}
              {calltoAction === "DOWNLOAD" && <span>Download</span>}
              {calltoAction === "CONTACT_US" && <span>Contact Us</span>}
              {calltoAction === "GET_OFFER" && <span>Get Offer</span>}
              {calltoAction === "LEARN_MORE" && <span>Learn More</span>}
              {calltoAction === "LISTEN_MUSIC" && <span>Listen Now</span>}
              {calltoAction === "MESSAGE_PAGE" && <span>Send Message</span>}
              {calltoAction === "SHOP_NOW" && <span>Shop Now</span>}
              {calltoAction === "SIGN_UP" && <span>Sign Up</span>}
              {calltoAction === "WATCH_MORE" && <span>Watch More</span>}
              {calltoAction === "BUY_NOW" && <span>Buy Now</span>} &nbsp;
              {">"}
            </p>
          </Panel>
        </Panel>
      )}

      {previewState === "General Search Results (Mobile)" && (
        <Panel shaded bordered bodyFill className='panelFormGeneral'>
          <div className='fbSponsorNew' style={{ padding: "0px 25px" }}>
            <div className='iconTool'>
              <img className='circleAshColor' src={circleIcon} alt='circleAshColor' />
              <img
                className='flagIconStyle'
                src={flagIcon}
                alt='flagIcon'
                style={{
                  position: "absolute",
                  top: 130,
                  left: 68,
                  width: "25px",
                  height: "22px"
                }}
              />
            </div>

            <div>
              <p className='fbTitle'>Roboket</p>
              <div className='fbSponsorNew'>
                <div>
                  <p className='sponsorText'>Sponsored</p>
                </div>
                <div>
                  <img src={earthIcon} alt='earth icon' />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div style={{ padding: "6px 25px", overflow: "hidden" }}>
            {description.length === 0 ? (
              <>This is your Description</>
            ) : (
              <>
                {description.split("\n").map((line: string, index: number) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </>
            )}
          </div>
          <div>
            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAdGen' />
          </div>

          <Panel>
            <div>{descriptionUrl.length === 0 ? "adndiginet.com" : descriptionUrl.split(".com")[0] + ".com"}</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

              {calltoAction === "NO_BUTTON" && <></>}

              {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

              {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

              {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

              {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

              {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

              {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

              {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

              {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

              {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

              {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

              {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

              {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
            </div>
          </Panel>
        </Panel>
      )}

      {previewState === "Marketplace Search Results (Mobile)" && <MarketPlace file={file} title={title} />}

      {previewState === "Audience Network Interstitial" && (
        <AudiNetWorkInter description={description} title={title} calltoAction={calltoAction} file={file} />
      )}

      {previewState === "Audience Network Native" && <AudiNetWorkNative title={title} calltoAction={calltoAction} file={file} />}

      {previewState === "Audience Network Banner" && <AudiNetBanner calltoAction={calltoAction} title={title} />}

      {previewState === "Instagram Feed" && <InstaFeed file={file} description={description} calltoAction={calltoAction} />}

      {previewState === "Instagram Stories" && <InstagramStory file={file} description={description} calltoAction={calltoAction} />}

      {previewState === "Instagram Explore Feed" && (
        <InstagramStoryFeed file={file} description={description} calltoAction={calltoAction} />
      )}

      {previewState === "Instant Articles" && (
        <InstantArticle file={file} description={description} title={title} calltoAction={calltoAction} />
      )}

      {previewState === "Facebook Stories" && <InstagramStory file={file} description={description} calltoAction={calltoAction} />}

      {previewState === "Messenger Stories" && <MessengerStory file={file} description={description} calltoAction={calltoAction} />}

      {previewState === "Marketplace Category Feed & Details Page" && <MarketPlace file={file} title={title} />}
    </div>
  )
}

export default FbImgDisplay18
