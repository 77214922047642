// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web__websiteCommon {
  margin: 30px 30px 0px 0px;
}
.web__websiteCommon .rs-nav-tabs.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content {
  font-size: 15px;
  padding-right: 25px;
}
.web__websiteCommon .web__siteCommonHeader {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.web__websiteCommon .web__siteCommonHeader .web__siteCommonRightSiteHeader {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
}
.web__websiteCommon .web__siteCommonHeader .web__siteCommonRightSiteHeader .ads-matrics-right {
  position: relative;
}
.web__websiteCommon .web__siteCommonRightSiteHeader .audienceBtn .rs-picker-toggle {
  background: #00ab86 !important;
  color: #fff;
}
.web__websiteCommon .web__siteCommonRightSiteHeader .audienceBtn .rs-picker-toggle-caret {
  color: #fff;
}
.web__websiteCommon .web__siteCommonRightSiteHeader .audienceBtn .rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #fff;
}
.web__websiteCommon .adtext {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.list-header .rs-nav-item {
  border-radius: 8px 8px 0px 0px;
  margin-right: 5px;
  height: 3em;
  border-bottom: 0px;
  color: var(--Blue900) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Websites/WebsiteCommon/websiteCommon.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,eAAA;EACA,mBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AADI;EACE,aAAA;EACA,yBAAA;EACA,SAAA;EACA,mBAAA;AAGN;AAFM;EACE,kBAAA;AAIR;AAAE;EACE,8BAAA;EACA,WAAA;AAEJ;AACI;EACE,WAAA;AACN;AACI;EACE,WAAA;AACN;AAGE;EACE,WAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;;AAQA;EACE,8BAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,gCAAA;AALF","sourcesContent":[".web__websiteCommon {\n  margin: 30px 30px 0px 0px;\n  .rs-nav-tabs.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content {\n    font-size: 15px;\n    padding-right: 25px;\n  }\n  .web__siteCommonHeader {\n    margin: 20px 0px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 15px;\n    .web__siteCommonRightSiteHeader {\n      display: flex;\n      justify-content: flex-end;\n      gap: 15px;\n      align-items: center;\n      .ads-matrics-right {\n        position: relative;\n      }\n    }\n  }\n  .web__siteCommonRightSiteHeader .audienceBtn .rs-picker-toggle {\n    background: #00ab86 !important;\n    color: #fff;\n  }\n  .web__siteCommonRightSiteHeader .audienceBtn {\n    .rs-picker-toggle-caret {\n      color: #fff;\n    }\n    .rs-picker-toggle .rs-picker-toggle-placeholder {\n      color: #fff;\n    }\n  }\n\n  .adtext {\n    color: #000;\n    font-family: Poppins;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n  }\n\n  //   background-color: white;\n  //   height: 100vh;\n}\n\n.list-header .rs-nav-item {\n  border-radius: 8px 8px 0px 0px;\n  margin-right: 5px;\n  height: 3em;\n  border-bottom: 0px;\n  color: var(--Blue900) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
