import axios from "src/common/axios-config"

const AdTechBaseUrlDev = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  GET_SPECIFIC_CAMPAIGN: AdTechBaseUrlDev + "/api/v1/adtech/campaign/",
  GET_SELECTED_ADS: AdTechBaseUrlDev + "/api/v1/adtech/ad/",
  GET_SELECTED_SEGMENT: AdTechBaseUrlDev + "/api/v1/adtech/segments/",
}

export const getCampaign = async (providerId: any, eid: any) => {
  const data = await axios.get(AdTechEndPoints.GET_SPECIFIC_CAMPAIGN + providerId + "/" + eid)
  return data
}

export const getSelectedAds = async (providerId: any, ad_eid: any) => {
  const params = {
    ad_eid: ad_eid
  }
  const data = await axios.get(AdTechEndPoints.GET_SELECTED_ADS + providerId , { params: params })
  return data
}

export const getSelectedSegment = async (providerId: any, segment_eid: any) => {
  const params = {
    segment_eid: segment_eid
  }
  const data = await axios.get(AdTechEndPoints.GET_SELECTED_SEGMENT + providerId + "/segment_eid", { params: params })
  return data
}

export const campaignUpdate = async (providerId: any, campId: any,payload:any) => {
  const data = await axios.put(AdTechEndPoints.GET_SPECIFIC_CAMPAIGN + providerId + '/' + campId, payload)
  return data
}