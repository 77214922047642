export function getDynamicImageSrc(data: any) {
  if (data && data.hasOwnProperty("assets") && Array.isArray(data.assets)) {
    const validAsset = data.assets.find((asset: any) => asset.subtype === "ad")
    if (validAsset) {
      return validAsset?.image_url
    }
  }
  return ""
}

export function getDynamicTitle(data: any) {
  if (data && data.hasOwnProperty("assets") && Array.isArray(data.assets)) {
    const validAsset = data.assets.find((asset: any) => asset.subtype === "title")
    if (validAsset) {
      return validAsset?.text
    }
  }
  return ""
}

export function getDynamicBrandName(data: any) {
  if (data && data.hasOwnProperty("assets") && Array.isArray(data.assets)) {
    const validAsset = data.assets.find((asset: any) => asset.subtype === "brand_name")
    return validAsset?.text
  }
  return ""
}

export function getDynamicCallAction(data: any) {
  if (data && data.hasOwnProperty("assets") && Array.isArray(data.assets)) {
    const validAsset = data.assets.find((asset: any) => asset.subtype === "call_to_action")
    return validAsset?.text
  }
  return ""
}

export function getDynamicDescription(data: any) {
  if (data && data.hasOwnProperty("assets") && Array.isArray(data.assets)) {
    const validAsset = data.assets.find((asset: any) => asset.subtype === "message")
    if (validAsset) {
      return validAsset?.text
    }
  }
  return ""
}

export function getDynamicUrl(data: any) {
  if (data && data.hasOwnProperty("assets") && Array.isArray(data.assets)) {
    const validAsset = data.assets.find((asset: any) => asset.subtype === "url")
    if (validAsset) {
      return validAsset?.text
    }
  }
  return ""
}

export const noDataMessage=()=>{
  return 'No data available'
}

export function shortenString(str: string) {
  if (str) {
    if (str.length <= 11) {
      return str
    } else {
      return str.slice(0, 9) + ".."
    }
  }
}

export function shortenStringMd(str: string) {
  if (str) {
    if (str.length <= 15) {
      return str
    } else {
      return str.slice(0, 14) + ".."
    }
  }
}

export function shortenStringEmailMd(str: string) {
  if (str) {
    if (str.length <= 20) {
      return str
    } else {
      return str.slice(0, 25) + ".."
    }
  }
}

export function shortenStringEmail(str: string) {
  if (str) {
    if (str.length <= 40) {
      return str
    } else {
      return str.slice(0, 38) + ".."
    }
  }
}

export enum SettingsMenu {
  basic = "basic",
  autoResponder = "auto_responder_access",
  webForm = "webform_connect_access",
  properties = "property_setting_access",
  contactCompanies = "contact_company_setting_access",
  users = "user_team_setting_access",
  rolePermission = "role_permission_setting_access",
  pipeline = "pipeline_setting_access",
  pipeline_ticktet = "pipeline_ticket_setting_access",
  tickets = "ticket_setting_access",
  adProvider = "ad_provider_setting_access",
  chatFlow = "chat_flow_setting_access",
  integrations = "integrations_setting_access",
  chatbot = "chatbot_setting_access",
  emailProvider = "email_provider_setting_access",
  notification = "notification_setting_access",
  accountDetails = "account_details",
  billing_details = "billing_details",
  tags_details = "tags_details"
}
