import axiosAdRoll from "src/common/axios-adroll-config"
import axios from "src/common/axios-config"
import { useProviderId } from "src/common/constants"

const AdTechBaseUrl = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  CREATE_ADS: AdTechBaseUrl + "/api/v1/adtech/ads/",
  FB_PAGE: AdTechBaseUrl + "/api/v1/adtech/get-facebook-page/",
}

export const createUploadAd = async (providerId:any,adDetails: any) => {
  const data = await axios.post(AdTechEndPoints.CREATE_ADS + providerId , adDetails)
  return data
}

export const displayFbAd = async () => {
  try {
    const providerId = await useProviderId()
    const result = await axios.get(AdTechEndPoints.FB_PAGE + providerId)
    return result.data
  } catch (error) {
    return []
  }
}
