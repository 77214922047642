import axios from "src/common/axios-config"

const AdTechBaseUrlDev = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  PROVIDER_LIST: AdTechBaseUrlDev + "/api/v1/provider/"
}

export const providerListAd = async () => {
  const data = await axios.get(AdTechEndPoints.PROVIDER_LIST)
  return data
}
