export const mockData = [
  {
    campaign: "Google Ads",
    impressions: 0,
    clicks: 0,
    cpc: 0,
    spend: 0
  },
  {
    campaign: "Facebook",
    impressions: 0,
    clicks: 0,
    cpc: 0,
    spend: 0
  },
  {
    campaign: "Linkedin",
    impressions: 0,
    clicks: 0,
    cpc: 0,
    spend: 0
  }
]

export const MockDataNew = [
  {
    label: "",
    total_cost: 0,
    total_clicks: 0,
    total_viewThroughs: 0,
    total_clickThroughs: 0,
    total_viewRevenue: 0,
    total_clickRevenue: 0,
    total_impressions: 0
  }
]

export const countryMockData = [
  {
    countryName: "United State",
    rating: "0%"
  },
  {
    countryName: "Switzerland",
    rating: "0%"
  },
  {
    countryName: "United Kingdom",
    rating: "0%"
  },
  {
    countryName: "Spain",
    rating: "0%"
  }
]

export const deviceMockData = [
  {
    deviceName: "Mobile",
    rating: "0%"
  },
  {
    deviceName: "Tablet",
    rating: "0%"
  },
  {
    deviceName: "Desktop",
    rating: "0%"
  },
  {
    deviceName: "Tablet",
    rating: "0%"
  }
]

export const summaryMockData = [
  {
    name: "Todays Ads",
    status: "up",
    value: "$0",
    date: "Wed, Jul 20",
    visitors: "",
    average: ""
  },
  {
    name: "Todays Spend",
    status: "up",
    value: "$0",
    date: "",
    visitors: "",
    average: ""
  },
  {
    name: "Today Sessions",
    status: "up",
    value: "0",
    date: "",
    visitors: "32k",
    average: ""
  },
  {
    name: "Todays Ads",
    status: "up",
    value: "0",
    date: "",
    visitors: "",
    average: "$34.28"
  }
]

export const adListMockData = [
  {
    img: "https://www.investopedia.com/thmb/RR2bD_o8u7t4a9G8JVEv99PH9dM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/GettyImages-1245748917-99e3329a7b8147e8ab648806220ce153.jpg",
    name: "Leonel Messi",
    adStatus: "Review",
    landingPage: "string",
    created: "string",
    upDated: "string",
    campaigns: "string"
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/d/d7/Cristiano_Ronaldo_playing_for_Al_Nassr_FC_against_Persepolis%2C_September_2023_%28cropped%29.jpg",
    name: "Cristiano Ronaldo",
    adStatus: "Not Review",
    landingPage: "string",
    created: "string",
    upDated: "string",
    campaigns: "string"
  },
  {
    img: "https://img.redbull.com/images/c_crop,x_1,y_0,h_3333,w_4999/c_fill,w_1920,h_1200/q_auto,f_auto/redbullcom/2021/2/5/xuyfzibqvdsmukwbkqxj/neymar-jr",
    name: "Neymar jr",
    adStatus: "Pending",
    landingPage: "string",
    created: "string",
    upDated: "string",
    campaigns: "string"
  }
]

export const adChannel = [
  {
    channelName: "Web"
  },
  {
    channelName: "Facebook & Instagram"
  }
]

export const getAllAdsList = [
  {
    eid: "3G257WLNBFHIBMYYFV8ADS",
    name: "2023Dec13 - Native Ad 600x315",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x315",
    created_date: "2023-12-13T10:23:59",
    updated_date: "2023-12-13T10:23:59",
    assets: [
      {
        ad_asset_eid: "ZRWRXW3KHNBGTLV4IG8ADS",
        asset_group_index: 0,
        crop_spec: {
          x: 0,
          y: 143,
          width: 600,
          height: 315,
          scale: 1.2
        },
        eid: "XBP3JEPCBVC4BG3L2H8ADS",
        name: "WhatsApp Image 2023-04-26 at 10.52.25 AM-removebg-preview.jpg",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/XBP/3JE/XBP3JEPCBVC4BG3L2H8ADS.jpg",
        filename: "XBP3JEPCBVC4BG3L2H8ADS.jpg",
        height: 500,
        width: 500,
        video_url: null,
        created_date: "2023-12-12T12:22:09",
        updated_date: "2023-12-12T12:22:09",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "NGAWIGTRIBGVFHAQJU8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "2XGKIUUKGJAJFOUUI68ADS",
        name: "brand_name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "Roboket",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T10:23:59",
        updated_date: "2023-12-13T10:23:59",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "GVV6EWWFWJBPZLYNE58ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "R2PTVJRFL5CXZHXR7Z8ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:21:25",
        updated_date: "2023-12-13T11:21:25",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "TFU35DWJV5AHLO553O8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "YYSEIXQVB5COZCFL338ADS",
        name: "message",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "Test30 desc",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T10:23:59",
        updated_date: "2023-12-13T10:23:59",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "DPPYXJ7RXRHIPBH6EE8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "ODL4V2U2W5GLPBBLCK8ADS",
        name: "title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "Test30",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T10:23:59",
        updated_date: "2023-12-13T10:23:59",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "XE224IQPXJHWZH2YQ58ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "HNLFZCZOONDPFNNVER8ADS",
        name: "destination_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "http://www.roboket.com?utm_source=adroll&utm_medium=adroll&utm_campaign=adroll",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T10:23:59",
        updated_date: "2023-12-13T10:23:59",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/ZRW/RXW/ZRWRXW3KHNBGTLV4IG8ADS.jpg",
    video_url: null,
    width: 600,
    height: 315,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "NFPPPBXO6RCJ3HV6UAJQEW",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "5XEJEAJ44JHQDLQUMZ8ADS",
    name: "Example static image ad",
    status: "created",
    type: "web",
    subtype: "HALF_PAGE",
    created_date: "2023-12-14T05:32:03",
    updated_date: "2023-12-14T05:32:03",
    assets: [
      {
        ad_asset_eid: "2LPK222IZNFLHIUCKY8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "D3C73BRFAZD53KDAOY8ADS",
        name: "Static ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/D3C/73B/D3C73BRFAZD53KDAOY8ADS.png",
        filename: "D3C73BRFAZD53KDAOY8ADS.png",
        height: 600,
        width: 300,
        video_url: null,
        created_date: "2023-12-14T05:32:02",
        updated_date: "2023-12-14T05:32:02",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "BVL7VLXD5JEUDOLKU68ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "FCQ6TXIYLNF3VGEL6O8ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:49:51",
        updated_date: "2023-12-14T05:49:51",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "3B56NO2JJVBWNO235F8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "IBUEOVVKSFFZ7HQ22C8ADS",
        name: "message",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:49:51",
        updated_date: "2023-12-14T05:49:51",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "GJ5NLLPNMFGBFMPET68ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "UJFN5FSB7NCRZH7OPM8ADS",
        name: "Static ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://www.example.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:32:02",
        updated_date: "2023-12-14T05:32:02",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/D3C/73B/D3C73BRFAZD53KDAOY8ADS.png",
    video_url: null,
    width: 300,
    height: 600,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "65BV6B4TYZGE7K3K3L3NXQ",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "AXQ6VWAK5NHVNM2O4Z8ADS",
    name: "a",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x500",
    created_date: "2023-12-14T06:34:37",
    updated_date: "2023-12-14T06:34:37",
    assets: [
      {
        ad_asset_eid: "JJSFU2NYSNATJOTOOH8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "LQ2PAPIQK5HFHAZSYC8ADS",
        name: "Native ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/LQ2/PAP/LQ2PAPIQK5HFHAZSYC8ADS.jpg",
        filename: "LQ2PAPIQK5HFHAZSYC8ADS.jpg",
        height: 500,
        width: 600,
        video_url: null,
        created_date: "2023-12-14T06:34:37",
        updated_date: "2023-12-14T06:34:37",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "KPCU7WPTJVHXDJTYP48ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "Y2AN2OGUOVD6NMU7RK8ADS",
        name: "Native ad brand name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "d",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:34:37",
        updated_date: "2023-12-14T06:34:37",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "ZUFNHCSKXBC5HLUFK48ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "TMY3FUKOQVALVERXHG8ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:57:32",
        updated_date: "2023-12-14T06:57:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "RUJRENCATBDXJEN4KC8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "KBTKNX47HZHOFNSWPO8ADS",
        name: "Native ad description",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "b",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:34:37",
        updated_date: "2023-12-14T06:34:37",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "5HQUJWQM6RBKHHELT48ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "DWZ7AHVD4JH6DCIANU8ADS",
        name: "Native ad title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "a",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:34:37",
        updated_date: "2023-12-14T06:34:37",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "DZBXM7GKYBCEFKM4XZ8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "BBBI7C7BCFABFHB23Z8ADS",
        name: "Native ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://facebook.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:34:37",
        updated_date: "2023-12-14T06:34:37",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/LQ2/PAP/LQ2PAPIQK5HFHAZSYC8ADS.jpg",
    video_url: null,
    width: 600,
    height: 500,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "IRGRHMRIDFBQ5LVVDZGFIK",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "B5FSTYSYKRGE3PTSGP8ADS",
    name: "w",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x500",
    created_date: "2023-12-14T06:30:44",
    updated_date: "2023-12-14T06:30:44",
    assets: [
      {
        ad_asset_eid: "ENHLIINDNJBK5M4TPQ8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "KJRYACNBTFB5ZMP2PD8ADS",
        name: "Native ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/KJR/YAC/KJRYACNBTFB5ZMP2PD8ADS.jpg",
        filename: "KJRYACNBTFB5ZMP2PD8ADS.jpg",
        height: 500,
        width: 600,
        video_url: null,
        created_date: "2023-12-14T06:30:43",
        updated_date: "2023-12-14T06:30:43",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "7TGMMZE425FWZLQQHI8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "E4UALE3OSRDEBAKOUW8ADS",
        name: "Native ad brand name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "faced",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:30:43",
        updated_date: "2023-12-14T06:30:43",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "3XOLOUZBDJGD3FJ6GJ8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "YSFVHULLMREZVIP6MU8ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:57:32",
        updated_date: "2023-12-14T06:57:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "KQLKVOLCMFGV3KJQN58ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "A4EQ2PI2DZCETIAKLY8ADS",
        name: "Native ad description",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "as",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:30:43",
        updated_date: "2023-12-14T06:30:43",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "FNYZBCZSTRFAXJIA5R8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "N5KG5MER5ZHVJJ75Z38ADS",
        name: "Native ad title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "w",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:30:43",
        updated_date: "2023-12-14T06:30:43",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "ITCGVTWTE5GO7CSZ7I8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "FPLSQIDMOJHBNO5YEH8ADS",
        name: "Native ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://facebook.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:30:43",
        updated_date: "2023-12-14T06:30:43",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/KJR/YAC/KJRYACNBTFB5ZMP2PD8ADS.jpg",
    video_url: null,
    width: 600,
    height: 500,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "OR3ZZZ3IPRA7RGWG244VA4",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "BIH2BY67WNEM7FBCYF8ADS",
    name: "w",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x500",
    created_date: "2023-12-14T06:25:27",
    updated_date: "2023-12-14T06:25:27",
    assets: [
      {
        ad_asset_eid: "WRGVP3L3FFBYTP66VO8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "3DFJ4KB2VVFHBAXNWH8ADS",
        name: "Native ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/3DF/J4K/3DFJ4KB2VVFHBAXNWH8ADS.jpg",
        filename: "3DFJ4KB2VVFHBAXNWH8ADS.jpg",
        height: 500,
        width: 600,
        video_url: null,
        created_date: "2023-12-14T06:25:26",
        updated_date: "2023-12-14T06:25:26",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "6TC7PFJFQVDHNCWCHG8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "AZ44Y4ZJTBCCVM7TIP8ADS",
        name: "Native ad brand name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "face",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:25:26",
        updated_date: "2023-12-14T06:25:26",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "6MJSXXQ6KJFLVICOJB8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "GU5ODPFZWZEENDITL68ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:57:32",
        updated_date: "2023-12-14T06:57:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "UDULO5VKWNGVZELIH38ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "5QCDMV6FFZBM3NLJ2S8ADS",
        name: "Native ad description",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "a",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:25:26",
        updated_date: "2023-12-14T06:25:26",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "5BE5JNUKYZAFHGZYPZ8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "AVMNDJEAAVFR5PYXKR8ADS",
        name: "Native ad title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "w",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:25:26",
        updated_date: "2023-12-14T06:25:26",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "Y56ZWRIRIRFJTJB5UZ8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "OWNRUS4VOVFEPJWWJN8ADS",
        name: "Native ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://facebook.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:25:26",
        updated_date: "2023-12-14T06:25:26",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/3DF/J4K/3DFJ4KB2VVFHBAXNWH8ADS.jpg",
    video_url: null,
    width: 600,
    height: 500,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "BWYCOYONMBEZ5J2BZVX6HN",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "CAFOGLJS45COXLK63N8ADS",
    name: "titke",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x500",
    created_date: "2023-12-14T05:51:33",
    updated_date: "2023-12-14T05:51:33",
    assets: [
      {
        ad_asset_eid: "5BTJI3UXVJGTPNJPII8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "34BQBYWIPZGOLCYQ6J8ADS",
        name: "Native ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/34B/QBY/34BQBYWIPZGOLCYQ6J8ADS.jpg",
        filename: "34BQBYWIPZGOLCYQ6J8ADS.jpg",
        height: 500,
        width: 600,
        video_url: null,
        created_date: "2023-12-14T05:51:32",
        updated_date: "2023-12-14T05:51:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "YS5TTPJMUVEUFLSJCR8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "R74WJSCK5VGVFP7XAU8ADS",
        name: "Native ad brand name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "face",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:51:32",
        updated_date: "2023-12-14T05:51:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "M6WUD7VS6NC6XBA2XQ8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "KG2NBWUELJAZDGI5E48ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:57:32",
        updated_date: "2023-12-14T06:57:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "UXD3FJXANFAL5N2O268ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "ZGPDSDOJJNBFVHNFI78ADS",
        name: "Native ad description",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "swax",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:51:32",
        updated_date: "2023-12-14T05:51:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "NH2Y4MOJCNDO7OXU6S8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "XDJY6273ENC6NHV7ML8ADS",
        name: "Native ad title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "titke",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:51:32",
        updated_date: "2023-12-14T05:51:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "YZHYEWMWLVHMBN4CVU8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "STM7Q775NFFXJJTDL58ADS",
        name: "Native ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://facebook.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:51:32",
        updated_date: "2023-12-14T05:51:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/34B/QBY/34BQBYWIPZGOLCYQ6J8ADS.jpg",
    video_url: null,
    width: 600,
    height: 500,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "ELTOBC2TUFESDF4KHSOXGZ",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "D6ACVOWQNRDNZI6FUK8ADS",
    name: "Test 32",
    status: "created",
    type: "web",
    subtype: "MEDIUM_RECTANGLE",
    created_date: "2023-12-13T11:21:04",
    updated_date: "2023-12-13T11:21:04",
    assets: [
      {
        ad_asset_eid: "PPTEFESG7FGIVL46VK8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "KAKYRFNFVFA53LRNIY8ADS",
        name: "Asset 1",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/KAK/YRF/KAKYRFNFVFA53LRNIY8ADS.jpg",
        filename: "KAKYRFNFVFA53LRNIY8ADS.jpg",
        height: 250,
        width: 300,
        video_url: null,
        created_date: "2023-12-13T11:21:03",
        updated_date: "2023-12-13T11:21:03",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "WBS5LQKMXBFP3BTBRG8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "PAQW7PGDIFDLDLHBM58ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:21:25",
        updated_date: "2023-12-13T11:21:25",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "XAQIOZHVRVCMDNLM448ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "ET5M654IVFDV5NQA6D8ADS",
        name: "message",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:21:25",
        updated_date: "2023-12-13T11:21:25",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "TD4UW3FTIZDXFGZGR68ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "FJS5EZYBJRFARP52XJ8ADS",
        name: "Asset 1 url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://www.roboket.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:21:03",
        updated_date: "2023-12-13T11:21:03",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/KAK/YRF/KAKYRFNFVFA53LRNIY8ADS.jpg",
    video_url: null,
    width: 300,
    height: 250,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "ZCG4VIT3YBGGZC7ET4UWZF",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "EUWQGHTFA5BSLCSXFT8ADS",
    name: "TestTitle",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x500",
    created_date: "2023-12-14T06:24:07",
    updated_date: "2023-12-14T06:24:07",
    assets: [
      {
        ad_asset_eid: "A7SOWYIK2VBXBD4BQW8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "L2FLOES7BVA2HEHHGZ8ADS",
        name: "Native ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/L2F/LOE/L2FLOES7BVA2HEHHGZ8ADS.jpg",
        filename: "L2FLOES7BVA2HEHHGZ8ADS.jpg",
        height: 500,
        width: 600,
        video_url: null,
        created_date: "2023-12-14T06:24:07",
        updated_date: "2023-12-14T06:24:07",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "GT54NJ6QG5HZXMJ4EY8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "SNF4JZAJBFDKJJRFUN8ADS",
        name: "Native ad brand name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "face",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:24:07",
        updated_date: "2023-12-14T06:24:07",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "BEQ7A7I6FZGIREFFC48ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "EORHCUCVMJD65JP37T8ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:57:32",
        updated_date: "2023-12-14T06:57:32",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "WTBK3UQZOFCQLPRFDG8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "X4YXS6TBLFBZHPMLKI8ADS",
        name: "Native ad description",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "sas",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:24:07",
        updated_date: "2023-12-14T06:24:07",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "Z4I23WKDTJGXTM57558ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "GLXTLA2ORFCN5ITE7R8ADS",
        name: "Native ad title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "TestTitle",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:24:07",
        updated_date: "2023-12-14T06:24:07",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "TLKYESWXARDZXFZZE38ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "4DMSV2IPZJB4POFNEI8ADS",
        name: "Native ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://facebook.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T06:24:07",
        updated_date: "2023-12-14T06:24:07",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/L2F/LOE/L2FLOES7BVA2HEHHGZ8ADS.jpg",
    video_url: null,
    width: 600,
    height: 500,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "4U2MS6O5JZHYNMDIT2D6KV",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "IBSDUZWXWFEL3IMI3R8ADS",
    name: "Test 33",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x500",
    created_date: "2023-12-13T11:28:52",
    updated_date: "2023-12-13T11:28:52",
    assets: [
      {
        ad_asset_eid: "6PYBDRMMTFEILDV5PB8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "KOGEZVKTIZBI7LXOGG8ADS",
        name: "Native ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/KOG/EZV/KOGEZVKTIZBI7LXOGG8ADS.jpg",
        filename: "KOGEZVKTIZBI7LXOGG8ADS.jpg",
        height: 500,
        width: 600,
        video_url: null,
        created_date: "2023-12-13T11:28:52",
        updated_date: "2023-12-13T11:28:52",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "Y3RN4CPKGZEKLMTO5Y8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "5XG572Z6CZB27IOGGI8ADS",
        name: "Native ad brand name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "AdRoll",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:28:52",
        updated_date: "2023-12-13T11:28:52",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "SZLST6GWQZFXZKTDWF8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "3TZT3CNJABDTNPKZW68ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T21:44:38",
        updated_date: "2023-12-13T21:44:38",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "FHVM4P7TQRAEHPICB68ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "XEXV7EOUXNDL7PSKGM8ADS",
        name: "Native ad description",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "Example message",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:28:52",
        updated_date: "2023-12-13T11:28:52",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "GTHSFRNKORCOPO6H5Z8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "6PDJOSPWDVFGDA3OJS8ADS",
        name: "Native ad title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "Sample title",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:28:52",
        updated_date: "2023-12-13T11:28:52",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "STHM7XNBCBCS5CXPPV8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "ZJTKOYDDCJBEZEIN7P8ADS",
        name: "Native ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://www.adroll.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-13T11:28:52",
        updated_date: "2023-12-13T11:28:52",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/KOG/EZV/KOGEZVKTIZBI7LXOGG8ADS.jpg",
    video_url: null,
    width: 600,
    height: 500,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "6BCS4PGL3FH7XFJUF35WTJ",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  },
  {
    eid: "K6U4IIR5QFDINACJZR8ADS",
    name: "a 1000000000000000",
    status: "created",
    type: "native",
    subtype: "NATIVE_AD_600x500",
    created_date: "2023-12-14T06:23:58",
    updated_date: "2023-12-14T06:23:58",
    assets: [
      {
        ad_asset_eid: "EV2WRK3FGFD7HPSHRA8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "V5GLV6UDTNEQHCINZS8ADS",
        name: "Native ad image",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "image",
        subtype: "ad",
        text: null,
        image_url: "https://s.adroll.com/a/V5G/LV6/V5GLV6UDTNEQHCINZS8ADS.jpg",
        filename: "V5GLV6UDTNEQHCINZS8ADS.jpg",
        height: 500,
        width: 600,
        video_url: null,
        created_date: "2023-12-14T05:44:16",
        updated_date: "2023-12-14T05:44:16",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "OEY6X33DP5GHDFQV248ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "YJ3SJ3LXQ5GIBEIEB58ADS",
        name: "Native ad brand name",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "brand_name",
        text: "a",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:44:16",
        updated_date: "2023-12-14T05:44:16",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "ZQJNTVKUMVCMBIMR3U8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "NIWAWSGBSREI7JANUG8ADS",
        name: "display_url",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "display_url",
        text: null,
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:49:51",
        updated_date: "2023-12-14T05:49:51",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "NGE2KT5HMZFGTGLPDD8ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "BWRR7WSOIFHMTA6UCY8ADS",
        name: "Native ad description",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "message",
        text: "a",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:44:16",
        updated_date: "2023-12-14T05:44:16",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "PTDSCTJTGJBLDB7AN58ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "SWWAXFVZPZGSBPFH3D8ADS",
        name: "Native ad title",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "title",
        text: "a",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:44:16",
        updated_date: "2023-12-14T05:44:16",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      },
      {
        ad_asset_eid: "OVJPSRBO7RADFMFQH58ADS",
        asset_group_index: 0,
        crop_spec: null,
        eid: "3DA6WPP3LFGLHB7MYX8ADS",
        name: "Native ad destination URL",
        status: "admin_review",
        used_in: [0, 0, 0],
        type: "text",
        subtype: "url",
        text: "https://facebook.com/",
        image_url: null,
        filename: null,
        height: 0,
        width: 0,
        video_url: null,
        created_date: "2023-12-14T05:44:16",
        updated_date: "2023-12-14T05:44:16",
        duration: 0,
        advertiser: {
          eid: "C5UGJD2WORG3RDBGTJYRDA"
        }
      }
    ],
    image_url: "https://s.adroll.com/a/V5G/LV6/V5GLV6UDTNEQHCINZS8ADS.jpg",
    video_url: null,
    width: 600,
    height: 500,
    is_multi_share_optimized: null,
    is_dynamic: false,
    multiple_products: 0,
    read_only: false,
    adroll_ad_eid: "Y2LA6KQBDFAZ7AAF2MRJNB",
    original_ad_eid: null,
    advertiser: {
      eid: "C5UGJD2WORG3RDBGTJYRDA"
    },
    ad_parameters: [],
    ad_parameter_variants: [],
    is_active: true,
    click_tracking_leniency: false,
    is_external: false
  }
]

export const fbData = [
  {
    label: "Desktop News Feed",
    value: "desktopfeed"
  },
  {
    label: "Mobile News Feed",
    value: "mobilefeed"
  },
  {
    label: "Feed",
    value: "feed"
  },
  {
    label: "Right-hand Column",
    value: "right_hand_column"
  },
  {
    label: "In-stream Video",
    value: "instream_video"
  },
  {
    label: "Marketplace",
    value: "marketplace"
  },
  {
    label: "Video Feeds",
    value: "video_feeds"
  },
  {
    label: "Story",
    value: "story"
  },
  {
    label: "Search",
    value: "search"
  }
]

export const deviceData = [
  {
    label: "Desktop",
    value: "desktop"
  },
  {
    label: "Mobile",
    value: "mobile"
  }
]

export const instagarmData = [
  {
    label: "Feed",
    value: "stream"
  },
  {
    label: "Story",
    value: "story"
  },
  {
    label: "Explore",
    value: "explore"
  }
]

export const messengerData = [
  {
    label: "Home",
    value: "messenger_home"
  },
  {
    label: "Story",
    value: "story"
  },
  {
    label: "Sponsored Messages",
    value: "sponsored_messages"
  }
]

export const audienceNetworkData = [
  {
    label: "Classic",
    value: "classic"
  },
  {
    label: "In-stream Video",
    value: "instream_video"
  },
  {
    label: "Rewarded Video",
    value: "rewarded_video"
  }
]

type CustomLabels = {
  [key: string]: string
}

const customLabels: CustomLabels = {
  // CONVERSIONS: "Conversion",
  LINK_CLICKS: "Link Clicks"
}

export const campaignObjectiveData = ["LINK_CLICKS"].map((item) => ({
  label: customLabels[item] || item,
  value: item
}))

type CustomLabelsRetarget = {
  [key: string]: string
}

const customLabelsRetarget: CustomLabelsRetarget = {
  CONVERSIONS: "Conversion",
  LINK_CLICKS: "Link Clicks",
  PRODUCT_CATALOG_SALES: "Product Catalog Sales"
}

export const campaignObjectiveDataRetarget = ["LINK_CLICKS"].map((item) => ({
  label: customLabelsRetarget[item] || item,
  value: item
}))

type CustomLabelsOptimization = {
  [key: string]: string
}

const customLabelsOptimization: CustomLabelsOptimization = {
  OFFSITE_CONVERSIONS: "Offsite Conversions",
  IMPRESSIONS: "Impressions",
  LINK_CLICKS: "Link Clicks",
  LANDING_PAGE_VIEWS: "Landing Page Views"
}

export const campaignOptimizationGoal = ["OFFSITE_CONVERSIONS", "IMPRESSIONS", "LINK_CLICKS", "LANDING_PAGE_VIEWS"].map((item) => ({
  label: customLabelsOptimization[item] || item,
  value: item
}))

type CustomLabelsOptimizationRetarget = {
  [key: string]: string
}

const customLabelsOptimizationRetarget: CustomLabelsOptimizationRetarget = {
  OFFSITE_CONVERSIONS: "Offsite Conversions",
  IMPRESSIONS: "Impressions",
  LINK_CLICKS: "Link Clicks",
  LANDING_PAGE_VIEWS: "Landing Page Views"
}

export const campaignOptimizationGoalRetarget = ["OFFSITE_CONVERSIONS", "IMPRESSIONS", "LINK_CLICKS", "LANDING_PAGE_VIEWS"].map((item) => ({
  label: customLabelsOptimizationRetarget[item] || item,
  value: item
}))

type CustomLabelsOptimizationLink = {
  [key: string]: string
}

const customLabelsOptimizationLink: CustomLabelsOptimizationLink = {
  IMPRESSIONS: "Impressions",
  LINK_CLICKS: "Link Clicks",
  LANDING_PAGE_VIEWS: "Landing Page Views"
}

export const campaignOptimizationGoalLink = ["IMPRESSIONS", "LINK_CLICKS"].map((item) => ({
  label: customLabelsOptimizationLink[item] || item,
  value: item
}))

export const audienceTreeData = [
  {
    label: "Gender",
    value: "gender",
    children: [
      { label: "Male", value: "gender1" },
      { label: "Female", value: "gender2" }
    ]
  },
  {
    label: "Age",
    value: "age",
    children: [
      { label: "Age 18-24", value: "age1" },
      { label: "Age 25-34", value: "age2" },
      { label: "Age 35-44", value: "age3" },
      { label: "Age 45-54", value: "age4" },
      { label: "Age 55-64", value: "age5" },
      { label: "Age 65+", value: "age6" }
    ]
  },
  {
    label: "Education Statuses",
    value: "education_level",
    children: [
      { label: "Professional degree", value: "Professional degree" },
      { label: "College grad", value: "College grad" },
      { label: "High school grad", value: "High school grad" },
      { label: "Some college", value: "Some college" },
      { label: "Associate degree", value: "Associate degree" },
      { label: "Master's degree", value: "Master's degree" }
    ]
  },
  {
    label: "Income",
    value: "income",
    children: [
      { label: "Household income: top 10% of ZIP codes (US)", value: "Household income: top 10% of ZIP codes (US)" },
      { label: "Household income: top 25%-50% of ZIP codes (US)", value: "Household income: top 25%-50% of ZIP codes (US)" },
      { label: "Household income: top 5% of ZIP codes (US)", value: "Household income: top 5% of ZIP codes (US)" }
    ]
  },
  {
    label: "Industries",
    value: "industries",
    children: [
      { label: "Arts, Entertainment, Sports and Media", value: "Arts, Entertainment, Sports and Media" },
      { label: "Business Decision Makers", value: "Business Decision Makers" },
      { label: "Cleaning and Maintenance Services", value: "Cleaning and Maintenance Services" },
      { label: "Companies founded before 2000", value: "Companies founded before 2000" },
      { label: "Companies founded between 2000 and 2009", value: "Companies founded between 2000 and 2009" },
      { label: "Companies founded between 2010 and now", value: "Companies founded between 2010 and now" },
      { label: "Company revenue: $1M to $10M", value: "Company revenue: $1M to $10M" },
      { label: "Company revenue: less than $1M", value: "Company revenue: less than $1M" },
      { label: "Company revenue: more than $10M", value: "Company revenue: more than $10M" },
      { label: "Company size: 101-500 employees", value: "Company size: 101-500 employees" },
      { label: "Company size: 11-100 employees", value: "Company size: 11-100 employees" },
      { label: "Company size: more than 500 employees", value: "Company size: more than 500 employees" },
      { label: "Computation and Mathematics", value: "Computation and Mathematics" },
      { label: "Construction and Extraction", value: "Construction and Extraction" },
      { label: "Farming, Fishing and Forestry", value: "Farming, Fishing and Forestry" },
      { label: "Government Employees (Global)", value: "Government Employees (Global)" },
      { label: "Healthcare and Medical Services", value: "Healthcare and Medical Services" },
      { label: "IT and Technical Services", value: "IT and Technical Services" },
      { label: "IT Decision Makers", value: "IT Decision Makers" },
      { label: "Legal Services", value: "Legal Services" },
      { label: "Life, Physical and Social Sciences", value: "Life, Physical and Social Sciences" },
      {
        label: "Medium business-to-business enterprise employees (200 - 500 employees)",
        value: "Medium business-to-business enterprise employees (200 - 500 employees)"
      },
      { label: "Military (Global)", value: "Military (Global)" },
      { label: "Production", value: "Production" },
      {
        label: "Small business-to-business enterprise employees (10-200 employees)",
        value: "Small business-to-business enterprise employees (10-200 employees)"
      },
      { label: "Transportation and Moving", value: "Transportation and Moving" },
      { label: "Veterans (US)", value: "Veterans (US)" }
    ]
  },
  {
    label: "Behaviors",
    value: "behaviors",
    children: [
      {
        label: "Behaviors",
        value: "Behaviors",
        children: [
          { label: "Facebook access (mobile) - Recently Detected Devices", value: "Facebook access (mobile) - Recently Detected Devices" },
          {
            label: "Facebook access (mobile) - Recently Detected Iphone 14 Devices",
            value: "Facebook access (mobile) - Recently Detected Iphone 14 Devices"
          }
        ]
      },
      {
        label: "Digital Activities",
        value: "Digital Activities",
        children: [
          { label: "New Active Business (< 12 months)", value: "New Active Business (< 12 months)" },
          { label: "New Active Business (< 24 months)", value: "New Active Business (< 24 months)" },
          { label: "New Active Business (< 6 months)", value: "New Active Business (< 6 months)" },
          { label: "Shops admins", value: "Shops admins" }
        ]
      },
      {
        label: "Digital activities",
        value: "Digital activities",
        children: [
          { label: "All creators", value: "All creators" },
          { label: "Facebook Lite app users", value: "Facebook Lite app users" },
          { label: "Food and drink creators", value: "Food and drink creators" },
          { label: "Instagram Business Profile Admins", value: "Instagram Business Profile Admins" },
          { label: "Internet personality creators", value: "Internet personality creators" },
          { label: "Facebook page admins - New Page Admins", value: "Facebook page admins - New Page Admins" },
          { label: "People who have visited Facebook Gaming", value: "People who have visited Facebook Gaming" },
          { label: "Travel and outdoors creators", value: "Travel and outdoors creators" }
        ]
      },
      {
        label: "Digital activitiesTeam",
        value: "Digital activitiesTeam",
        children: [{ label: "Music creators", value: "Music creators" }]
      },
      {
        label: "Expats",
        value: "Expats",
        children: [{ label: "Friends of those who live abroad", value: "Friends of those who live abroad" }]
      }
    ]
  }
]

export const fbPageData = [
  {
    eid: "6JNV4GVEXFBERBO4XA8C4G",
    created_date: "2024-02-19T11:35:17.971381+00:00",
    updated_date: "2024-02-19T11:35:17.971387+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 108067558720392,
    name: "Md. Ziaul Haque",
    url: "https://www.facebook.com/108067558720392",
    adaccount_eid: "MEXPKPIPJZDV5BZ4PS8C4G"
  },
  {
    eid: "L5HQ6NLQLNCKVAY24X8C4G",
    created_date: "2024-02-19T11:35:18.296904+00:00",
    updated_date: "2024-02-19T11:35:18.296909+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 1948187198829463,
    name: "ADNsms",
    url: "https://www.facebook.com/1948187198829463",
    adaccount_eid: "MEXPKPIPJZDV5BZ4PS8C4G"
  },
  {
    eid: "CSU5T2GRJFEMLLLUNX8C4G",
    created_date: "2024-02-19T11:35:15.517494+00:00",
    updated_date: "2024-03-04T03:40:18.198070+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 431773470526819,
    name: "ADN Diginet",
    url: "https://www.facebook.com/431773470526819",
    adaccount_eid: "MEXPKPIPJZDV5BZ4PS8C4G"
  },
  {
    eid: "ZE3RTO3TCJD43CW2OU8C4G",
    created_date: "2024-02-19T11:35:18.914915+00:00",
    updated_date: "2024-03-04T03:40:18.575860+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 100325485397327,
    name: "ADN Group",
    url: "https://www.facebook.com/100325485397327",
    adaccount_eid: "MEXPKPIPJZDV5BZ4PS8C4G"
  },
  {
    eid: "XDXXGEIKMVEJJAFWDU8C4G",
    created_date: "2024-02-19T11:35:16.129823+00:00",
    updated_date: "2024-03-04T03:40:17.365799+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 113851357076253,
    name: "Roboket",
    url: "https://www.facebook.com/113851357076253",
    adaccount_eid: "MEXPKPIPJZDV5BZ4PS8C4G"
  },
  {
    eid: "ZDIMZ63NFVAVZJJTA38C4G",
    created_date: "2024-02-19T11:35:17.129851+00:00",
    updated_date: "2024-03-04T03:40:17.642376+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 157813945012993,
    name: "ADN Diginet - Cloud",
    url: "https://www.facebook.com/157813945012993",
    adaccount_eid: "MEXPKPIPJZDV5BZ4PS8C4G"
  },
  {
    eid: "7KE373W7IBDO5INPCO8C4G",
    created_date: "2024-02-19T11:35:16.653320+00:00",
    updated_date: "2024-03-04T03:40:17.913914+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 568990386800901,
    name: "ADNsms",
    url: "https://www.facebook.com/568990386800901",
    adaccount_eid: "MEXPKPIPJZDV5BZ4PS8C4G"
  }
]

type CustombidValueData = {
  [key: string]: string
}

const custombidValueData: CustombidValueData = {
  LOWEST_COST_WITHOUT_CAP: "Lowest Cost Without Cap",
  LOWEST_COST_WITH_BID_CAP: "Lowest Cost With Bid Cap",
  COST_CAP: "Cost Cap",
  LOWEST_COST_WITH_MIN_ROAS: "Lowest Cost With Min Roas"
}

export const bidValueData = ["LOWEST_COST_WITHOUT_CAP", "LOWEST_COST_WITH_BID_CAP", "COST_CAP", "LOWEST_COST_WITH_MIN_ROAS"].map(
  (item) => ({
    label: custombidValueData[item] || item,
    value: item
  })
)

export const campaignData = [
  {
    advertisable_eid: "C5UGJD2WORG3RDBGTJYRDA",
    budget: {
      current_month_spend: null,
      goal: 5,
      spend: null,
      type: "daily"
    },
    budget_balancing_automated: true,
    campaigns: [
      {
        adgroups: [
          {
            ads: [
              {
                eid: "DH3NQLBIQJHCLGLIQRTRMH",
                status: "live"
              }
            ],
            campaign_eid: "LRSWETGMCBHYDAI6FI34XM",
            created_at: "2024-01-30T05:04:09",
            device_targets: {
              targets_desktop: true,
              targets_mobile_app: true,
              targets_mobile_web: false
            },
            eid: "WXI36IOZZNANTBADENIHQC",
            flights: [],
            geotargets: [],
            name: "AdGroup new",
            segments: [],
            service: "web-retargeting",
            status: "live",
            updated_at: "2024-01-30T05:04:09"
          }
        ],
        created_at: "2024-01-30T05:04:09",
        dayparting: {
          is_automatic: true,
          schedule: []
        },
        eid: "LRSWETGMCBHYDAI6FI34XM",
        frequency_cap: null,
        kpi: {
          goal: 4,
          metric: "CPC"
        },
        name: "Re-targeting Campaign 1 - 1/30/2024",
        service: "web-retargeting",
        status: "admin_review",
        updated_at: "2024-01-30T05:04:09",
        uses_external_budget: false
      }
    ],
    created_at: "2024-01-30T05:04:07.355414",
    currency: "USD",
    eid: "I5H6YB6IIZGS3J4FY48VOL",
    end_date: "2024-02-05T11:02:38",
    managed_by_spendtracker: null,
    name: "Re-targeting Campaign 1 - 1/30/2024",
    spend_floor: 1,
    start_date: "2024-01-30T11:02:38",
    status: "live",
    updated_at: "2024-01-30T05:04:07.392414"
  }
]

export const campaignDataUpdate = [
  {
    data: {
      data: {
        advertisable_eid: "C5UGJD2WORG3RDBGTJYRDA",
        budget: {
          current_month_spend: null,
          goal: 5,
          spend: null,
          type: "daily"
        },
        budget_balancing_automated: true,
        campaigns: [
          {
            adgroups: [
              {
                ads: [
                  {
                    eid: "DH3NQLBIQJHCLGLIQRTRMH",
                    status: "live"
                  },
                  {
                    eid: "NZWZPR7SY5AX5NWKKVZ5D5",
                    status: "live"
                  }
                ],

                // geotargets: [
                //   {
                //     eid: "WHSRSKRCDBBI3DTR6Q8C4G",
                //     targeted: true
                //   }
                // ],
                segments: [
                  {
                    eid: "CTO77O4TZ5AN7BHC4S8SEG",
                    targeted: true
                  },
                  {
                    eid: "5L7NHJXRIJGVZH62A28SEG",
                    targeted: true
                  }
                ],
                campaign_eid: "LRSWETGMCBHYDAI6FI34XM",
                created_at: "2024-01-30T05:04:09",
                device_targets: {
                  targets_desktop: true,
                  targets_mobile_app: true,
                  targets_mobile_web: false
                },
                eid: "WXI36IOZZNANTBADENIHQC",
                flights: [],
                name: "AdGroup new",
                service: "web-retargeting",
                status: "live",
                updated_at: "2024-01-30T05:04:09"
              }
            ],
            created_at: "2024-01-30T05:04:09",
            dayparting: {
              is_automatic: true,
              schedule: []
            },
            eid: "LRSWETGMCBHYDAI6FI34XM",
            frequency_cap: null,
            kpi: {
              goal: 4,
              metric: "CPC"
            },
            name: "Re-targeting Campaign 1 - 1/30/2024",
            service: "web-retargeting",
            status: "admin_review",
            updated_at: "2024-01-30T05:04:09",
            uses_external_budget: false
          }
        ],
        created_at: "2024-01-30T05:04:07.355414",
        currency: "USD",
        eid: "I5H6YB6IIZGS3J4FY48VOL",
        end_date: "2024-02-05T11:02:38",
        managed_by_spendtracker: null,
        name: "Re-targeting Campaign 1 - 1/30/2024",
        spend_floor: 1,
        start_date: "2024-01-30T11:02:38",
        status: "live",
        updated_at: "2024-01-30T05:04:07.392414"
      },
      errors: null,
      status: 200
    }
  }
]

export const getaSegment = [
  {
    abm_tags: null,
    advertiser_id: "C5UGJD2WORG3RDBGTJYRDA",
    conversion_value: null,
    created_date: 1701079272023,
    currency_code: null,
    duration: 3,
    is_active: true,
    is_conversion: false,
    is_lead: false,
    lead_type: null,
    max: 20,
    min: 0,
    name: "High Intent",
    product: null,
    relative_to: "top",
    segment_id: "CTO77O4TZ5AN7BHC4S8SEG",
    source: "slargma",
    subtype: null,
    tags: "s",
    type: "intent",
    value_type: "percent"
  },
  {
    abm_sales_stage_name: null,
    abm_sales_stage_order: null,
    abm_tags: null,
    advertiser_id: "C5UGJD2WORG3RDBGTJYRDA",
    conversion_value: null,
    created_date: 1703141816556,
    currency_code: null,
    duration: 120,
    external_id: null,
    first_match_date: "2023-12-22T00:00:00+00:00",
    integration_source: null,
    is_active: true,
    is_conversion: false,
    is_from_cdp: false,
    is_lead: false,
    last_adroll_graph_seg_generation_matches: null,
    last_adroll_graph_seg_generation_ts: 1705722304197,
    latest_crm_upload_ts: 1703141816721,
    lead_type: null,
    name: "Segment One",
    product: null,
    segment_id: "5L7NHJXRIJGVZH62A28SEG",
    source: "slargma",
    subtype: null,
    tags: "s",
    total_emails: 101,
    total_valid_emails: 101,
    type: "crm"
  }
]

export const fbPageList = [
  {
    eid: "PREM6OI5YFFZBISKPS8C4G",
    created_date: "2024-05-29T07:11:58.753775+00:00",
    updated_date: "2024-05-29T07:11:58.753781+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 157813945012993,
    name: "ADN Diginet - Cloud",
    url: "https://www.facebook.com/157813945012993",
    adaccount_eid: "L2JF22XFSVHGVBLY4G8C4G"
  },
  {
    eid: "RAC7QD6WSRFFZOIBED8C4G",
    created_date: "2024-05-29T07:11:58.786254+00:00",
    updated_date: "2024-05-29T07:11:58.786259+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 568990386800901,
    name: "ADNsms",
    url: "https://www.facebook.com/568990386800901",
    adaccount_eid: "L2JF22XFSVHGVBLY4G8C4G"
  },
  {
    eid: "64EAF4T6XFAGXIXIP58C4G",
    created_date: "2024-05-29T07:11:58.819428+00:00",
    updated_date: "2024-05-29T07:11:58.819434+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 108067558720392,
    name: "Md. Ziaul Haque",
    url: "https://www.facebook.com/108067558720392",
    adaccount_eid: "L2JF22XFSVHGVBLY4G8C4G"
  },
  {
    eid: "JBGPNYAFXFH5HI2GKF8C4G",
    created_date: "2024-05-29T07:11:59.072147+00:00",
    updated_date: "2024-05-29T07:11:59.072152+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 1948187198829463,
    name: "ADNsms",
    url: "https://www.facebook.com/1948187198829463",
    adaccount_eid: "L2JF22XFSVHGVBLY4G8C4G"
  },
  {
    eid: "XWSSO6DGFFAK7K3EZD8C4G",
    created_date: "2024-05-29T07:11:59.106602+00:00",
    updated_date: "2024-05-29T07:11:59.106607+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 100325485397327,
    name: "ADN Group",
    url: "https://www.facebook.com/100325485397327",
    adaccount_eid: "L2JF22XFSVHGVBLY4G8C4G"
  },
  {
    eid: "3O7G26CPO5CAFNTO2W8C4G",
    created_date: "2024-05-29T07:11:58.098310+00:00",
    updated_date: "2024-06-23T21:35:06.990454+00:00",
    advertisable_eid: "W2ZDKYA24ZDBNGFIQJQOWO",
    external_id: 431773470526819,
    name: "ADN Diginet",
    url: "https://www.facebook.com/431773470526819",
    adaccount_eid: "L2JF22XFSVHGVBLY4G8C4G"
  }
]

export const audienceDataDummy = [
  {
    eid: "NB7A2FMHLNCRFAQ4QA8SEG",
    created_date: "2024-05-29T07:11:56+00:00",
    updated_date: "2024-05-29T07:11:56+00:00",
    name: "OrderNow-Cloud",
    rule: "",
    retention_days: 120,
    approximate_count: null,
    subtype: "EXTERNAL",
    match_method: "external_facebook",
    threshold: 1,
    is_conversion: false,
    advertiser: {
      eid: "W2ZDKYA24ZDBNGFIQJQOWO"
    },
    advertisable: {
      eid: "W2ZDKYA24ZDBNGFIQJQOWO"
    },
    lookalike_audience: null
  },
  {
    eid: "2RXMFLCF2RBE5OS5E48SEG",
    created_date: "2024-05-29T07:11:56+00:00",
    updated_date: "2024-05-29T07:11:56+00:00",
    name: "AddToCartCloud",
    rule: "",
    retention_days: 120,
    approximate_count: null,
    subtype: "EXTERNAL",
    match_method: "external_facebook",
    threshold: 1,
    is_conversion: false,
    advertiser: {
      eid: "W2ZDKYA24ZDBNGFIQJQOWO"
    },
    advertisable: {
      eid: "W2ZDKYA24ZDBNGFIQJQOWO"
    },
    lookalike_audience: null
  },
  {
    eid: "AQR2HWANXZHIDIMXZF8SEG",
    created_date: "2024-06-26T07:16:49+00:00",
    updated_date: "2024-09-04T07:27:54+00:00",
    name: "ADN Diginet audience email",
    rule: "",
    retention_days: 120,
    approximate_count: 1000,
    subtype: "CUSTOM",
    match_method: "crm_data",
    threshold: 1,
    is_conversion: false,
    advertiser: {
      eid: "W2ZDKYA24ZDBNGFIQJQOWO"
    },
    advertisable: {
      eid: "W2ZDKYA24ZDBNGFIQJQOWO"
    },
    lookalike_audience: null
  }
]
