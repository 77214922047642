import React from "react"
import { ControlLabel, FormControl, FormGroup, HelpBlock, IconButton, InputGroup } from "rsuite"
import UnvisibleIcon from "@rsuite/icons/Unvisible"
import VisibleIcon from "@rsuite/icons/Visible"
import "./custom-field.styles.scss"

const CustomField = (props: any) => {
  const { name, message, label, accepter, error, password, formValue, allowInfo } = props
  const [visible, setVisible] = React.useState(false)

  const handleChange = () => {
    setVisible(!visible)
  }
  // let allowInfoValue = allowInfo;
  if (!password) {
    return (
      <FormGroup className={error ? "has-error" : ""}>
        <ControlLabel>
          {label}{" "}
          {allowInfo?.length > 0 && (
            <div className='allowInfomartionArea'>
              <span>{allowInfo}</span>
            </div>
          )}{" "}
        </ControlLabel>
        <FormControl
          value={typeof formValue === "object" ? (Object.keys(formValue).length > 0 && formValue[name] ? formValue[name] : "") : undefined}
          name={name}
          accepter={accepter}
          errorMessage={error}
          {...props}
        />
        <HelpBlock>{message}</HelpBlock>
      </FormGroup>
    )
  } else {
    return (
      <FormGroup className={error ? "has-error" : ""}>
        <ControlLabel>{label}</ControlLabel>
        <InputGroup inside style={{ width: "100%" }}>
          <FormControl name={name} accepter={accepter} errorMessage={error} {...props} type={visible ? "text" : "password"} />
          <InputGroup.Addon>
            <IconButton
              icon={visible ? <UnvisibleIcon /> : <VisibleIcon />}
              circle
              size='xs'
              style={{ marginTop: "-7px" }}
              onClick={handleChange}
            />
          </InputGroup.Addon>
          <HelpBlock>{message}</HelpBlock>
        </InputGroup>
      </FormGroup>
    )
  }
}

export default CustomField
