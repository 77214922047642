import { authenticationType } from "./authentication.types";

export const INITIAL_STATE = {
  isAuthenticated: false,
  userId: "",
  token: "",
  account: "",
  permissions: [],
};

const AuthenticationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authenticationType.IS_AUTHENTIACTED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case authenticationType.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case authenticationType.SET_USER_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case authenticationType.SET_CURRENT_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case authenticationType.SET_USER_PERMISSION:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

export default AuthenticationReducer;
