import { Alert, Button, Loader, Modal, Toggle } from "rsuite"
import { useEffect, useState } from "react"
import { useProviderId } from "../constants"
import { audienceList, audienceListFacebook } from "src/components/AddRollPanel/BoxPanel/api-panel"
import DataTable from "react-data-table-component"
import LoadingComponent from "../components/loading/Loading"

const AudienceModal = (props: any) => {
  const { handleCloseListAudience, handleRowSelectedAud, selectedCardGoal, boxBody, openListAudience, selectedRowsAud } = props
  const [load, setLoad] = useState(false)
  const [audienceData, setAudienceData] = useState([])
  const [selectedRows, setSelectedRows] = useState(selectedRowsAud)
  const [data, setData] = useState<any>([])

  useEffect(() => {
    if (audienceData) {
      const formattedData = audienceData.map((item: any) => ({
        ...item,
        selected: true
      }))
      setData(formattedData)
      handleRowSelectedAud(formattedData)

      const defaultSelectedRows = formattedData.filter((item) => item.selected).map((item) => item.eid)
      setSelectedRows(defaultSelectedRows)
    }
  }, [audienceData])

  const columnsForAudienceList = [
    {
      name: "Audience Name",
      sortable: true,
      selector: (row: any) => {
        return <div>{row?.name}</div>
      },
      width: "580px"
    },
    {
      name: "Target of Exclude",
      sortable: true,
      selector: (row: any) => {
        return (
          <div>
            {" "}
            <Toggle defaultChecked={row?.is_active} /> Targeting{" "}
          </div>
        )
      }
    },

    {
      name: "Audience Size",
      sortable: true,
      selector: (row: any) => {
        return <div>{row?.total_emails ? <> {row?.total_emails} </> : <> - </>}</div>
      }
    },

    {
      name: "Duration",
      sortable: true,
      selector: (row: any) => {
        return <div>{row?.duration}</div>
      }
    }
  ]

  const audienceListFunction = async () => {
    const providerId = await useProviderId()
    let result
    setLoad(true)
    try {
      if (boxBody === "FacebookConfigureAdBody") {
        result = await audienceListFacebook(providerId, selectedCardGoal === "retarget" ? "retarget" : "lookalike")
        setAudienceData(result)
      } else {
        result = await audienceList(providerId)
        setAudienceData(result.segments)
      }
      setLoad(false)
    } catch (error) {
      Alert.error("No Data Found")
    }
  }

  useEffect(() => {
    audienceListFunction()
  }, [])

  return (
    <>
      <Modal className='__generic_modal' backdrop={"static"} overflow show={openListAudience} full onHide={handleCloseListAudience}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Choose Audiences</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal__body'
          style={{ display: "flex", alignItems: "center", justifyContent: "center", overflow: "auto", maxHeight: "100vh" }}
        >
          {load === true ? (
            <LoadingComponent/>
          ) : (
            <DataTable
              columns={columnsForAudienceList as any}
              onSelectedRowsChange={handleRowSelectedAud}
              selectableRows
              data={data}
              selectableRowsHighlight
              contextMessage={{ singular: "row selected", plural: "rows selected" }}
            />
          )}
        </Modal.Body>
        {load === true ? (
          <></>
        ) : (
          <Modal.Footer style={{ padding: "23px" }}>
            <Button onClick={handleCloseListAudience} style={{ padding: "9px 28px" }} color='green' appearance='primary'>
              Ok
            </Button>
            <Button onClick={handleCloseListAudience} color='red' appearance='primary'>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

export default AudienceModal
