import React, { useEffect } from "react"
import { Dropdown, Nav, Sidenav } from "rsuite"
import "./sidebarComponent.scss"
import { Link, useHistory } from "react-router-dom"
import dashboardIcon from "../../assets/svgs/dashboardNew.svg"
import audIcon from "../../assets/svgs/audienceNew.svg"
import webIcon from "../../assets/svgs/websiteNew.svg"
import adsCampIcon from "../../assets/svgs/adsCampaignList.svg"
import createAdsIcon from "../../assets/svgs/createAdsNew.svg"
import adlibIcon from "../../assets/svgs/adlibNew.svg"

interface SideNavigationProps {
  selectedMenuItem: any
  onSelectMenuItem: (eventKey: string) => void
  setCollapsed: any
  handleOpen: any
  open: any
}

const SideNavigation: React.FC<SideNavigationProps> = ({ setCollapsed, onSelectMenuItem, selectedMenuItem, handleOpen, open }) => {
  useEffect(() => {
    const storedMenuItem = localStorage.getItem("selectedMenuItem")
    if (storedMenuItem) {
      onSelectMenuItem(storedMenuItem)
    }
  }, [onSelectMenuItem])

  const history = useHistory()

  const handleSelectMenuItem = (eventKey: string) => {
    localStorage.setItem("selectedMenuItem", eventKey)
    onSelectMenuItem(eventKey)
    history.push("/ads")
    setCollapsed(false)
  }

  return (
    <div>
      <Sidenav onSelect={handleSelectMenuItem} activeKey={selectedMenuItem} className='sidenav'>
        <Sidenav.Body>
          <Nav>
            <Nav.Item
              icon={<img src={dashboardIcon} height='17px' width='17px' alt='dashboard icon' />}
              eventKey='dashboard'
              className={!open ? "default-navItem-width borderBottom" : "borderBottom"}
            >
              {/* {expanded === true ? "Dashboard" : ""} */}
              {!open && "Dashboard"}
            </Nav.Item>

            <Dropdown
              eventKey='audiences'
              // placement="bottomEnd"
              className={!open ? "default-navItem-width borderBottom" : "borderBottom"}
              icon={<img src={audIcon} height='22px' width='22px' alt='audIcon icon' />}
              title={!open ? "Audiences" : ""}
            >
              <div className='dropdown-items'>
                <Link to='websites'>
                  <Dropdown.Item
                    onSelect={() => handleSelectMenuItem("websites")}
                    icon={<img src={webIcon} height='17px' width='17px' alt='web icon' />}
                    eventKey='audiences'
                    active={selectedMenuItem === "websites"}
                  >
                    {!open ? "Website" : null}
                  </Dropdown.Item>
                </Link>

                {/* <Link to="/dwc"><Dropdown.Item active={active === 'dwc'}>Dynamic Content</Dropdown.Item></Link> */}
              </div>
            </Dropdown>

            <Nav.Item
              eventKey='campaign-list'
              icon={<img src={adsCampIcon} height='22px' width='22px' alt='ads campaign icon' />}
              className={!open ? "default-navItem-width borderBottom" : "borderBottom"}
            >
              {" "}
              {!open ? "Ads Campaign List" : null}
              {/* {expanded === true ? "Ads Campaign List" : ""} */}
            </Nav.Item>
            <Nav.Item
              eventKey='create-ads'
              icon={<img src={createAdsIcon} height='22px' width='22px' alt='create ads icon' />}
              className={!open ? "default-navItem-width borderBottom" : "borderBottom"}
            >
              {!open ? "Create Ads Campaign " : null}
            </Nav.Item>
            <Nav.Item
              eventKey='ad-library'
              icon={<img src={adlibIcon} height='17px' width='17px' alt='ad library icon' />}
              className={!open ? "default-navItem-width borderBottom" : "borderBottom"}
            >
              {!open ? "Ad Library" : null}
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  )
}

interface SidebarComponentProps {
  onSelectMenuItem: (eventKey: string) => void
  selectedMenuItem: string
  setCollapsed: any
  handleOpen: any
  open: any
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({ setCollapsed, onSelectMenuItem, selectedMenuItem, handleOpen, open }) => {
  return (
    <>
      <div>
        <div
          className={
            !open
              ? "sideNav-drawer-button-main sideNav-body-header-top-collapseIn"
              : "sideNav-drawer-button-main sideNav-body-header-top-collapseOut"
          }
        >
          <div onClick={handleOpen} className='collapsible-drawer'>
            {open ? (
              <svg style={{ cursor: "pointer" }} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path
                  d='M6 17L11 12L6 7M13 17L18 12L13 7'
                  stroke='#A5A5A5'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            ) : (
              <svg style={{ cursor: "pointer" }} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path
                  d='M18 17L13 12L18 7M11 17L6 12L11 7'
                  stroke='#A5A5A5'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            )}
          </div>
        </div>
        <SideNavigation
          handleOpen={handleOpen}
          open={open}
          setCollapsed={setCollapsed}
          onSelectMenuItem={onSelectMenuItem}
          selectedMenuItem={selectedMenuItem}
        />
      </div>
    </>
  )
}

export default SidebarComponent
