// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web__deviceInsightsPageArea h5 {
  margin-top: 20px;
  padding: 0px 25%;
}

.web__deviceInsightsPageArea span {
  color: #31c3e9;
}`, "",{"version":3,"sources":["webpack://./src/pages/Websites/DeviceInsights/deviceInsights.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;;AACA;EACE,cAAA;AAEF","sourcesContent":[".web__deviceInsightsPageArea h5 {\n  margin-top: 20px;\n  padding: 0px 25%;\n}\n.web__deviceInsightsPageArea span {\n  color: #31c3e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
