import { authenticationType } from "./authentication.types";

export const setIsAuthenticated = (authentication) => ({
  type: authenticationType.IS_AUTHENTIACTED,
  payload: authentication,
});

export const setCurrentUserId = (userId) => ({
  type: authenticationType.SET_USER_ID,
  payload: userId,
});

export const setUserToken = (token) => ({
  type: authenticationType.SET_USER_TOKEN,
  payload: token,
});

export const setCurrentAccount = (account) => ({
  type: authenticationType.SET_CURRENT_ACCOUNT,
  payload: account,
});

export const setUserPermission = (permission) => ({
  type: authenticationType.SET_USER_PERMISSION,
  payload: permission,
});
