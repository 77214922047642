import { Modal } from "rsuite"
import CreateNativeAdsBody from "src/pages/CreateNativeAds/CreateNativeAdsBody"

const NativeAdModal = (props: any) => {
  const { nativeAdModal, handleClosenativeAd } = props
  return (
    <>
      <Modal backdrop={true} className='__generic_modal adsModal' full overflow size='lg' show={nativeAdModal} onHide={handleClosenativeAd}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Native Ad</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body nativeAdBody'>
          <CreateNativeAdsBody flag={"flag"} handleClosenativeAd={handleClosenativeAd} />
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}></Modal.Footer>
      </Modal>
    </>
  )
}

export default NativeAdModal
