import Axios from "axios"

import { PermissionDetails } from "../auth/PermissionDetails"
import { UserPermissionDetails } from "../auth/UserPermissionDetails"
import axios from "../axios-config"
import { ApiRoutes } from "../routes"

export async function GetUserById(id: string) {
  const result = await axios.get(ApiRoutes.GET_USER_BY_ID + `${id}`)
  return result
}

export const adminToken = process.env.REACT_APP_ADMIN_TOKEN
const adminAxios = Axios.create({
  headers: {
    Authorization: `Bearer ${adminToken}`
  }
})

export async function GetCurrentUserPermissionList() {
  const result = await axios
    .get<UserPermissionDetails>(ApiRoutes.GET_USER_ROLES_AND_PERMISSIONS)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
      return null
    })
    .catch((error) => {
      return null
    })
  return result
}

export async function GetUserByAccountId(accId: number) {
  const result = await axios.get(ApiRoutes.GET_USERS_BY_ACC_ID + accId)
  return result
}

//package identifier

export async function GetPackgeIdentifier(userid: number) {
  try {
    const result = await axios.get(ApiRoutes.BILLPAYMENT + "/sslcommerz/check-subscription")
    if (result.data) {
      return result.data
    }
  } catch (error) {
    console.log(error)
  }
  return []
}

export async function GetPackagesById(id: number) {
  try {
    const result = await adminAxios.get(ApiRoutes.PACKAGES_BASE + "/" + id)
    if (result.data) {
      return result.data
    }
  } catch (error) {
    console.log(error)
  }
  return []
}

export async function GetAllPermissionList() {
  const result = await axios
    .get<PermissionDetails>(ApiRoutes.GET_ALL_ROLES_AND_PERMISSIONS)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
      return null
    })
    .catch((error) => {
      return null
    })
  return result
}
