import React, { RefObject, createRef, useEffect, useMemo, useState } from "react"
import Dropzone, { DropzoneRef, useDropzone } from "react-dropzone"
import { cloudIcon, displayIcon, fbImageIcon, fbVideoIcon } from "src/assets"
import "./styles/AddDndFileModal.scss"
import { rulesResulationObject } from "./Rules"
import { Alert } from "rsuite"
interface AddDndFileModalProps {
  file: any
  setFile: React.Dispatch<React.SetStateAction<any>>
  selectedTypes: any
  setSelectedTypes: React.Dispatch<React.SetStateAction<any>>
}
const AddDndFileModal: React.FC<AddDndFileModalProps> = ({ file, setFile, selectedTypes, setSelectedTypes }) => {
  const dropzoneRef: RefObject<DropzoneRef> = createRef()
  const openDialog = async () => {
    if (dropzoneRef.current) {
      ;(dropzoneRef.current as any).open()
    }
  }
  useEffect(() => {
    if (file && file.length > 0 && file[0] instanceof File) {
      let filePreview = file[0]
      if (filePreview.type === "image/png" || filePreview.type === "image/jpeg") {
        const image = new Image()

        image.src = URL.createObjectURL(file[0])
        image.onload = () => {
          const aspectRatio = image.width / image.height
          const returnedData = rulesResulationObject(aspectRatio, image?.width, image?.height, filePreview.size)
          if (returnedData.includes("size error")) {
            const errorMessage = returnedData
            Alert.error(`The given ad exceeds the maximum size of ${errorMessage}`)
          } else if (returnedData.includes("default")) {
            Alert.error("Unsupported Ad Size or file")
          } else {
            setSelectedTypes(returnedData)
          }
        }
        image.onerror = (error) => {
          Alert.error("Unsupported Ad Size or file")
        }
      } else {
        Alert.error("Unsupported Ad Size or file")
      }
    }
  }, [file])

  return (
    <>
      <div className='uploadAdd__modal-addDndFile-area'>
        <div className='uploadAdd__modal-dnd-content'>
          <Dropzone ref={dropzoneRef} noClick noKeyboard>
            {({ getRootProps, getInputProps, acceptedFiles }) => {
              setFile(acceptedFiles)
              return (
                <div className='container uploadAdd__dnd-contentArea'>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps({ accept: "image/jpeg, image/png" })} />
                    <div className='uploadAdd__dnd-contentImage'>
                      <img src={cloudIcon} alt='drag_drop' />
                    </div>
                    <h5>
                      Drag & drop files or{" "}
                      <h5 className='uploadAdd__BrowserLink' onClick={openDialog}>
                        Browse
                      </h5>
                    </h5>
                    <p>Supported formats: JPEG, JPG, PNG</p>
                  </div>
                  {selectedTypes.length > 0 && !selectedTypes.includes("default") ? (
                    <div className='uploadAdd__acceptedFileList'>
                      <ul>
                        {acceptedFiles?.map((file: any) => (
                          <li key={file.path}>
                            {file.path} - {file.size} bytes
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
              )
            }}
          </Dropzone>
        </div>
        <div className='uploadAdd__dndFileFooter'>
          <div className='uploadAdd__fileFooterContent'>
            <p>Cover all your bases by uploading as many of the recommended sizes as possible</p>
          </div>
          <div className='uploadAdd__fileLastFooterContent'>
            <div className='uploadAdd__recommendedAddsArea'>
              <div className='uploadAdd__fileFooterContentCard'>
                <img src={displayIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Web Display Ads </h4>
                <p>300x250, 300x600, 160x600, 970x250, 728x90, 320x50</p>
                <p> (Maximum size is 150 KB) </p>
              </div>
              <div className='uploadAdd__fileFooterContentCard'>
                <img src={fbImageIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Native Ads</h4>
                <p>600x600, 600x315, 600x500</p>
              </div>
              <div className='uploadAdd__fileFooterContentCard'>
                <img src={fbVideoIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Facebook/Instagram Image Ads</h4>
                <p>Aspect Ratio: 9:16, 4:5, 1:1 or 1.9:1 Resolution: 600x315+ </p>
              </div>
              {/* <div className='uploadAdd__fileFooterContentCard'>
                <img src={nativeAddIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Facebook/Instagram Video Ads</h4>
                <p>Aspect Ratio: 9:16, 4:5, 1:1 or 1:9:1 Resolution: 1280x720+</p>
              </div> */}
              {/* <div className='uploadAdd__fileFooterContentCard'>
                <img src={pinterastImageIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Pinterest Video Ads</h4>
                <p>Aspect Ratio: 16:9, 1:1, 9:16, 4:5 Resolution: 1286x726+ </p>
              </div>
              <div className='uploadAdd__fileFooterContentCard'>
                <img src={pinterastVideoIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Pinterest Video Ads</h4>
                <p>300x250, 300x600, 160x608 970x250, 728x906, 326x50 </p>
              </div>
              <div className='uploadAdd__fileFooterContentCard'>
                <img src={tiktokVideoIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Pinterest Image Ads</h4>
                <p>Aspect Ratio: 2:3 Resolution: 1000x1500+</p>
              </div> */}
              {/* <div className='uploadAdd__fileFooterContentCard'>
                <img src={webVideoAnotherIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> Web Display Ads </h4>
                <p>Aspect ratio: 2:3, 1:1, 9:16</p>
              </div> */}
              {/* <div className='uploadAdd__fileFooterContentCard'>
                <img src={webVideoIcon} alt='drag_drop' />
                <h4 className='uploadAdd__fileFooterContentCardTitle'> TikTok Video Ads</h4>
                <p>Aspect Ratio: 16:9, 1:1 or 9:16 Minimum bit rate: 516Kbps.</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddDndFileModal
