import axios from "src/common/axios-config"
import { useProviderId } from "src/common/constants"

const AdTechCampignDetails = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE
const AdTechAudiencEndPoints = {
  AUDIENCE_MATRICS: AdTechCampignDetails + "/api/v1/adtech/audience_metrics",
  VISITOR_REPORTING: AdTechCampignDetails + "/api/v1/adtech/visitor-reporting",
  SEGMENT_REPORTING: AdTechCampignDetails + "/api/v1/adtech/segments"
}

export const getInsights = async (q_data: string) => {
  const p_id = await useProviderId()
  const data = await axios.get(`${AdTechAudiencEndPoints.AUDIENCE_MATRICS}/${p_id}/query?metric=${q_data}`)
  return data
}
export const getVisitorsReporting = async (q_data: any) => {
  const p_id = await useProviderId()
  const data = await axios.get(
    `${AdTechAudiencEndPoints.VISITOR_REPORTING}/${p_id}?from_date=${q_data?.fromDate}&to_date=${q_data?.toDate}`
  )
  return data
}
export const getSegmentsReporting = async () => {
  const p_id = await useProviderId()
  const data = await axios.get(`${AdTechAudiencEndPoints.SEGMENT_REPORTING}/${p_id}`)
  return data
}
