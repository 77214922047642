import { connect } from "react-redux"
import { Drawer } from "rsuite"
import { setDrawerName, toggleDrawer } from "../../redux/drawer/drawer.actions"

const DrawerComponentState = (props: any) => {
  const {
    isDrawerShow,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activePanel,
    toggleDrawer,
    backdrop = "static",
    placement = "right",
    size = "sm",
    title,
    drawerClose,
    setDrawerName
  } = props

  const handleClose = () => {
    toggleDrawer(false)
    setDrawerName("")
    if (props.drawerClose) {
      drawerClose()
    }
  }

  return (
    <Drawer className='__generic_modal' show={isDrawerShow} onHide={handleClose} backdrop={backdrop} placement={placement} size={size}>
      <Drawer.Header className='modal-header-custom'>
        <Drawer.Title>{title}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>{props.children}</Drawer.Body>
    </Drawer>
  )
}

const mapStateToProps = ({ drawer: { isDrawerShow }, drawer: { activePanel } }: any) => ({
  isDrawerShow,
  activePanel
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleDrawer: (toggle: boolean) => dispatch(toggleDrawer(toggle)),
  setDrawerName: (name: string) => dispatch(setDrawerName(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponentState)
