import { userType } from "./user.types";

export const setCurrentUser = (user) => ({
  type: userType.CURRENT_USER,
  payload: user,
});

export const setUserStatus = (status) => ({
  type: userType.CURRENT_STATUS,
  payload: status,
});

export const setLoadAvailability = (load) => ({
  type: userType.LOAD_AVAILABILITY,
  payload: load,
});
