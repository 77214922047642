import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import JavascriptTimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import { PersistGate } from "redux-persist/integration/react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// All are common css for all project
import "bootstrap/dist/css/bootstrap.css"
import "./common/styles.scss"
import "rsuite/dist/styles/rsuite-default.css"
import "react-confirm-alert/src/react-confirm-alert.css"

import App from "./App"
import { store, persistor } from "./common/redux/store"

JavascriptTimeAgo.addLocale(en)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)
