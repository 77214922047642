export const Routes = {
  CAMPAIGN: "/campaign"
}

export const BASE_URL = process.env.REACT_APP_BASE_URL_DEV
export const CAMPAIGN_BASE_URL = process.env.REACT_APP_CAMPAIGN_BASE_URL_DEV
export const PORTAL_DASHBOARD_URL = process.env.REACT_APP_PORTAL_DASHBOARD
export const ADTECH_BASE_URL = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE
export const REACT_APP_BILLING_SYSTEM = process.env.REACT_APP_BILLING_SYSTEM

export const mauticRoutes = {
  GET_CAMPAIGN_LIST: CAMPAIGN_BASE_URL + "/campaigns",
  GET_CAMPAIGN_CATEGORY: CAMPAIGN_BASE_URL + "/categories",
  FETCH_CONTACT_SEGMENT: CAMPAIGN_BASE_URL + "/segments",
  FETCH_CAMPAIGN_FORMS: CAMPAIGN_BASE_URL + "/forms",
  GET_CAMPAIGN: CAMPAIGN_BASE_URL + "",
  CREATE_CAMPAIGN: CAMPAIGN_BASE_URL + "/campaigns/new"
}

export const portalRoutes = {
  GET_MEETING: PORTAL_DASHBOARD_URL + "/meeting-complete-status",
  GET_DEALS: PORTAL_DASHBOARD_URL + "/deals-stage-with-range",
  GET_TICKETS: PORTAL_DASHBOARD_URL + "/ticket-list",
  GET_CALENDER: PORTAL_DASHBOARD_URL + "/meeting-list",
  GET_CAMPAIGN: PORTAL_DASHBOARD_URL + "/campaign"
}

export const ApiRoutes = {
  LOGIN: BASE_URL + "/api/v1/auth/login",
  REGISTER: BASE_URL + "/api/v1",
  GET_LAST_ACTIVE_ACCOUNT: BASE_URL + "/api/v1/users/user-and-active-account/",
  GET_USERS_BY_ACC_ID: BASE_URL + "/api/v1/users/accounts/",
  GET_USER_BY_ID: BASE_URL + "/api/v1/users/",
  GET_INDUSTRY_LIST: BASE_URL + "/api/v1/core/industry_list/",
  GET_ALL_ACCOUNTS: BASE_URL + "/api/v1/accounts/account_list/",
  UPDATE_ACTIVE_ACCOUNT: BASE_URL + "/api/v1/users/active-account/",
  GET_PIXEL_CONNECTION: ADTECH_BASE_URL + "/api/v1/adtech/get-pixel/",
  BILLPAYMENT: REACT_APP_BILLING_SYSTEM + "/api/v1/billing-system",

  //For Auth
  AUTH_VERIFY_EMAIL: BASE_URL + "/api/v1/users/verify-email",

  //For Users
  CREATE_USER: BASE_URL + "/api/v1/users/",
  VERIFY_USER: BASE_URL + "/api/v1/users/account-confirmation/",

  //Packages section

  PACKAGES_BASE: BASE_URL + "/api/v1/admin/packages",

  //For Campaign
  FETCH_CONTACT_SEGMENT: CAMPAIGN_BASE_URL + "/segments",
  CREATE_CAMPAIGN: CAMPAIGN_BASE_URL + "/campaigns/new",
  GET_CAMPAIGN_LIST: CAMPAIGN_BASE_URL + "/campaigns",
  GET_CAMPAIGN_CATEGORY: CAMPAIGN_BASE_URL + "/categories",

  EMAIL_BASE: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/",
  UPSERT_CAMPAIGN_CONTACT_ASSOCIATION: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/upsert-campaign-email-and-contact-association/",
  CREATE_CAMPAIGN_LIST_CONTACT_ASSOCIATION:
    CAMPAIGN_BASE_URL + "/api/v1/email-campaign/create-campaign-email-and-contact-multiple-association/",
  GET_CAMPAIGN_CONTACT_ASSOCIATION: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/get-campaign-email-and-contact-association/",
  DELETE_CAMPAIGN_CONTACT_ASSOCIATION: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/delete-campaign-email-and-contact-association/",
  GET_CAMPAIGN_GROUP_ASSOCIATION: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/get-campaign-email-and-group-association/",
  DELETE_CAMPAIGN_GROUP_ASSOCIATION: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/delete-campaign-email-and-group-association/",
  GET_CURRENCY_LIST: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/currency-list/",

  //For Social Media
  SOCIAL_MEDIA_FACEBOOK: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_MEDIA_FACEBOOK_PAGES: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/pages/accounts/",
  SOCIAL_MEDIA_FACEBOOK_POST_COUNTS: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/all-status/post-counts/accounts/",
  SOCIAL_MEDIA_GET_POST: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_MEDIA_GET_POST_STASUSES: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/post-status/all",
  SOCIAL_MEDIA_POST_LIST: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/list/accounts/",
  SOCIAL_UPDATE_FACEBOOK_POST: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_DELETE_FACEBOOK_POST: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_GET_BEST_PRACTICE_FACEBOOK: CAMPAIGN_BASE_URL + "/api/v1/social-media-best-practices/accounts/",

  //For Email Campaign Event
  GET_FILTER_EMAIL_CAMPAIGN_EVENT: CAMPAIGN_BASE_URL + "/api/v1/email-campaign-events/filter/by-date",

  //For Contact
  GET_CONTACTS_LIST: BASE_URL + "/api/v1/contacts/contact-list-details/",
  GET_CONTACTS_LIST_BY_ACCOUNT: BASE_URL + "/api/v1/contacts/accounts/",

  //For Groups
  CONTACT_GROUP: BASE_URL + "/api/v1/contact-group/",
  GET_GROUPS: BASE_URL + "/api/v1/contact-group/by-account/",

  //For Email Best Practices
  CREATE_EMAIL_BEST_PRACTICES: CAMPAIGN_BASE_URL + "/api/v1/campaign-email-best-practices/",
  GET_EMAIL_BEST_PRACTICES: CAMPAIGN_BASE_URL + "/api/v1/campaign-email-best-practices/accounts/",
  //Generate URL
  GENERATE_URL: CAMPAIGN_BASE_URL + "/api/v1/campaign-url/email/generate/",

  //smtp section
  SMTP_BASE: CAMPAIGN_BASE_URL + "/api/v1/campaign-email-settings/",

  //campaign wise smtp section
  CAMPAIGN_SMTP_BASE: CAMPAIGN_BASE_URL + "/api/v1/campaign-wise-email-settings/",

  // For Roles & Permissions
  GET_USER_ROLES_AND_PERMISSIONS: BASE_URL + "/api/v1/role/current-user/permission_list",
  GET_ALL_ROLES_AND_PERMISSIONS: BASE_URL + "/api/v1/role/permission/get_all/",

  USER_IMAGE: BASE_URL + "/api/v1/users/user-profile-picture/",

  //Notification
  GET_NOTIFICATION: BASE_URL + "/api/v1/notification/paginated/notification_list/"
}

export const CampaignApiRoutes = {
  CREATE_TASK_QUEUE: CAMPAIGN_BASE_URL + "/task-queues/"
}

export const roboketCrmUi = {
  BASE_URL: process.env.REACT_APP_CRM_UI_URL_DEV,
  Ads: process.env.REACT_APP_CAMPAIGN_UI_URL_DEV
}
