import axiosAdRoll from "src/common/axios-adroll-config"
import axios from "src/common/axios-config"
import { useProviderId } from "src/common/constants"

const AdTechBaseUrl = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  UPDATE_ADS: AdTechBaseUrl + "/api/v1/adtech/update-ads/",

  DELETE_ADS: AdTechBaseUrl + "/api/v1/adtech/delete-ads/"
}

export async function updateSpecificAds(ad_eid: string, payload: any) {
  try {
    const params = {
      ad_eid: ad_eid
    }
    const providerId = await useProviderId()
    const result = await axios.put(AdTechEndPoints.UPDATE_ADS + providerId, payload, {
      params: params
    })
    return result
  } catch (error) {
    return []
  }
}

export async function deleteSpecificAds(ad_eid: string) {
  try {
    const params = {
      ad_eid: ad_eid
    }
    const providerId = await useProviderId()
    const result = await axios.delete(
      AdTechEndPoints.DELETE_ADS + providerId,

      {
        params: params
      }
    )
    return result.data
  } catch (error) {
    return []
  }
}
