import "../styles/TypeofAdd.scss"
import { Panel } from "rsuite"
import flagIcon from "../../../assets/svgs/flagIcon.svg"
import infoIcon from "../../../assets/svgs/info.svg"
import rightArrowIcon from "../../../assets/svgs/rightIcon.svg"

type AudiNetworkType = {
  title: string
  calltoAction: string
  file: any
  flag?: string
}

const AudiNetWorkNative = (props: AudiNetworkType) => {
  const { title, calltoAction, file, flag } = props
  return (
    <>
      <Panel shaded bordered bodyFill className='panelFormAudi'>
        <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
          <div className='ar-ad-manager-fb-preview-placeholder'>
            <img
              src={flagIcon}
              alt='flagIcon'
              style={{
                alignItems: "center"
              }}
            />
          </div>

          <div style={{ flex: "0.9" }}>
            <p className='fbTitle'>Roboket</p>
            <div className='fbSponsor'>
              {" "}
              <div>
                {" "}
                <p className='sponsorText'>Sponsored</p>
              </div>{" "}
            </div>
          </div>

          <div style={{ flex: "0.1", display: "flex", alignItems: "center" }}>
            <img src={infoIcon} alt='info Icon' />
            <img src={rightArrowIcon} alt='right arrow Icon' />
          </div>
        </div>

        <Panel>
          <img src={flag === "flag" ? file : URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
        </Panel>
        <hr />
        <Panel>
          <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

          <div className='btnActionNative' style={{ marginTop: "3%" }}>
            {calltoAction === "NO_BUTTON" && <></>}

            {calltoAction === "APPLY_NOW" && <div>Apply Now</div>}

            {calltoAction === "BOOK_TRAVEL" && <div>Book Now</div>}

            {calltoAction === "DOWNLOAD" && <div>Download</div>}

            {calltoAction === "CONTACT_US" && <div>Contact Us</div>}

            {calltoAction === "GET_OFFER" && <div>Get Offer</div>}

            {calltoAction === "LEARN_MORE" && <div>Learn More</div>}

            {calltoAction === "LISTEN_MUSIC" && <div>Listen Now</div>}

            {calltoAction === "MESSAGE_PAGE" && <div>Send Message</div>}

            {calltoAction === "SHOP_NOW" && <div>Shop Now</div>}

            {calltoAction === "SIGN_UP" && <div>Sign Up</div>}

            {calltoAction === "WATCH_MORE" && <div>Watch More</div>}

            {calltoAction === "BUY_NOW" && <div>Buy Now</div>}
          </div>
        </Panel>
      </Panel>
    </>
  )
}

export default AudiNetWorkNative
