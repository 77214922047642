// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allowInfomartionArea span {
  color: #f44336;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-field/custom-field.styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;AACF","sourcesContent":[".allowInfomartionArea span {\n  color: #f44336;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
