import axiosAdRoll from "src/common/axios-adroll-config"
import axios from "src/common/axios-config"

const AdTechCampignList = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  CAMPAIGN_LIST: AdTechCampignList + "/api/v1/adtech/campaign/"
}

export const postProviderAd = async (providerId: string, fromDate: string, toDate: string) => {
  const params = {
    from_date: fromDate,
    to_date: toDate
  }
  const data = await axios.get(AdTechEndPoints.CAMPAIGN_LIST + providerId, { params: params })
  return data
}
