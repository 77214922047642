import React from "react"
import { Loader } from "rsuite"
import "./loading.scss"

interface ILoadingComponent {
  message?: string
}

const LoadingComponent: React.FC<ILoadingComponent> = ({ message }: ILoadingComponent) => {
  return (
    <div className='loadingComponent_container'>
      <Loader />
      <div>{message ? message : "Loading..."}</div>
    </div>
  )
}

export default LoadingComponent
