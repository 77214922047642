import React, { useRef, useState } from "react"
import {
  Alert,
  ButtonToolbar,
  CheckPicker,
  CheckTreePicker,
  Col,
  DatePicker,
  Grid,
  Icon,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  Panel,
  Popover,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  TagPicker,
  Toggle,
  Whisper
} from "rsuite"
import BoxPanelHeader from "./BoxPanelHeader"
import webForCamp from "../../../assets/svgs/webForCampaign.svg"
import "./styles/styles.scss"
import audienceSvg from "../../../assets/svgs/audience.svg"
import CheckOutlineIcon from "@rsuite/icons/CheckOutline"
import locationSvg from "../../../assets/svgs/location.svg"
import gridSvg from "../../../assets/svgs/grid.svg"
import createSvg from "../../../assets/svgs/create.svg"
import uploadSvg from "../../../assets/svgs/upload.svg"
import facebookSvg from "../../../assets/svgs/facebook.svg"
import imgSvg from "../../../assets/svgs/image.svg"
import tickBlueSvg from "../../../assets/svgs/tickBlue.svg"
import tickSvg from "../../../assets/svgs/tick.svg"
import plusSvg from "../../../assets/svgs/plus.svg"
import { Button } from "rsuite"
import { AspectRatio } from "react-aspect-ratio"
import DataTable from "react-data-table-component"
import { geoTargetFacebook, geoTargetWeb } from "./api-panel"
import { debounce } from "lodash"
import { columnsForAudienceList, columnsForAudienceListFb } from "src/common/utils/Utils"
import UploadsModal from "src/common/uploadsModal/UplaodModal"
import NativeAdModal from "src/common/uploadsModal/NativeAdModal"
import { audienceTreeData, bidValueData } from "src/components/mocks/mock-data"
import FbAdsModal from "src/common/uploadsModal/FbAdsModal"
import SegmentsList from "./SegmentsList"
import AudienceModal from "src/common/uploadsModal/AudienceModal"

export default function BoxPanelUpdated(props: any) {
  const {
    title,
    buttonToolbar,
    imgS,
    setWebAds,
    bidInput,
    minAge,
    maxAge,
    boxtitle,
    setFlag,
    boxBody,
    boxSubTitle,
    bidStrategyValue,
    checkCampaignTargetingType,
    handleContextualTargetsChange,
    selectedRowsAud,
    setFbAds,
    deviceTarget,
    handleNumberChange,
    dataForContextualTargets,
    inputNumberValue,
    webFlag,
    handleNameChange,
    inputValue,
    selectedRowsImg,
    handleSelectedRowsChange,
    loading,
    selectedCardGoal,
    handleRowSelectedAud,
    handleBidInput,
    selectedStartDate,
    selectedEndDate,
    selectedValue,
    handleSelectChange,
    suggestions,
    setSuggestions,
    setSelectedStartDate,
    setSelectedEndDate,
    targetingType,
    handleOpen,
    setDeviceTarget,
    selectedCardChanel,
    handleMinAgeChange,
    handleMaxAgeChange,
    handleAdPlacementChange,
    setSuggestionsSelected,
    handleBidValueChange,
    bidValue,
    checkCampaignType,
    handleChangeAudTree,
    selectedBidFlag,
    selectedBid,
    handleBidStrategyChange,
    webAdGroups
  } = props

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [checkSelectBox, setCheckSelectBox] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [openList, setOpenList] = React.useState(false)
  const [openListAudience, setOpenListAudience] = React.useState(false)

  const [startsWith, setStartsWith] = useState("")
  const [isAutomatically, setIsAutomatically] = useState(true)
  const [selectedType, setSelectedType] = useState("country")
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [nativeAdModal, setNativeAdModal] = useState(false)
  const [cacheData, setCacheData] = React.useState<any>([])
  const selectedTypeRef = useRef(selectedType)

  const handleUpload = () => {
    setOpenModal(true)
  }

  const handleNativeAd = () => {
    setNativeAdModal(true)
  }

  const trueTargets: any = []

  if (deviceTarget) {
    Object.entries(deviceTarget).forEach(([key, value]) => {
      if (value) {
        let logText = key.replace("targets_", "")
        if (logText === "desktop") {
          logText = "Desktop"
        } else if (logText === "mobile_app") {
          logText = "Mobile App"
        } else if (logText === "mobile_web") {
          logText = "Mobile Web"
        }
        trueTargets.push(logText)
      }
    })
  }

  const handleClosenativeAd = () => {
    setNativeAdModal(false)
  }

  const handleCloseListAudience = () => setOpenListAudience(false)

  const handleClose = () => setOpenModal(false)

  const handleOpenList = () => {
    setOpenList(true)
  }

  const fetchSearchData = async (value: any) => {
    try {
      const result = await geoTargetFacebook(startsWith)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchSearchDataWeb = async (value: any) => {
    try {
      const result = await geoTargetWeb(startsWith, selectedType)
      setSuggestions(result.results)
    } catch (error) {
      console.error(error)
    }
  }

  const debouncedFetchSearchData = debounce(fetchSearchData, 10)
  const debouncedFetchSearchDataWeb = debounce(fetchSearchDataWeb, 10)

  const handleInputChange = (value: any) => {
    setStartsWith(value)
    debouncedFetchSearchData(value)
    setSuggestions(value)
  }

  const handleInputChangeWeb = (value: any) => {
    setStartsWith(value)
    debouncedFetchSearchDataWeb(value)
  }

  const handleEndDateChange = (endDate: Date) => {
    if (endDate) {
      const formattedDate = endDate.toISOString().split("T")[0] + "T" + endDate.toLocaleTimeString(undefined, { hour12: false })
      setSelectedEndDate(formattedDate)
    } else {
      Alert.error("Select End Date")
    }
  }

  const handleStartDateChange = (startDate: Date) => {
    if (startDate) {
      const formattedDate = startDate.toISOString().split("T")[0] + "T" + startDate.toLocaleTimeString(undefined, { hour12: false })
      setSelectedStartDate(formattedDate)
      setStartDate(startDate)
    } else {
      Alert.error("Select Start Date")
    }
  }

  const handleOpenListAudience = async () => {
    setOpenListAudience(true)
  }

  const data = ["Desktop", "Mobile Web", "Mobile App"].map((item) => ({
    label: item,
    value: item
  }))

  const handleCloseList = () => setOpenList(false)

  const handleChange = () => {
    setIsAutomatically((prev) => !prev)
  }

  const dataWeb = ["Conversion", "Clicks", "Impressions"].map((item) => ({
    label: item,
    value: item
  }))

  const dataAgeStart = []
  for (let age = 16; age <= 65; age++) {
    const ageString = age === 65 ? "65+" : age.toString()
    dataAgeStart.push({ label: ageString, value: ageString })
  }

  const dataAgeEnd = []
  for (let age = 16; age <= 65; age++) {
    const ageString = age === 65 ? "65+" : age.toString()
    dataAgeEnd.push({ label: ageString, value: ageString })
  }

  const handleToggleCollapse = () => {
    if (isCollapsed) {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  }

  const handleHover = (event: any, width: any, height: any) => {
    const tooltip = event.target.nextElementSibling
    tooltip.style.display = "block"
    tooltip.innerText = `${width} x ${height}`
  }

  const handleLeave = (event: any) => {
    const tooltip = event.target.nextElementSibling
    tooltip.style.display = "none"
  }

  const dataPicker = ["country", "city"].map((item) => ({ label: item, value: item }))

  const handleTypeChange = (value: any) => {
    setSelectedType(value)
  }

  const Speaker = ({ ...props }) => {
    let popoverContent: string | JSX.Element = ""

    switch (selectedValue) {
      case "Impressions":
        popoverContent = (
          <>
            Target Cost Per 1000 Impressions (CPM) is typically $2.50-$6.50. <br /> Leave this black to let us automatically maximize
            impressions within <br /> your budget. If you set a specific CPM, we will optimize to reach <br /> your target (note: actual CPM
            may vary)
          </>
        )
        break
      case "Clicks":
        popoverContent = (
          <>
            Target Cost Per Click(CPC) is typically $1.00 - $5.00. <br />
            Leave this blank to let us automatically maximize clicks within <br /> your budget.If you set a specific CPC, we will optimize
            to reach <br /> your budget (note: actual CPC may vary).
          </>
        )
        break
      case "Conversion":
        popoverContent = (
          <>
            Leave this blank to let us automatically maximize conversations <br /> within your budget. If you set a specific CPA, we will
            optimize to <br /> reach your target. (Note: actual CPA may vary).
          </>
        )
        break
      default:
        popoverContent = ""
    }

    return (
      <Popover {...props}>
        <p>{popoverContent}</p>
      </Popover>
    )
  }

  const dataWebForLookaLike = ["Clicks", "Impressions"].map((item) => ({
    label: item,
    value: item
  }))

  const disabledDate = (date: any) => {
    if (startDate) {
      const fiveDaysLater = new Date(startDate)
      fiveDaysLater.setDate(startDate.getDate() + 6)

      return date < startDate || date < fiveDaysLater
    }
    return false
  }

  const handleCheckPickerChange = (values: any) => {
    setDeviceTarget(values)
    setFlag(2)
  }

  const handleSelect = (value: string, item: any) => {
    setCacheData([...cacheData, item])
    setSuggestionsSelected(value)
  }

  return (
    <div>
      <div className='boxPanel__header-area'>
        <p style={{ fontSize: "20px", fontWeight: "400" }}> {title} </p>
        <ButtonToolbar onClick={handleOpen}> {buttonToolbar} </ButtonToolbar>
      </div>
      <Panel
        className='boxPanel__header-area-content'
        header={
          <BoxPanelHeader
            imgage={imgS}
            title={boxtitle}
            boxSubTitle={boxSubTitle}
            isCollapsed={isCollapsed}
            selectedTitle={selectedCardChanel}
            handleCustomCollapse={handleToggleCollapse}
          />
        }
        collapsible={isCollapsed ? true : true}
        bordered
        expanded={isCollapsed === true ? true : false}
        onSelect={handleToggleCollapse}
      >
        {boxBody === "ChannelsBody" && (
          <div className='boxPanel__content'>
            {webFlag === "webFlag" && selectedCardChanel === "Web" ? (
              <div className={`createAdsCardChannel ${selectedCardChanel === "Web" ? "selectedCardChanel" : ""}`}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <img src={webForCamp} height='35px' width='35px' alt='web for camp' />
                  {selectedCardChanel === "Web" && (
                    <div className='iconsArea' style={{ display: "flex", justifyContent: "space-between" }}>
                      {checkSelectBox && (
                        <CheckOutlineIcon
                          style={{
                            fontSize: "1.8em",
                            marginTop: "-20px",
                            color: "green"
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className='titleStyle'>Web</div>
                <div className='slimText'>
                  <div>Advertise on Web</div>
                </div>
              </div>
            ) : (
              <div className={`createAdsCardChannel ${selectedCardChanel === "Facebook" ? "selectedCardChanel" : ""}`}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <img src={facebookSvg} height='35px' width='35px' alt='web for camp' />
                  {selectedCardChanel === "Web" && (
                    <div className='iconsArea' style={{ display: "flex", justifyContent: "space-between" }}>
                      {checkSelectBox && (
                        <CheckOutlineIcon
                          style={{
                            fontSize: "1.8em",
                            marginTop: "-20px",
                            color: "green"
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className='titleStyle'>Facebook</div>
                <div className='slimText'>
                  <div>Advertise on Facebook</div>
                </div>
              </div>
            )}
          </div>
        )}

        {boxBody === "ScheduleBody" && (
          <div className='schedule_body'>
            <h6>Campaign Schedule</h6>

            <div className='locationFlex'>
              <div>
                <p>Start Date</p>
                <DatePicker
                  format='YYYY-MM-DD HH:mm:ss'
                  value={selectedStartDate}
                  ranges={[
                    {
                      label: "Now",
                      value: new Date()
                    }
                  ]}
                  onChange={handleStartDateChange}
                />
              </div>

              <div>
                <p>End Date</p>
                <DatePicker
                  format='YYYY-MM-DD HH:mm:ss'
                  value={selectedEndDate}
                  ranges={[
                    {
                      label: "Now",
                      value: new Date()
                    }
                  ]}
                  onChange={handleEndDateChange}
                  disabledDate={disabledDate}
                />
              </div>
            </div>

            <p className='monty-proxy-schedule-section-description'>
              Upon campaign submission, there will be a review period of up to 48hrs before going live.
            </p>
          </div>
        )}

        {boxBody === "webSettingsBody" && (
          <>
            <h6 style={{ margin: "1% 0" }}>Bidding Settings</h6>
            <p className='adSchedule'>Bid Strategy</p>

            <InputGroup className='inputBid'>
              <InputGroup.Addon>
                <ButtonToolbar>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    data={
                      checkCampaignTargetingType === "lookalike" || checkCampaignTargetingType === "contextual"
                        ? dataWebForLookaLike
                        : dataWeb
                    }
                    value={selectedValue}
                    style={{ width: 184 }}
                    onChange={handleSelectChange}
                  />
                </ButtonToolbar>
              </InputGroup.Addon>

              <Whisper trigger='click' placement='bottomStart' speaker={<Speaker />}>
                <InputGroup inside>
                  <InputGroup.Addon>$</InputGroup.Addon>
                  <Input placeholder='Automatic' value={bidInput} onChange={handleBidInput} />
                </InputGroup>
              </Whisper>

              <InputGroup.Addon>
                {selectedValue === "Conversion" && "Target CPA"}
                {selectedValue === "Clicks" && "Target CPC"}
                {selectedValue === "Impressions" && "Target CPM"}
              </InputGroup.Addon>
            </InputGroup>
          </>
        )}

        {boxBody === "ConfigureBody" && (
          <div className='schedule_body'>
            <h6>Web AdGroup Settings</h6>
            <p className='adSchedule'>AdGroup Name</p>
            <Input style={{ width: "50%" }} onChange={handleNameChange} value={inputValue} />

            <h6 style={{ paddingTop: "2%" }}>Web AdGroup Settings</h6>

            {checkCampaignTargetingType === "contextual" ? (
              <>
                <br />
                <CheckPicker
                  cleanable={false}
                  searchable={false}
                  data={dataForContextualTargets}
                  appearance='default'
                  defaultValue={webAdGroups}
                  placeholder='Contextual Targets'
                  style={{ width: 324 }}
                  onChange={handleContextualTargetsChange}
                />
              </>
            ) : (
              <>
                {checkCampaignTargetingType !== "lookalike" && (
                  <div className='audienceStyle'>
                    <h6>Audience</h6>
                    <div>
                      {" "}
                      <Button appearance='primary' color='cyan' onClick={handleOpenListAudience}>
                        Choose
                      </Button>{" "}
                    </div>
                  </div>
                )}

                <SegmentsList
                  checkCampaignType={checkCampaignType}
                  selectedRowsAud={selectedRowsAud}
                  handleRowSelectedAud={handleRowSelectedAud}
                />
              </>
            )}

            <div style={{ paddingTop: "2%" }}>
              <h6>Locations</h6>
              <div className='locationFlex'>
                <div>
                  {" "}
                  <Toggle checked={isAutomatically} onChange={handleChange} />{" "}
                </div>
                <p>
                  <strong>{isAutomatically ? "Automatically" : "Manually"}</strong>
                  {isAutomatically ? " targeting the best locations (Recommended)" : " targeting locations"}
                </p>
              </div>

              {!isAutomatically && (
                <>
                  <div>
                    <SelectPicker
                      searchable={false}
                      cleanable={false}
                      defaultValue={selectedType}
                      onChange={handleTypeChange}
                      data={dataPicker}
                      style={{ width: 224 }}
                    />
                  </div>

                  <div className='countryField'>
                    <InputGroup inside className='inputLocation'>
                      <InputGroup.Addon>
                        <img src={locationSvg} alt='location svg' />
                      </InputGroup.Addon>

                      <TagPicker
                        // onChange={handleInputChangeWeb}
                        data={suggestions}
                        cacheData={cacheData}
                        onSearch={handleInputChangeWeb}
                        labelKey='name'
                        valueKey='eid'
                        onSelect={handleSelect}
                        renderMenu={(menu) => {
                          if (loading) {
                            return <p style={{ padding: 10, color: "#999", textAlign: "center" }}>Loading...</p>
                          }
                          if (React.isValidElement(menu) && menu.props.children === "No results found") {
                            return (
                              <p style={{ padding: 10, color: "#999", textAlign: "center" }}>
                                <Icon icon='spinner' spin /> Loading...
                              </p>
                            )
                          }
                          return menu
                        }}
                        renderMenuItem={(label, item: any) => {
                          return (
                            <div>
                              {selectedType === "city" ? (
                                <>
                                  {label} - {item?.region?.name}, {item?.region?.country.name}
                                </>
                              ) : (
                                <>{label} </>
                              )}
                            </div>
                          )
                        }}
                      />
                    </InputGroup>
                  </div>
                </>
              )}
            </div>

            <div style={{ paddingTop: "2%" }}>
              <h6>Environments</h6>

              <div className='locationFlex'>
                <div>
                  {" "}
                  <CheckPicker
                    cleanable={false}
                    onChange={handleCheckPickerChange}
                    placeholder='All Environments'
                    defaultValue={trueTargets}
                    data={data}
                    style={{ width: 224, paddingTop: "1%" }}
                    searchable={false}
                  />
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "2%" }}>
              <h6>Select your ads</h6>
              <p>Use your own ads or have us create a complete set for you.</p>

              <div style={{ paddingTop: "3%" }}>
                <Grid>
                  <Row>
                    <Col lg={6}>
                      <div className='divHover' onClick={handleOpenList}>
                        <img src={gridSvg} alt='grid svg' />
                        <h5 style={{ paddingTop: "4%" }}>Choose</h5>
                        <p style={{ fontSize: "14px" }}>Choose ads from you ad Library</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className='divHover' onClick={handleNativeAd}>
                        <img src={createSvg} alt='create svg' />
                        <h5 style={{ paddingTop: "4%" }}>Create</h5>
                        <p style={{ fontSize: "14px" }}>Create custom ads using our easy-to-use tool</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className='divHover' onClick={handleUpload}>
                        <img src={uploadSvg} alt='upload svg' />
                        <h5 style={{ paddingTop: "4%" }}>Upload</h5>
                        <p style={{ fontSize: "14px" }}>Already have finished ads? Upload them here.</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className='divHover opacity-low'>
                        <img src={tickSvg} alt='tick svg' />
                        <h5 style={{ paddingTop: "4%" }}>Ads</h5>
                        <p style={{ fontSize: "14px" }}>
                          Ads will be automatically uploaded to your Ad Library and you’ll be notified via email.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
            {openModal === true ? (
              <>
                <UploadsModal open={openModal} handleClose={handleClose} />
              </>
            ) : (
              <></>
            )}

            <div style={{ paddingTop: "3%" }}>
              <Row style={{ paddingBottom: "3%" }}>
                <Col lg={4}>
                  <div>
                    {" "}
                    <img src={imgSvg} /> Web Sizes{" "}
                  </div>
                </Col>

                {selectedRowsImg.map((selectedRowsImgLoop: any) => (
                  <>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 250 && selectedRowsImgLoop?.width == 300 ? (
                        <>
                          <img src={tickBlueSvg} />
                          300x250{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 600 && selectedRowsImgLoop?.width == 300 ? (
                        <>
                          <img src={tickBlueSvg} />
                          300x600
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 600 && selectedRowsImgLoop?.width == 160 ? (
                        <>
                          <img src={tickBlueSvg} />
                          160x600{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 250 && selectedRowsImgLoop?.width == 970 ? (
                        <>
                          <img src={tickBlueSvg} />
                          970x250{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 90 && selectedRowsImgLoop?.width == 728 ? (
                        <>
                          <img src={tickBlueSvg} />
                          728x90{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 50 && selectedRowsImgLoop?.width == 320 ? (
                        <>
                          <img src={tickBlueSvg} />
                          320x50
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 600 && selectedRowsImgLoop?.width == 600 ? (
                        <>
                          <img src={tickBlueSvg} />
                          600x600{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 315 && selectedRowsImgLoop?.width == 600 ? (
                        <>
                          <img src={tickBlueSvg} />
                          600x315{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 500 && selectedRowsImgLoop?.width === 600 ? (
                        <>
                          <img src={tickBlueSvg} />
                          600x500
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </Col>
                    <Col lg={2}></Col>
                  </>
                ))}
              </Row>

              <Grid>
                <Row>
                  {selectedRowsImg?.map((selectedRowsImgLoop: any, index: any) => (
                    <Col lg={6} key={index} style={{ position: "relative" }}>
                      <AspectRatio ratio={selectedRowsImgLoop?.width / selectedRowsImgLoop?.height}>
                        <img
                          src={selectedRowsImgLoop?.image_url}
                          width={selectedRowsImgLoop?.width}
                          height={selectedRowsImgLoop?.height}
                          alt={`Image ${index}`}
                          onMouseEnter={(e) => handleHover(e, selectedRowsImgLoop?.width, selectedRowsImgLoop?.height)}
                          onMouseLeave={(e) => handleLeave(e)}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            display: "none",
                            background: "rgba(255, 255, 255, 0.8)",
                            padding: "5px",
                            borderRadius: "5px"
                          }}
                        ></div>
                      </AspectRatio>
                    </Col>
                  ))}
                </Row>
              </Grid>
            </div>
          </div>
        )}

        {boxBody === "ChooseBudgetBody" && (
          <div className='schedule_body'>
            <h6>Budget Settings</h6>
            <p className='adSchedule'>Daily Budget</p>

            <InputGroup style={{ width: "20%" }}>
              <InputGroup.Addon>$</InputGroup.Addon>
              <InputNumber value={inputNumberValue} onChange={handleNumberChange} />
            </InputGroup>

            <p className='adSchedule'>This campaign starts immediately and has no set end date, with a $50.00 a day budget.</p>
          </div>
        )}

        {boxBody === "facebookSettingsBody" && (
          <>
            <h6>Facebook Page</h6>
            <p className='adSchedule' style={{ marginBottom: "1%" }}>
              We need your company's Facebook page information so you can reach potential customers on Facebook. Your ads will appear in the
              Newsfeed and link to your website.
            </p>

            <Button disabled appearance='primary'>
              Log in with Facebook
            </Button>

            <p className='adminAccess'>
              Don't have Admin access to your Facebook page?{" "}
              <a href='#' target='_blank'>
                Send an access request.
              </a>{" "}
            </p>
            <p className='_adminAccessSub'>
              By connecting your Facebook account to AdRoll to run advertising campaigns you agree to Facebook's Terms found{" "}
              <a target='_blank' href='#'>
                here.
              </a>
            </p>

            <h6 style={{ margin: "2% 0" }}>Instagram Account</h6>

            <p style={{ marginBottom: "1%", color: "#8c8c8c" }}>
              If you do not connect your Instagram account, we are still able to run ads on Instagram. Instead of using your Instagram
              account, we will use the page name and profile picture from the FB page you connected.
            </p>

            <Button disabled appearance='primary'>
              Connect Instagram Account
            </Button>

            <div className='addRoll__dashed-div'></div>

            <h6 style={{ margin: "2% 0" }}>Bidding Settings</h6>

            <p>Bid Strategy</p>

            <RadioGroup
              name='radioListClick'
              defaultValue={selectedBidFlag === false ? "Maximize clicks" : "Set your own performance target"}
              onChange={handleBidStrategyChange}
            >
              <Radio value='Maximize clicks'>
                <p style={{ fontWeight: "500" }}>Maximize clicks</p>
                <span style={{ fontSize: "13px", color: "#8c8c8c" }}>We'll optimize your bids to get you the most clicks. </span>
              </Radio>
              <Radio value='Set your own performance target'>
                <p style={{ fontWeight: "500" }}>Set your own performance target</p>
                <span style={{ fontSize: "13px", color: "#8c8c8c" }}>Enter your target CPC or CPM and we'll do the rest.</span>
              </Radio>
            </RadioGroup>

            {selectedBid === "Set your own performance target" || selectedBidFlag === true ? (
              <div style={{ margin: "2% 3%" }}>
                <div style={{ marginBottom: "2%" }}>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    defaultValue={bidValue}
                    data={bidValueData}
                    style={{ width: 234 }}
                    onChange={handleBidValueChange}
                  />
                </div>

                <InputGroup style={{ width: "100%", maxWidth: "401px" }}>
                  <InputGroup inside>
                    <InputGroup.Addon>$</InputGroup.Addon>
                    <Input value={bidInput} onChange={handleBidInput} />
                  </InputGroup>

                  <InputGroup.Addon>
                    <ButtonToolbar>
                      <SelectPicker
                        cleanable={false}
                        searchable={false}
                        value={bidStrategyValue}
                        data={dataWeb}
                        style={{ width: 184 }}
                        onChange={handleSelectChange}
                      />
                    </ButtonToolbar>
                  </InputGroup.Addon>
                </InputGroup>

                <p style={{ marginTop: "2%", color: "#8c8c8c" }}>
                  Target Cost Per 1000 Impressions (CPM) is typically $2.50 - $6.50. <br />
                  We'll optimize to reach your target, but actual CPM may vary.
                </p>
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        {boxBody === "FacebookConfigureAdBody" && (
          <div className='schedule_body'>
            <h6>Facebook AdGroup Settings</h6>
            <p className='adSchedule'>AdGroup Name</p>
            <Input style={{ width: "50%" }} onChange={handleNameChange} value={inputValue} />

            <div className='addRoll__dashed-div'></div>

            {targetingType === "attribute" && selectedCardGoal === "prospect" ? (
              <>
                <br />
                <CheckTreePicker
                  data={audienceTreeData}
                  style={{ width: 280 }}
                  uncheckableItemValues={["age", "gender"]}
                  onChange={handleChangeAudTree}
                  searchable={false}
                />
              </>
            ) : (
              <>
                <div className='audienceStyle'>
                  <h6>Audience</h6>
                  <div>
                    {" "}
                    <Button appearance='primary' color='cyan' onClick={handleOpenListAudience}>
                      Choose
                    </Button>{" "}
                    {/* <Button appearance='ghost'>Create</Button>{" "} */}
                  </div>
                </div>
                <div style={{ marginTop: "2%" }}>
                  {selectedRowsAud !== undefined && selectedRowsAud.length > 0 ? (
                    <>
                      <DataTable
                        columns={boxBody === "FacebookConfigureAdBody" ? columnsForAudienceListFb : (columnsForAudienceList as any)}
                        onSelectedRowsChange={handleRowSelectedAud}
                        data={selectedRowsAud}
                      />
                    </>
                  ) : (
                    <div className='audienceBoxStyle'>
                      <div className='audienceBoxFlex'>
                        <div>
                          {" "}
                          <img src={audienceSvg} alt='audience svg' />{" "}
                        </div>
                        <div>
                          {" "}
                          <h2 className='title'>Your Customized Audiences Here</h2>
                          <p className='pTag'>
                            {" "}
                            Target your customized audiences by creating new ones or choose existing ones from the buttons above.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div style={{ paddingTop: "2%" }}>
              <h6>Locations</h6>
              <div className='locationFlex'>
                <div>
                  {" "}
                  <Toggle checked={isAutomatically} onChange={handleChange} />{" "}
                </div>
                <p>
                  <strong>{isAutomatically ? "Automatically" : "Manually"}</strong>
                  {isAutomatically ? " targeting the best locations (Recommended)" : "  targeting locations"}
                </p>
              </div>

              {!isAutomatically && (
                <>
                  {/* <div>
                    <SelectPicker onChange={handleTypeChange} data={dataPicker} style={{ width: 224 }} />
                  </div> */}

                  <div>
                    <InputGroup inside className='inputLocation'>
                      <InputGroup.Addon>
                        <img src={locationSvg} alt='location svg' />
                      </InputGroup.Addon>

                      <InputPicker
                        onChange={handleInputChange}
                        data={suggestions}
                        onSearch={handleInputChange}
                        labelKey='country_name'
                        valueKey='eid'
                        renderMenu={(menu) => {
                          if (loading) {
                            return <p style={{ padding: 10, color: "#999", textAlign: "center" }}>Loading...</p>
                          }
                          return menu
                        }}
                      />
                    </InputGroup>
                  </div>
                </>
              )}
            </div>

            <div style={{ paddingTop: "2%" }}>
              <h6>Age Range</h6>
              <div className='locationFlex'>
                <SelectPicker
                  cleanable={false}
                  data={dataAgeStart}
                  onChange={handleMinAgeChange}
                  searchable={false}
                  value={"" + minAge}
                  style={{ width: 84 }}
                />
                <SelectPicker
                  cleanable={false}
                  data={dataAgeEnd}
                  onChange={handleMaxAgeChange}
                  searchable={false}
                  value={"" + maxAge}
                  style={{ width: 84 }}
                />
              </div>

              <p style={{ marginTop: "1%" }}>
                A defined age range may restrict audience size. Only set an age range if your content is age-specific, such as alcoholic
                beverages.
              </p>

              <div className='addRoll__dashed-div'></div>
            </div>

            <div style={{ paddingTop: "2%" }}>
              <Row>
                <Col md={12} lg={12}>
                  <p> Facebook Ad Types</p>

                  <RadioGroup name='radioList' defaultValue={"Static Ads & Video"} inline>
                    <Radio value='Static Ads & Video'>
                      <p style={{ fontWeight: "600" }}>Static Ads & Video</p>
                    </Radio>
                  </RadioGroup>
                </Col>

                <Col md={12} lg={12}>
                  <p> Ad Placement</p>

                  <RadioGroup name='radioList' defaultValue={"Automatic (Recommended)"} onChange={handleAdPlacementChange}>
                    <Radio value='Automatic (Recommended)'>
                      <p style={{ fontWeight: "600" }}>Automatic (Recommended)</p>
                    </Radio>
                    <Radio value='Manual'>
                      <p style={{ fontWeight: "600" }}>Manual</p>
                    </Radio>
                  </RadioGroup>
                </Col>
              </Row>
            </div>

            <div style={{ paddingTop: "2%" }}>
              <h6>Select your ads</h6>
              <p>Use your own ads or have us create a complete set for you.</p>

              <div style={{ paddingTop: "3%" }}>
                <Grid>
                  <Row>
                    <Col lg={8}>
                      <div className='divHover' onClick={handleOpenList}>
                        <img src={gridSvg} alt='grid svg' />
                        <h5 style={{ paddingTop: "4%" }}>Choose</h5>
                        <p style={{ fontSize: "14px" }}>Choose ads from you ad Library</p>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className='divHover' onClick={handleUpload}>
                        <img src={uploadSvg} alt='upload svg' />
                        <h5 style={{ paddingTop: "4%" }}>Upload</h5>
                        <p style={{ fontSize: "14px" }}>Already have finished ads? Upload them here.</p>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className='divHover opacity-low'>
                        <img src={tickSvg} alt='tick svg' />
                        <h5 style={{ paddingTop: "4%" }}>Ads</h5>
                        <p style={{ fontSize: "14px" }}>
                          Ads will be automatically uploaded to your Ad Library and you’ll be notified via email.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>

            {openModal === true ? (
              <>
                <UploadsModal open={openModal} handleClose={handleClose} />
              </>
            ) : (
              <></>
            )}

            <div style={{ paddingTop: "3%" }}>
              <Row style={{ paddingBottom: "3%" }}>
                <Col lg={4}>
                  <div>
                    {" "}
                    <img src={imgSvg} /> Web Sizes{" "}
                  </div>
                </Col>

                {selectedRowsImg.map((selectedRowsImgLoop: any) => (
                  <>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 250 && selectedRowsImgLoop?.width == 300 ? (
                        <>
                          <img src={tickBlueSvg} />
                          300x250{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 600 && selectedRowsImgLoop?.width == 300 ? (
                        <>
                          <img src={tickBlueSvg} />
                          300x600
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 600 && selectedRowsImgLoop?.width == 160 ? (
                        <>
                          <img src={tickBlueSvg} />
                          160x600{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 250 && selectedRowsImgLoop?.width == 970 ? (
                        <>
                          <img src={tickBlueSvg} />
                          970x250{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 90 && selectedRowsImgLoop?.width == 728 ? (
                        <>
                          <img src={tickBlueSvg} />
                          728x90{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 50 && selectedRowsImgLoop?.width == 320 ? (
                        <>
                          <img src={tickBlueSvg} />
                          320x50
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 600 && selectedRowsImgLoop?.width == 600 ? (
                        <>
                          <img src={tickBlueSvg} />
                          600x600{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 315 && selectedRowsImgLoop?.width == 600 ? (
                        <>
                          <img src={tickBlueSvg} />
                          600x315{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col lg={2}>
                      {selectedRowsImgLoop?.height === 500 && selectedRowsImgLoop?.width === 600 ? (
                        <>
                          <img src={tickBlueSvg} />
                          600x500
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </Col>
                    <Col lg={2}></Col>
                  </>
                ))}
              </Row>

              <Grid>
                <Row>
                  {selectedRowsImg.map((selectedRowsImgLoop: any, index: any) => (
                    <Col lg={6} key={index} style={{ position: "relative" }}>
                      <AspectRatio ratio={selectedRowsImgLoop?.width / selectedRowsImgLoop?.height}>
                        <img
                          src={selectedRowsImgLoop?.image_url}
                          width={selectedRowsImgLoop?.width}
                          height={selectedRowsImgLoop?.height}
                          alt={`Image ${index}`}
                          onMouseEnter={(e) => handleHover(e, selectedRowsImgLoop?.width, selectedRowsImgLoop?.height)}
                          onMouseLeave={(e) => handleLeave(e)}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            display: "none",
                            background: "rgba(255, 255, 255, 0.8)",
                            padding: "5px",
                            borderRadius: "5px"
                          }}
                        ></div>
                      </AspectRatio>
                    </Col>
                  ))}
                </Row>
              </Grid>
            </div>
          </div>
        )}

        <div className='addRoll__dashed-div'></div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{ gap: "10px", display: "flex" }}>
            {boxBody === "ConfigureBodys" && (
              <Button appearance='ghost' onClick={handleToggleCollapse}>
                Duplicate AdGroup
              </Button>
            )}

            {boxBody === "ConfigureBodys" && (
              <Button appearance='ghost' onClick={handleToggleCollapse}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={plusSvg} alt='plus icon' /> New AdGroup
                </div>
              </Button>
            )}
          </div>
          <div>
            <Button appearance='ghost' onClick={handleToggleCollapse}>
              Done
            </Button>
          </div>
        </div>
      </Panel>
      {/* ============== */}

      <NativeAdModal handleClosenativeAd={handleClosenativeAd} nativeAdModal={nativeAdModal} />

      <FbAdsModal
        setFbAds={setFbAds}
        handleCloseList={handleCloseList}
        openList={openList}
        handleSelectedRowsChange={handleSelectedRowsChange}
        boxBody={boxBody}
        setWebAds={setWebAds}
      />

      {openListAudience === true && (
        <AudienceModal
          handleRowSelectedAud={handleRowSelectedAud}
          selectedCardGoal={boxBody === "FacebookConfigureAdBody" ? selectedCardGoal : checkCampaignTargetingType}
          boxBody={boxBody}
          openListAudience={openListAudience}
          handleCloseListAudience={handleCloseListAudience}
          selectedRowsAud={selectedRowsAud}
        />
      )}
    </div>
  )
}
