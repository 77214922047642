import axiosAdRoll from "src/common/axios-adroll-config"
import axios from "src/common/axios-config"
import { useProviderId } from "src/common/constants"

const AdTechBaseUrl = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  GEOTARGET_ADS: AdTechBaseUrl + "/geo/api/search/v2/geo",
  GEOTARGET_ADS_FB: AdTechBaseUrl + "/api/v1/adtech/facebook/search-geo/",
  GEOTARGET_ADS_WEB: AdTechBaseUrl + "/api/v1/adtech/search-geo/",
  GET_AUDIENCE: AdTechBaseUrl + "/api/v1/adtech/segments/",
  GET_AUDIENCE_FB: AdTechBaseUrl + "/api/v1/adtech/facebook/segments/",
  GET_GEO_FB: AdTechBaseUrl + "/api/v1/adtech/facebook/search-geo/",
  POST_CAMP: AdTechBaseUrl + "/api/v1/adtech/campaign/"
}

export async function geoTarget(geo_types: string, starts_with: string, apiKey: string) {
  try {
    const params = {
      geo_types: geo_types,
      starts_with: starts_with,
      apikey: apiKey
    }
    const result = await axiosAdRoll.get(AdTechEndPoints.GEOTARGET_ADS, {
      params: params
    })
    return result.data
  } catch (error) {
    return []
  }
}

export async function geoTargetFacebook(starts_with: string) {
  const providerId = await useProviderId()
  try {
    const params = {
      starts_with: starts_with
    }
    const result = await axios.get(AdTechEndPoints.GET_GEO_FB + providerId, {
      params: params
    })
    return result.data
  } catch (error) {
    return []
  }
}

export async function geoTargetWeb(starts_with: any, selectedType: string) {
  const providerId = await useProviderId()
  try {
    const result = await axios.post(AdTechEndPoints.GEOTARGET_ADS_WEB + providerId, null, {
      params: {
        starts_with: starts_with,
        geo_types: selectedType
      }
    })
    return result.data
  } catch (error) {
    return []
  }
}

export async function audienceList(providerId: string) {
  try {
    const result = await axios.get(AdTechEndPoints.GET_AUDIENCE + providerId)
    return result.data
  } catch (error) {
    return []
  }
}

export async function audienceListFacebook(providerId: any, segment_type: string) {
  try {
    const params = {
      segment_type: segment_type
    }
    const result = await axios.get(AdTechEndPoints.GET_AUDIENCE_FB + providerId, {
      params: params
    })
    return result.data
  } catch (error) {
    return []
  }
}

export async function retargetingCamp(providerId: any, payload: any) {
  try {
    const result = await axios.post(AdTechEndPoints.POST_CAMP + providerId, payload)
    return result.data
  } catch (error) {
    return []
  }
}
