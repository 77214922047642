import axios from "src/common/axios-config"

const AdTechBaseUrl = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  FACEBOOK_ADS: AdTechBaseUrl + "/api/v1/adtech/filtered-ads/"
}

export async function fetchWebAds(providerId: string, ads_type: string) {
  try {
    const params = {
      ads_type: ads_type
    }
    const result = await axios.get(AdTechEndPoints.FACEBOOK_ADS + providerId, {
      params: params
    })
    return result.data
  } catch (error) {
    return []
  }
}
