import Axios from "axios"

export const access_token_ad_tech = process.env.REACT_APP_ADTECH_TOKEN
const axiosAdRoll = Axios.create({
  headers: {
    Authorization: `Token ${access_token_ad_tech}`
  }
})

export default axiosAdRoll
