import axiosAdRoll from "src/common/axios-adroll-config"
import { getUserId } from "src/common/constants"

const USER_BASE_URL = process.env.REACT_APP_BASE_URL_DEV

const userId = getUserId()

const AdTechEndPointsUser = {
  USER_INFO: USER_BASE_URL + "/api/v1/users/" + userId
}

export async function userInfoApi() {
  try {
    const result = await axiosAdRoll.get(AdTechEndPointsUser.USER_INFO)
    return result.data
  } catch (error) {
    return []
  }
}
