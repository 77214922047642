import { useState } from "react"
import { ToastContainer } from "react-toastify"
import { Modal } from "rsuite"
import AddDndFileModal from "src/components/UploadAdd/AddDndFileModal"
import TypeofAdd from "src/components/UploadAdd/TypeofAdd"

const UploadsModal = (props: any) => {
  const { open, handleClose } = props
  const [file, setFile] = useState<any>({})
  const [selectedTypes, setSelectedTypes] = useState<string[]>([])
  const [imgUplaod, setImgUpload] = useState<string>("")

  return (
    <>
      <Modal backdrop={true} full className='__generic_modalUploadAds' overflow size='md' show={open} onHide={handleClose}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Upload Ads</Modal.Title>
          <ToastContainer />
        </Modal.Header>
        <Modal.Body className='modal__body' style={{ overflow: "auto", minHeight: "65vh" }}>
          <div className='modalPreview'>
            {file && file?.length > 0 && selectedTypes.length > 0 ? (
              <TypeofAdd
                imgUplaod={imgUplaod}
                file={file}
                setFile={setFile}
                selectedType={selectedTypes}
                setSelectedTypes={setSelectedTypes}
              />
            ) : (
              <AddDndFileModal file={file} setFile={setFile} selectedTypes={selectedTypes} setSelectedTypes={setSelectedTypes} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}></Modal.Footer>
      </Modal>
    </>
  )
}

export default UploadsModal
