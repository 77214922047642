import React from "react"
import { Col, Row } from "rsuite"
import "./getStarted.scss"

import CheckRoundIcon from "@rsuite/icons/CheckRound"
import { useState } from "react"

const GetStarted = (props: any) => {
  const { onGoalSelect } = props
  const [selectedGoal, setSelectedGoal] = useState("retargeting")

  const handleGoalClick = (goal: any) => {
    setSelectedGoal(goal)
    onGoalSelect(goal)
  }

  return (
    <>
      <div className=''>
        <div className='monty-marketing-goal-select-page-header'>
          <h2>What's your marketing goal?</h2>
          <p className='monty-marketing-goal-select-page-subtitle'>
            Choose the targeting type that would best help you achieve your marketing goal
          </p>
        </div>
        <div className='webAdCampaign'>
          <div className='monty-marketing-goal-select-page-targeting-menu'>
            <div
              className={`marketing-goal-btn goal-retargeting ${selectedGoal === "retargeting" ? "active" : ""}`}
              onClick={() => handleGoalClick("retargeting")}
            >
              <div>{selectedGoal === "retargeting" ? <CheckRoundIcon color='#00AB86' /> : <CheckRoundIcon color='#e0e0e0' />}</div>
              <div> Retargeting</div>
            </div>
            <div
              className={`marketing-goal-btn goal-lookalike ${selectedGoal === "lookalike" ? "active" : ""}`}
              onClick={() => handleGoalClick("lookalike")}
            >
              <div>{selectedGoal === "lookalike" ? <CheckRoundIcon color='#00AB86' /> : <CheckRoundIcon color='#e0e0e0' />}</div>
              <div> Lookalike Targeting</div>
            </div>
            <div
              className={`marketing-goal-btn goal-contextual ${selectedGoal === "contextual" ? "active" : ""}`}
              onClick={() => handleGoalClick("contextual")}
            >
              <div>{selectedGoal === "contextual" ? <CheckRoundIcon color='#00AB86' /> : <CheckRoundIcon color='#e0e0e0' />}</div>
              <div> Contextual</div>
            </div>
          </div>

          {selectedGoal === "retargeting" && (
            <div className='card-body-retargeting'>
              <Row>
                <Col lg={12} md={12}>
                  <div className='nav-body'>
                    <div className='monty-marketing-goal-select-page-targeting-description'>
                      <h3>Retargeting</h3>
                      <p>
                        Stay top-of-mind by retargeting people who have visited your website but not completed your target action. Your
                        potential customers will see your ads on our expansive network of digital advertising partner websites as you
                        strategically bring them back to the point of purchase.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={2}></Col>
                <Col lg={10} md={10}>
                  <img
                    width='200px'
                    src='https://assets1.adroll.com/rollup/ar-style-base/13.9.2/images/targeting-type-images/06-retargeting-on-web.png'
                    alt='retargetingImage'
                  />
                </Col>
              </Row>
            </div>
          )}

          {selectedGoal === "lookalike" && (
            <div className='card-body-targeting'>
              <Row>
                <Col lg={12} md={12}>
                  <div className='nav-body'>
                    <div className='monty-marketing-goal-select-page-targeting-description'>
                      <h3>Lookalike Targeting</h3>
                      <p>
                        Let our AI do the work of identifying people who look and act like the people who already engage with your brand.
                        AdRoll’s lookalike targeting really shines when used in conjunction with our other audience targeting options, in
                        related campaigns.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={2}></Col>
                <Col lg={10} md={10}>
                  <img
                    width='300px'
                    src='https://assets1.adroll.com/rollup/ar-style-base/13.9.2/images/targeting-type-images/02-lookalike-targeting-on-web.png'
                    alt='targetingImage'
                  />
                </Col>
              </Row>
            </div>
          )}

          {selectedGoal === "contextual" && (
            <div className='card-body-contextual'>
              <Row>
                <Col lg={12} md={12}>
                  <div className='nav-body'>
                    <div className='monty-marketing-goal-select-page-targeting-description'>
                      <h3>Contextual Targeting</h3>
                      <p>
                        Showcase your brand alongside content that matters to your ideal customer. Simply select the categories that are
                        relevant to your industry or target market. Categories include sports, health and wellness, education, and so much
                        more.{" "}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={2}></Col>
                <Col lg={10} md={10}>
                  <img
                    width='300px'
                    src='https://assets1.adroll.com/rollup/ar-style-base/13.9.2/images/targeting-type-images/01-contextual-targeting-on-web.png'
                    alt='contextualImage'
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default GetStarted
