import React, { useState } from "react"
import "./styles/TypeofAdd.scss"
import { Alert, Button, Col, Form, Grid, Input, List, Modal, Panel, Row, Schema, SelectPicker } from "rsuite"
import { web } from "src/assets"
import { monthNames, typedOfAdd } from "./Rules"
import flagIcon from "../../assets/svgs/flagIcon.svg"
import earthIcon from "../../assets/svgs/earth.svg"
import likeIcon from "../../assets/svgs/like.svg"
import commentIcon from "../../assets/svgs/comment.svg"
import shareIcon from "../../assets/svgs/share.svg"
import circleIcon from "../../assets/svgs/cricleBackground.svg"
import { createUploadAd, displayFbAd } from "./apiUploadAd"
import { convertImageToBase64, convertImageToBase64Ad } from "src/common/utils/Utils"
import { useHistory } from "react-router-dom"
import LoadingComponent from "src/common/components/loading/Loading"

import { Constants, useProviderId } from "src/common/constants"
import CustomField from "../custom-field/custom-field.component"

import threeDot from "../../assets/svgs/threeDotHorizon.svg"
import nextBtn from "../../assets/svgs/nextBtnsvg.svg"
import previousBtn from "../../assets/svgs/previousBtnSvg.svg"
import AudiNetWorkInter from "./Preset/AudiNetWorkInter"
import AudiNetWorkNative from "./Preset/AudiNetWorkNative"
import AudiNetBanner from "./Preset/AudiNetBanner"
import InstaFeed from "./Preset/InstaFeed"
import InstantArticle from "./Preset/InstantArticle"
import InstagramStory from "./Preset/InstagramStory"
import InstagramStoryFeed from "./Preset/InstagramExploreFeed"
import MessengerStory from "./Preset/MessengerStory"
import MarketPlace from "./Preset/MarketPlace"
import { fbPageList } from "../mocks/mock-data"
import FbImgDisplay18 from "./FbImgDisplay18"

type TypeofAddProps = {
  file: any
  setFile: React.Dispatch<React.SetStateAction<any>>
  selectedType: any
  setSelectedTypes: React.Dispatch<React.SetStateAction<any>>
  imgUplaod: any
}

interface fbListType {
  eid: string
  name: string
  url: string
}

const TypeofAdd: React.FC<TypeofAddProps> = ({ file, setFile, selectedType, setSelectedTypes, imgUplaod }) => {
  const [isChecked, setChecked] = useState("")
  const [chooseType, setChooseType] = useState("")
  const [calltoAction, setCalltoAction] = useState("BOOK_TRAVEL")
  const [fbList, setFbList] = useState<fbListType[]>([])
  const [fbModal, setFbModal] = useState(false)
  const [selectedEid, setSelectedEid] = useState<string | null>(null)

  const today = new Date()

  const history = useHistory()

  const formattedDate = `${today.getFullYear()}${monthNames[today.getMonth()]}${today.getDate().toString().padStart(2, "0")}`
  const formattedFileName = `${formattedDate}-${file[0].name}`
  const [isCreating, setIsCreating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [descriptionUrl, setDescriptionUrl] = useState("")
  const [BrandName, setBrandName] = useState("")
  const [pageName, setPageName] = useState("")
  const [pageUrl, setPageUrl] = useState("")
  const [formError, setFormError] = useState<any>({})
  // const [previewState, setPreviewState] = useState("Desktop News Feed")

  const [adDetails, setAdDetails] = useState({
    name: formattedFileName ?? "",
    title: "",
    description: "",
    url: "",
    read_only: false,
    status: "created",
    multiple_products: 0,
    is_multi_share_optimized: false,
    type: "",
    subtype: "",
    is_dynamic: false,
    do_not_forward: false,
    ad_parameters: [],
    brandName: "",
    assets: [{}]
  })
  const formRef: any = React.useRef(null)

  const { StringType } = Schema.Types

  const validateDescription = StringType().addRule((value, data) => {
    if (!value) return true
    const words = value.split(" ")
    for (let word of words) {
      if (word.length > 14) {
        return false
      }
    }
    return true
  }, "No word should be longer than 14 letters.")

  const modelWeb = Schema.Model({
    name: StringType().isRequired("This field is required."),
    descriptionUrlWeb: StringType().isRequired("This field is required.")
  })

  const modelWebNative = Schema.Model({
    name: StringType().isRequired("This field is required."),
    title: StringType().isRequired("This field is required."),
    description: StringType().isRequired("This field is required."),
    descriptionUrl: StringType().isRequired("This field is required."),
    BrandName: StringType().isRequired("This field is required.")
  })

  const modelFacebook = Schema.Model({
    name: StringType().isRequired("This field is required."),
    title: StringType().isRequired("This field is required."),
    description: StringType().isRequired("This field is required."),
    descriptionUrl: StringType().isRequired("This field is required.")
  })

  const handleChange = (event: string) => {
    setName(event)
  }

  const handleTitleChange = (event: string) => {
    setTitle(event)
  }

  const handleRadioChange = (eid: string, name: string, url: string) => {
    setSelectedEid(eid)
    setPageName(name)
    setPageUrl(url)
  }

  const handleDescriptionChange = (event: string) => {
    setDescription(event)
  }

  const handleBrandNameChange = (event: string) => {
    setBrandName(event)
  }

  const handleCloseList = () => {
    setFbModal(false)
  }

  const handleUrlChange = (event: string) => {
    // if (event.includes('.com') && event.split('.com')[1] !== '') {
    //   return;
    // }

    setDescriptionUrl(event)
  }

  const handleCheckboxChange = (id: string) => {
    setChecked(id)
  }

  const showFbPage = async () => {
    setFbModal(true)
    setLoading(false)
    try {
      const result = await displayFbAd()
      setFbList(result)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSelectChange = (value: string) => {
    setCalltoAction(value)
  }

  const handleCreateAdd = async () => {
    const base64Image = (await convertImageToBase64Ad(file[0])) as string

    // if (!formRef.current.check()) {
    //   Alert.error("form invalid", Constants.Error_Alert_Constant_Time)
    //   return
    // }
    setIsCreating(true)
    let payloads

    if (isChecked === "NativeAds") {
      payloads = {
        name: name,
        title: title,
        description: description,
        url: descriptionUrl,
        read_only: false,
        brandName: BrandName,
        do_not_forward: false,
        ad_parameters: [],
        is_dynamic: false,
        status: "created",
        multiple_products: 0,
        is_multi_share_optimized: false,
        type: "native",
        subtype: "NATIVE_AD_600x600",
        assets: [
          {
            name: "Native ad destination URL",
            type: "text",
            subtype: "url",
            value: descriptionUrl
          },
          {
            name: "Native ad image",
            type: "image",
            subtype: "ad",
            file: base64Image
          },
          {
            name: "Native ad title",
            type: "text",
            subtype: "title",
            value: title
          },
          {
            name: "Native ad description",
            type: "text",
            subtype: "message",
            value: description
          },
          {
            name: "Native ad brand name",
            type: "text",
            subtype: "brand_name",
            value: BrandName
          }
        ]
      }
    } else if (isChecked === "FacebookInstagramImage") {
      payloads = {
        name: name,
        title: title,
        description: description,
        url: descriptionUrl,
        read_only: false,
        brandName: BrandName,
        do_not_forward: false,
        ad_parameters: [],
        is_dynamic: false,
        status: "created",
        multiple_products: 0,
        is_multi_share_optimized: false,
        type: "facebook",
        subtype: "LEADERBOARD",
        assets: [
          {
            name: "Asset url",
            type: "text",
            subtype: "url",
            value: descriptionUrl
          },
          {
            name: "Asset title",
            type: "text",
            subtype: "title",
            value: title
          },
          {
            name: "Asset description",
            type: "text",
            subtype: "message",
            value: description
          },
          {
            name: "Asset action",
            type: "text",
            subtype: "call_to_action",
            value: calltoAction
          },

          {
            name: "Asset image",
            type: "image",
            subtype: "ad",
            file: base64Image
          },

          {
            name: "Facebook asset 2 ad facebook",
            type: "text",
            subtype: "actor_id",
            value: selectedEid
          }
        ]
      }
    } else if (isChecked === "Web") {
      payloads = {
        name: name,
        title: title,
        description: description,
        url: descriptionUrl,
        read_only: false,
        brandName: BrandName,
        do_not_forward: false,
        ad_parameters: [],
        is_dynamic: false,
        status: "created",
        multiple_products: 0,
        is_multi_share_optimized: false,
        type: "web",
        subtype: "LEADERBOARD",
        assets: [
          {
            name: "Static ad destination URL",
            type: "text",
            subtype: "url",
            value: descriptionUrl
          },
          {
            name: "Static ad image",
            type: "image",
            subtype: "ad",
            file: base64Image
          }
        ]
      }
    }

    try {
      const providerId = await useProviderId()
      const result = await createUploadAd(providerId, payloads)
      Alert.success("Assets Created Successfully")
      localStorage.setItem("selectedMenuItem", "ad-library")
      setTimeout(() => {
        window.location.href = "/ads"
      }, 2000)
      history.push("/ads")
    } catch (error) {
      Alert.error("Please fill up the required fields")
    } finally {
      setIsCreating(false)
    }
  }

  const dataCallAction = [
    "None",
    "Apply Now",
    "Book Now",
    "Contact Us",
    "Download",
    "Get Offer",
    "Learn More",
    "Listen Now",
    "Send Message",
    "Shop Now",
    "Sign Up",
    "Watch More",
    "Buy Now"
  ].map((item) => ({
    label: item,
    value: mapLabelToValue(item)
  }))

  function mapLabelToValue(label: string) {
    switch (label) {
      case "None":
        return "NO_BUTTON"
      case "Apply Now":
        return "APPLY_NOW"
      case "Book Now":
        return "BOOK_TRAVEL"

      case "Contact Us":
        return "CONTACT_US"

      case "Download":
        return "DOWNLOAD"

      case "Get Offer":
        return "GET_OFFER"

      case "Learn More":
        return "LEARN_MORE"
      case "Listen Now":
        return "LISTEN_MUSIC"

      case "Send Message":
        return "MESSAGE_PAGE"

      case "Shop Now":
        return "SHOP_NOW"

      case "Sign Up":
        return "SIGN_UP"

      case "Watch More":
        return "WATCH_MORE"

      case "Buy Now":
        return "BUY_NOW"
      default:
        return label
    }
  }

  return (
    <>
      {chooseType !== "upload" && (
        <div className='uploadAdd__modal-typeofAdd-area'>
          <div className='uploadAdd__modal-typeofAdd-area-content'>
            <h4>What type of ad do you want to create?</h4>
            <p>We'll generate ads for each of the channels selected.</p>
          </div>

          <div className='uploadAdd__modal-typeofAdd-area-content-middle'>
            <Grid fluid>
              <Row gutter={26} className='show-grid'>
                {typedOfAdd?.map((item: any) => {
                  return (
                    <Col xs={24} md={24} lg={12}>
                      <div
                        className={selectedType.includes(item?.id) ? "activeTypeofAdd_card" : "typeofAdd_card disableTypeofAdd_card"}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCheckboxChange(item?.id)}
                      >
                        <div className='typeofAdd_card_left'>
                          <img src={item?.icon} alt='web' />
                          <div className='typeofAdd_card_left_content'>
                            <h5>{item?.title}</h5>
                            <p>{item?.content}</p>
                          </div>
                        </div>

                        <div className='typeofAdd_card_right'>
                          <div className='round'>
                            <input type='radio' checked={selectedType.includes(item?.id)} id='radio' />
                            {item?.id === isChecked && <label htmlFor='radio'></label>}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Grid>
            <div className='uploadAdd__modal-typeofAdd-footer'>
              <div className='uploadAdd__modal-typeofAdd-footer-content'>
                <button
                  onClick={() => {
                    setFile({})
                    setSelectedTypes("")
                  }}
                >
                  Back
                </button>
                <button
                  disabled={isChecked === ""}
                  onClick={() => setChooseType("upload")}
                  className={isChecked ? "activeFooterButton" : "nonActiveFooterButton"}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {chooseType === "upload" && (
        <div className='uploadAdd__modal-addDetails'>
          <div className='uploadAdd__modal-typeofAdd-area-content-body'>
            <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Ad Details</h4>
            <Grid fluid>
              <Row gutter={16} className='show-grid'>
                <Col xs={24} md={24} lg={12}>
                  <p className='details__page-title-left'>Ad Details Description</p>
                  {isChecked === "FacebookInstagramImage" && <div></div>}
                  <div className='uploadAdd__modal-left'>
                    {isChecked === "Web" && (
                      <Form
                        model={modelWeb}
                        fluid
                        ref={formRef}
                        onCheck={(formError) => {
                          setFormError(formError)
                        }}
                      >
                        <label className='rs-control-label labelFont'>Name</label>
                        <CustomField
                          name='name'
                          placeholder='Name'
                          accepter={Input}
                          size='lg'
                          value={name}
                          onChange={handleChange}
                          error={formError.name}
                        />

                        <label className='rs-control-label labelFont'>Description url</label>
                        <CustomField
                          name='descriptionUrlWeb'
                          placeholder='https://roboket.com?utm_source=adroll&utm_medium=adrol'
                          accepter={Input}
                          size='lg'
                          value={descriptionUrl}
                          onChange={handleUrlChange}
                          error={formError.descriptionUrl}
                        />
                      </Form>
                    )}

                    {isChecked === "FacebookInstagramImage" && (
                      <Form
                        model={modelFacebook}
                        fluid
                        ref={formRef}
                        onCheck={(formError) => {
                          setFormError(formError)
                        }}
                      >
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Name</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>{name?.length ?? 0}/51</div>
                          <CustomField
                            name='name'
                            placeholder='Name'
                            accepter={Input}
                            size='lg'
                            value={name}
                            onChange={handleChange}
                            error={formError.name}
                            maxLength={51}
                            style={{ paddingRight: "60px" }}
                          />
                        </div>
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Title</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>{title?.length ?? 0}/255</div>

                          <CustomField
                            name='title'
                            placeholder='Title'
                            accepter={Input}
                            size='lg'
                            value={title}
                            onChange={handleTitleChange}
                            error={formError.title}
                            style={{ paddingRight: "95px" }}
                          />
                        </div>
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Description</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>
                            {description?.length ?? 0}/1024
                          </div>
                          <CustomField
                            name='description'
                            placeholder='Description'
                            accepter={Input}
                            componentClass='textarea'
                            size='lg'
                            value={description}
                            onChange={handleDescriptionChange}
                            error={formError.description}
                            style={{ paddingRight: "95px" }}
                            rows={3}
                          />
                        </div>
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Description url</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>
                            {descriptionUrl?.length ?? 0}/947
                          </div>
                          <CustomField
                            name='descriptionUrl'
                            placeholder='https://roboket.com'
                            accepter={Input}
                            size='lg'
                            value={descriptionUrl}
                            onChange={handleUrlChange}
                            error={formError.descriptionUrl}
                            style={{ paddingRight: "95px" }}
                          />
                        </div>
                        <label className='rs-control-label labelFont'>Call to Action</label>
                        <CustomField
                          name='selectedOption'
                          accepter={SelectPicker}
                          data={dataCallAction}
                          size='lg'
                          value={calltoAction}
                          onChange={handleSelectChange}
                          error={formError.selectedOption}
                          searchable={false}
                          cleanable={false}
                        />
                        <label className='rs-control-label labelFont mt-4'>Facebook Identity</label>
                        <br />
                        {pageName.length > 0 ? (
                          <div className='pageDisplayParent' onClick={showFbPage}>
                            <div className='editBtnFb'>Edit</div>

                            <CustomField
                              name='pageDesc'
                              accepter={Input}
                              size='lg'
                              value={pageName + "   " + pageUrl}
                              error={formError.pageDesc}
                              disabled
                            />
                          </div>
                        ) : (
                          <Button color='green' appearance='default' onClick={showFbPage}>
                            Select a page to connect
                          </Button>
                        )}
                      </Form>
                    )}

                    {isChecked === "NativeAds" && (
                      <Form
                        model={modelWebNative}
                        fluid
                        ref={formRef}
                        onCheck={(formError) => {
                          setFormError(formError)
                        }}
                      >
                        <label className='rs-control-label labelFont'>Name</label>
                        <CustomField
                          name='name'
                          placeholder='Name'
                          accepter={Input}
                          size='lg'
                          value={name}
                          onChange={handleChange}
                          error={formError.name}
                        />

                        <label className='rs-control-label labelFont'>Description url</label>
                        <CustomField
                          name='title'
                          placeholder='Title'
                          accepter={Input}
                          size='lg'
                          value={title}
                          onChange={handleTitleChange}
                          error={formError.title}
                        />

                        <label className='rs-control-label labelFont'>Description</label>
                        <CustomField
                          name='description'
                          placeholder='Description'
                          accepter={Input}
                          size='lg'
                          value={description}
                          onChange={handleDescriptionChange}
                          error={formError.description}
                        />

                        <label className='rs-control-label labelFont'>Description url</label>
                        <CustomField
                          name='descriptionUrl'
                          placeholder='https://roboket.com?utm_source=adroll&utm_medium=adrol'
                          accepter={Input}
                          size='lg'
                          value={descriptionUrl}
                          onChange={handleUrlChange}
                          error={formError.descriptionUrl}
                        />

                        <label className='rs-control-label labelFont'>Brand Name</label>
                        <CustomField
                          name='BrandName'
                          placeholder='Brand Name'
                          accepter={Input}
                          size='lg'
                          value={BrandName}
                          onChange={handleBrandNameChange}
                          error={formError.BrandName}
                        />
                      </Form>
                    )}
                  </div>
                </Col>

                <Col xs={24} md={24} lg={12}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p className='details__page-title'>Ad Preview</p>

                    <div onClick={() => setFile({})} className='preview_card_right'>
                      Clear
                    </div>
                  </div>
                  {isChecked === "NativeAds" && (
                    <div className='uploadAdd__modal-right panelForm'>
                      <div className='typeofAdd_card detailsTypeofAdd_card'>
                        <div className='typeofAdd_card_left'>
                          <img src={web} alt='web' />
                          <div className='typeofAdd_card_left_content uploadAdd__modal-right-context'>
                            <p>Connect</p>
                            <h5>Web banner</h5>
                          </div>
                        </div>
                      </div>
                      <div className='uploadAdd__modal-right-midContent'>
                        <img src={URL?.createObjectURL(file[0])} className='nativeAdBanner' alt='file_preview' />
                      </div>
                      {selectedType !== "Web" && (
                        <div className='uploadAdd__modal-right-footerContent'>
                          <div className='typeofAdd_card_left'>
                            <img src={web} alt='web' />
                            <div className='typeofAdd_card_left_content uploadAdd__modal-right-context preview-footer-content'>
                              <h5>{adDetails.title?.length > 0 ? adDetails.title : "Select title"}</h5>
                              <p>Roboket</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {isChecked === "FacebookInstagramImage" && (
                    <FbImgDisplay18
                      file={file}
                      title={title}
                      description={description}
                      descriptionUrl={descriptionUrl}
                      calltoAction={calltoAction}
                    />
                  )}

                  {isChecked === "Web" && (
                    <div className='uploadAdd__modal-right panelForm'>
                      <div className='typeofAdd_card detailsTypeofAdd_card'>
                        <div className='uploadAdd__modal-right-midContent'>
                          <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgBannerWeb' />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Grid>

            <div className='uploadAdd__modal-typeofAdd-footer'>
              <div className='uploadAdd__modal-typeofAdd-footer-content last-page-footer-content'>
                <button
                  onClick={() => {
                    setChooseType("")
                  }}
                >
                  Back
                </button>
                <Button
                  disabled={isChecked === "" || isCreating}
                  onClick={handleCreateAdd}
                  className={isCreating ? "nonActiveFooterButton" : "activeFooterButton"}
                >
                  {isCreating ? "Creating..." : "Create"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal className='__generic_modal' show={fbModal} full onHide={handleCloseList}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Select a Page to Connect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading === true ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <div>
              <p className='title_sub'>Your account is associated with the following page</p>
              {Array.isArray(fbPageList) && fbPageList.length > 0 ? (
                <List hover>
                  {fbPageList.map((fbListIndex) => (
                    <List.Item
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRadioChange(fbListIndex.eid, fbListIndex.name, fbListIndex.url)}
                    >
                      <Row>
                        <Col lg={1}></Col>
                        <Col lg={1}>
                          <input
                            type='radio'
                            name='fbListRadio'
                            checked={selectedEid === fbListIndex.eid}
                            onChange={() => handleRadioChange(fbListIndex.eid, fbListIndex.name, fbListIndex.url)}
                          />
                        </Col>

                        <Col lg={11}>
                          <div>{fbListIndex.name}</div>
                        </Col>
                        <Col lg={11}>
                          <div>
                            {" "}
                            <a href={fbListIndex.url} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>
                              {fbListIndex.url}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <div>
                  <p className='NoItemTxt'>No page found</p>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        {loading === false && (
          <Modal.Footer style={{ padding: "10px" }}>
            <Button onClick={handleCloseList} color='green' appearance='primary'>
              Ok
            </Button>
            <Button onClick={handleCloseList} color='red' appearance='primary'>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

export default TypeofAdd
