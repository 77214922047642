import React, { useState } from "react"

import "./deviceInsights.scss"
import { Button, CheckPicker, Content, DatePicker, Icon, Input, InputGroup, Nav, SelectPicker } from "rsuite"
import dotSvg from "../../../assets/svgs/dot.svg"
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"
import DataTable from "react-data-table-component"
import { useHistory } from "react-router-dom"
import { device, noConnectedApps } from "src/assets"
type Props = {}
const button = {
  borderRadius: "1px",
  margin: "2px"
}
const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      borderRadius: "0px",
      backgroundColor: "white"
    }
  },
  headCells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "12px"
    }
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  }
}

const DeviceInsights = (props: Props) => {
  // const [selectTabs, setSelectedTabs] = useState("news")
  const history = useHistory()
  const handleRowSelected = (row: any) => {
    // history.push(`/ads/update-campaign/?eid=${row.eid}`)
    history.push(`/ads/websites/?eid=${row.eid}`)
  }
  const columnsForAdList = [
    {
      name: "Name",
      sortable: true,
      selector: (row: any) => {
        return <div>{row.name}</div>
      },
      width: "320px"
    },
    {
      name: "Status",
      sortable: true,
      selector: (row: any) => {
        return (
          <div>
            {row.status === "live" ? (
              <>
                <img src={dotSvg} alt='review svg' /> &nbsp;{row.status === "live" ? <>Live</> : <>{row.status}</>}{" "}
              </>
            ) : (
              <>{row.status}</>
            )}
          </div>
        )
      },
      width: "150px"
    },
    {
      name: "Budget",
      sortable: true,
      selector: (row: any) => {
        return (
          <div>
            &nbsp;{"$" + row.budget.goal} {row.budget.type}
          </div>
        )
      },
      width: "150px"
    },
    {
      name: "Created at",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.created_at)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div>&nbsp;{formattedUpdatedDate}</div>
      },
      width: "180px"
    },
    {
      name: "Spend",
      sortable: true,
      selector: (row: any) => {
        return <div>&nbsp;{row.budget.spend === null ? <>$0.00</> : <>${row.budget.spend}</>}</div>
      },
      width: "150px"
    },
    {
      name: "Start date",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.start_date)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div>&nbsp;{formattedUpdatedDate}</div>
      },
      width: "180px"
    },
    {
      name: "End date",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.end_date)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div>&nbsp;{formattedUpdatedDate === "Jan 1, 1970" ? <>-</> : <> {formattedUpdatedDate} </>}</div>
      },
      width: "180px"
    },
    {
      name: "Actions",
      sortable: true,
      selector: (row: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button appearance='ghost' color='green' onClick={() => handleRowSelected(row)}>
              Edit
            </Button>
          </div>
        )
      },
      width: "180px"
    }
  ]
  const dataForAdList = [
    {
      name: "Ad Campaign 1",
      status: "live",
      budget: {
        goal: 1000,
        type: "impressions",
        spend: 500
      },
      created_at: "2023-01-15T10:30:00Z",
      start_date: "2023-01-20T00:00:00Z",
      end_date: "2023-02-15T23:59:59Z"
    },
    {
      name: "Ad Campaign 2",
      status: "paused",
      budget: {
        goal: 500,
        type: "clicks",
        spend: 250
      },
      created_at: "2023-02-01T08:45:00Z",
      start_date: "2023-02-10T00:00:00Z",
      end_date: "2023-03-05T23:59:59Z"
    },
    {
      name: "Ad Campaign 3",
      status: "completed",
      budget: {
        goal: 1500,
        type: "impressions",
        spend: 1200
      },
      created_at: "2023-03-10T15:20:00Z",
      start_date: "2023-03-15T00:00:00Z",
      end_date: "2023-04-10T23:59:59Z"
    }
    // Add more data objects as needed
  ]
  return (
    <>
      <div className='web__connectedAppsPageArea web__deviceInsightsPageArea'>
        {/* <h5>Apps</h5> */}
        <div className='web__connectedApps-area web__device-area'>
          <img src={device} alt='Device apps' />
          <h5>
            No cross-device data available for this advertisable, opt in to get it <span>here</span> If you already have, please allow time
            for data to be collected
          </h5>
          {/* <p>
            <span>Connect your other marketing apps</span> like Mailchimp, Hubspot, and Klaviyo to automatically sync audiences to target in
            AdRoll display campaigns!
          </p> */}
        </div>
      </div>
      {/* ============ */}
      <div className='web__siteAudienceTable'>
        <div className='tableWidth audienceTableWidth'>
          <DataTable columns={columnsForAdList as any} data={dataForAdList} pagination={true} customStyles={customStyles} />
        </div>
      </div>
      {/* ============ */}
    </>
  )
}

export default DeviceInsights

// export default ConnectedAudience
