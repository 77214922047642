const CustomProgressBar = (props: any) => {
  const { bgcolor, completed } = props

  const containerStyles = {
    height: 30,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginRight: 20
  }

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right" as const,
    transition: "width 1s ease-in-out",
    position: "relative" as const
  }

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
    position: "absolute" as const,
    // top: "2px" as const,
    left: "50%" as const
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  )
}

export default CustomProgressBar
