import axios from "src/common/axios-config"

const AdTechCampignDetails = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE
const AdTechDetailsEndPoints = {
  CAMPAIGN_DETAILS: AdTechCampignDetails + "/api/v1/adtech/campaign-details",
  CAMPAIGN_REPORTING: AdTechCampignDetails + "/api/v1/adtech/campaign-adgroups-details",
  ADD_DETAILS: AdTechCampignDetails + "/api/v1/adtech/campaign-ads-details",
  AUDIENCE_DETAILS: AdTechCampignDetails + "/api/v1/adtech/campaign-audience-details"
}
export const getDetailsOfCamapign = async (p_id: string, cam_id: string, fromDate: string, toDate: string, reportType: string) => {
  const data = await axios.get(
    `${AdTechDetailsEndPoints.CAMPAIGN_DETAILS}/${p_id}/${cam_id}?from_date=${fromDate}&to_date=${toDate}&report_type=${reportType}`
  )
  return data
}
export const getCamapignReports = async (p_id: string, cam_id: string, fromDate: string, toDate: string) => {
  const data = await axios.get(`${AdTechDetailsEndPoints.CAMPAIGN_REPORTING}/${p_id}/${cam_id}?from_date=${fromDate}&to_date=${toDate}`)
  return data
}
export const getCamapignAdsReports = async (p_id: string, ads_id: string, from_date: string, to_date: string) => {
  const data = await axios.get(`${AdTechDetailsEndPoints.ADD_DETAILS}/${p_id}/${ads_id}?from_date=${from_date}&to_date=${to_date}`)
  return data
}
export const getCamapignAudienceReports = async (p_id: string, audience_id: string) => {
  const data = await axios.get(`${AdTechDetailsEndPoints.AUDIENCE_DETAILS}/${p_id}/${audience_id}`)
  return data
}
