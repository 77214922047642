import { Alert, Button, Col, Grid, Input, Modal, Panel, Row } from "rsuite"
import copy from "clipboard-copy"
import embedSvg from "../../assets/svgs/embed.svg"
import lightBulb from "../../assets/svgs/lightBulb.svg"
import newTab from "../../assets/svgs/newTab.svg"
import "./style.scss"
const PixelModal = (props: any) => {
  const { openPixelModal, handleClosePixelSetUp, text, isLessThan1199px } = props

  function decodeHtmlEntities(html: string) {
    const txt = document.createElement("textarea")
    txt.innerHTML = html
    return txt.value
  }

  const handleCopyToClipboard = () => {
    const decodedCode = decodeHtmlEntities(text)
    copy(decodedCode)
      .then(() => {
        Alert.info("Text copied to clipboard")
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err)
      })
  }

  return (
    <>
      <Modal
        backdrop={true}
        className='__generic_modal'
        size='lg'
        full={isLessThan1199px ? true : false}
        show={openPixelModal}
        onHide={handleClosePixelSetUp}
      >
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Pixel Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body pixelModalStyle'>
          <div className='titleFlex'>
            <img src={embedSvg} alt='embed svg' />
            <h6>Manual Connection</h6>
          </div>

          <p className='secondTitle'>Connect AdRoll to your website</p>

          <p>Follow these steps to get your site connected:</p>

          <p style={{ paddingTop: "2%", fontSize: "16px", fontWeight: "500" }}>Copy the pixel code below.</p>

          <div className='step-body'>
            <ol>
              <li>
                <div className='titleFlex'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text
                    }}
                    className='inputField'
                  />
                  <Button onClick={handleCopyToClipboard} color="green" appearance="primary">
                    Copy to Clipboard
                  </Button>
                </div>
              </li>

              <li>
                Place the copied code on your website before the end of the <strong> {"</head>"} </strong> tag.
              </li>

              <li>Navigate back to AdRoll and click Verify Connection.</li>

              <li>Once your pixel is placed correctly, you'll receive a confirmation email. This can take up to 24 hours.</li>
            </ol>
          </div>

          <div className='cardBox'>
            <div>
              <img src={lightBulb} alt='light bulb' />
            </div>

            <div>
              <p style={{ fontWeight: "600", fontSize: "18px" }}>Not Sure, how to connect?</p>
              <p className='titleFlex'>
                Read our detailed{" "}
                <a
                  style={{ display: "flex", alignItems: "center" }}
                  target='_blank'
                  href='https://help.adroll.com/hc/en-us/articles/360040116252?_gl=1*qcvnaf*_ga*MTQ0NDg2NzUzNi4xNzAxMTQ5OTQ1*_ga_Z6V9VWD6DL*MTcwNTkwODIzNC4xMjIuMS4xNzA1OTA4MjQ4LjQ2LjAuNDQ5MjYwMjE4'
                >
                  {" "}
                  Pixel Installation Guide <img src={newTab} className='ml-2' alt='new tab icon' />
                </a>{" "}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}></Modal.Footer>
      </Modal>
    </>
  )
}

export default PixelModal
