import { useEffect, useState } from "react"
import { Alert, Button, ButtonToolbar, Content, FlexboxGrid, Header, Input, Modal, Radio, RadioGroup, SelectPicker } from "rsuite"
import channelSvg from "../../assets/svgs/channel.svg"
import settingsIcon from "../../assets/svgs/settingsIcon.svg"
import { useHistory, useLocation } from "react-router-dom"
import chooseScheduleSvg from "../../assets/svgs/chooseSchedule.svg"
import webSvg from "../../assets/svgs/webForCampaign.svg"
import budget from "../../assets/svgs/budgetIcon.svg"
import adgroupSvg from "../../assets/svgs/adGroup.svg"
import LoadingComponent from "src/common/components/loading/Loading"
import BoxPanelUpdated from "src/components/AddRollPanel/BoxPanel/Boxpanel.updated"
import { useProviderId } from "src/common/constants"
import { campaignDataUpdate } from "src/components/mocks/mock-data"
import { campaignUpdate, getCampaign, getSelectedAds, getSelectedSegment } from "./api-updateAdroll"
import { WebAdsType } from "src/common/utils/types"

interface selectedRowsImgType {
  image_url: string
  height: number
  width: number
  adroll_ad_eid: string
}

interface selectedRowsAudType {
  segment_id: string
}

interface SuggestionItem {
  eid: string;
  name: string;
  region?: {
    name: string;
    country: {
      name: string;
    };
  };
  iso2: string;
  iso3: string;
  type: string;
}


const UpdateAdRoll = (props: any) => {
  const { selectedCardGoal } = props
  const [campName, setCampName] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [campChannel, setCampChannel] = useState("")
  const [selectedValue, setSelectedValue] = useState("Impressions")
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
  const [suggestions, setSuggestions] = useState<SuggestionItem[]>([])
  const [inputNumberValue, setInputNumberValue] = useState("")
  const [webAdGroups, setWebAdGroups] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [bidInput, setBidInput] = useState("")
  const [open, setOpen] = useState(false)
  const [inputValueModal, setInputValueModal] = useState()
  const [selectedRowsAud, setSelectedRowsAud] = useState<selectedRowsAudType[]>([])
  const [selectedRowsImg, setSelectedRowsImg] = useState<any[]>([])
  const [metric, setMetric] = useState("")
  const [deviceTarget, setDeviceTarget] = useState({})
  const [deviceTargetChange, setDeviceTargetChange] = useState({})
  const [checkOptions, setCheckOptions] = useState([])
  const [selectedAds, setSelectedAds] = useState([])
  const [campEid, setCampEid] = useState("")
  const [checkCampaignType, setCheckCampaignType] = useState("")
  const [checkCampaignTargetingType, setCheckCampaignTargetingType] = useState("")
  const [draft, setDraft] = useState(false)
  const [bidStrategyValue, setBidStrategyValue] = useState("")
  const [flag, setFlag] = useState(1)
  const [webAds, setWebAds] = useState<WebAdsType[]>([])
  const [suggestionsSelected, setSuggestionsSelected] = useState<SuggestionItem[]>([])

  const handleNumberChange = (event: any) => {
    const newValue = event
    setInputNumberValue(newValue)
  }

  const handleCancel = () => {
    localStorage.setItem("selectedMenuItem", "create-ads")
    window.location.href = "/ads"
  }

  const handleCampStatus = (value: string) => {
    if (value === "Live") {
      setDraft(false)
    } else {
      setDraft(true)
    }
  }

  const history = useHistory()

  const handleSubmit = async () => {
    setLoading(true)

    try {
      let payload

      if (checkCampaignType === "web-retargeting") {
        payload = {
          name: campName,
          budget_balancing_automated: true,
          start_date: selectedStartDate,
          end_date: selectedEndDate,
          is_draft: draft,
          currency: "USD",
          budget: {
            type: "daily",
            goal: inputNumberValue
          },
          campaigns: [
            {
              name: campName,
              service: "web-retargeting",
              adgroups: [
                {
                  name: inputValue,
                  service: "web-retargeting",
                  ads: selectedRowsImg.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.adroll_ad_eid,
                      status: "live"
                    }
                  }),
                  geotargets:
                    suggestionsSelected.length !== 0
                      ? suggestionsSelected.map((suggestion) => ({
                          eid: suggestion,
                          targeted: true
                        }))
                      : [],
                  segments: selectedRowsAud.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.segment_id,
                      targeted: true
                    }
                  }),
                  device_targets: flag === 1 ? deviceTarget : deviceTargetChange
                }
              ],
              kpi: {
                metric: bidStrategyValue,
                goal: bidInput
              }
            }
          ]
        }
      } else if (checkCampaignTargetingType === "lookalike" && checkCampaignType === "web-prospecting") {
        payload = {
          name: campName,
          budget_balancing_automated: true,
          is_draft: draft,
          currency: "USD",
          budget: {
            type: "daily",
            goal: inputNumberValue
          },
          campaigns: [
            {
              name: campName,
              service: "web-prospecting",
              key: "2",
              adgroups: [
                {
                  name: inputValue,
                  service: "web-prospecting",
                  campaign_eid: "2",
                  ads: selectedRowsImg.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.adroll_ad_eid,
                      status: "live"
                    }
                  }),
                  flights: [],
                  geotargets:
                    suggestions.length !== 0
                      ? [
                          {
                            eid: suggestions[0]?.eid,
                            targeted: true
                          }
                        ]
                      : [],
                  auto_audience: true,
                  auto_geo_targets: suggestions.length !== 0 ? false : true,
                  device_targets: flag === 1 ? deviceTarget : deviceTargetChange
                }
              ],
              targeting_type: checkCampaignTargetingType
            }
          ]
        }
      } else if (checkCampaignTargetingType === "contextual" && checkCampaignType === "web-prospecting") {
        payload = {
          name: campName,
          budget_balancing_automated: true,
          is_draft: draft,
          currency: "USD",
          budget: {
            type: "daily",
            goal: inputNumberValue
          },
          campaigns: [
            {
              name: campName,
              service: "web-prospecting",
              key: "2",
              adgroups: [
                {
                  name: inputValue,
                  service: "web-prospecting",
                  campaign_eid: "2",
                  ads: selectedRowsImg.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.adroll_ad_eid,
                      status: "live"
                    }
                  }),
                  flights: [],
                  geotargets:
                    suggestions.length !== 0
                      ? [
                          {
                            eid: suggestions[0]?.eid,
                            targeted: true
                          }
                        ]
                      : [],
                  auto_audience: true,
                  contextual_targets: [
                    {
                      name: "attributes",
                      source: "clouseau_ct_nxroll_tier2",
                      options: webAdGroups.map((webAdGroupsIndex: any) => {
                        return {
                          name: webAdGroupsIndex,
                          target: true
                        }
                      })
                    }
                  ],
                  auto_geo_targets: suggestions.length !== 0 ? false : true,
                  device_targets: flag === 1 ? deviceTarget : deviceTargetChange
                }
              ],
              targeting_type: checkCampaignTargetingType
            }
          ]
        }
      }

      const providerId = await useProviderId()

      const campSubmit = await campaignUpdate(providerId, campEid, payload)

      if (campSubmit.status === 201 || campSubmit.status === 200) {
        Alert.success("Campaign create successfully")
        localStorage.setItem("selectedMenuItem", "campaign-list")
        history.push("/ads")
        localStorage.setItem("selectedMenuItem", "campaign-list")
      } else {
        Alert.error("error")
      }
    } catch (error: any) {
      Alert.error(error.response.data.detail)
    } finally {
      setLoading(false)
    }
  }

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  const getCampaignInfo = async () => {
    const providerId = await useProviderId()
    setLoading(true)
    try {
      const result = await getCampaign(providerId, queryParameters.get("eid"))

      if (result?.data?.data.campaigns[0]?.targeting_type === "contextual") {
        const webAdGroupList = result?.data?.data.campaigns[0]?.adgroups[0].contextual_targets[0]?.options
        const mappedNames = webAdGroupList?.map((webAdGroupListIndex: any) => webAdGroupListIndex.name)

        setWebAdGroups(mappedNames)
      }

      const dateObject = result?.data?.data.start_date ? new Date(result?.data?.data.start_date) : null
      const endDateObject = result?.data?.data.end_date ? new Date(result?.data?.data.end_date) : null
      setCampName(result?.data?.data.name)
      setCampChannel(result?.data?.data.campaigns[0]?.service)
      setSelectedStartDate(dateObject)
      setSelectedEndDate(endDateObject)
      setInputNumberValue(result?.data?.data.budget.goal)
      setInputValue(result?.data?.data.campaigns[0]?.adgroups[0].name)
      setBidInput(result?.data?.data.campaigns[0]?.kpi.goal)
      setMetric(result?.data?.data.campaigns[0]?.kpi.metric)
      setDeviceTarget(result?.data?.data.campaigns[0]?.adgroups[0]?.device_targets)
      setSelectedAds(result?.data?.data.campaigns[0]?.adgroups[0]?.ads)
      setCampEid(result?.data?.data.eid)
      setCheckCampaignType(result?.data?.data.campaigns[0]?.adgroups[0].service)
      setCheckCampaignTargetingType(result?.data?.data.campaigns[0]?.targeting_type)

      if (checkCampaignType === "web-retargeting") {
        const segmentListDataGetEid = result?.data?.data.campaigns[0]?.adgroups[0]?.segments?.map((segment: any) => segment.eid)

        let segmentList = []

        for (const segmentId of segmentListDataGetEid) {
          const segmentListItem = await getSelectedSegment(providerId, segmentId)
          segmentList.push(segmentListItem.data)
          setSelectedRowsAud(segmentList)
        }
      }

      const adListDataGetEid = result?.data?.data.campaigns[0]?.adgroups[0]?.ads?.map((ad: any) => ad.eid)

      let adList = []

      for (const adId of adListDataGetEid) {
        const adListItem = await getSelectedAds(providerId, adId)

        adList.push(adListItem.data)
        setSelectedRowsImg(adList)
      }
    } catch (error) {
      Alert.info("No Ads selected in this campaign")
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const dataForContextualTargets = [
    "Arts & Entertainment",
    "Automotive",
    "Business & Industry",
    "Careers",
    "Dating",
    "Education",
    "Family & Parenting",
    "Fashion & Beauty",
    "Finance",
    "Food & Drink",
    "Health & Fitness",
    "Home & Garden"
  ].map((item) => ({
    label: item,
    value: item
  }))

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const handleModalInputChange = (event: any) => {
    const newValue = event
    setCampName(newValue)
  }

  function mapLabelToValue(label: string) {
    switch (label) {
      case "Re-targeting":
        return "retargeting"
      case "Lookalike Campaign":
        return "lookalike"
      case "Contextual Campaign":
        return "contextual"
      default:
        return label
    }
  }

  const cardGoaldata = ["Re-targeting", "Lookalike Campaign", "Contextual Campaign"].map((item) => ({
    label: item,
    value: mapLabelToValue(item)
  }))

  const handleBidInput = (event: any) => {
    const newValue = event
    setBidInput(newValue)
  }

  const handleNameChange = (event: any) => {
    const newValue = event
    setInputValue(newValue)
  }

  const handleRowSelectedAud = (state: any) => {
    setSelectedRowsAud(state.selectedRows)
  }

  const handleSelectChange = (value: any) => {
    setSelectedValue(value)
  }

  const handleSelectedRowsChange = (selectedRows: any) => {
    setSelectedRowsImg(selectedRows)
  }

  const handleContextualTargetsChange = (selectedValues: any[]) => {
    setWebAdGroups(selectedValues)
  }

  useEffect(() => {
    getCampaignInfo()
  }, [])

  useEffect(() => {
    if (metric === "CPM") {
      setSelectedValue("Impressions")
    } else if (metric === "CPA") {
      setSelectedValue("Conversion")
    } else if (metric === "CPC") {
      setSelectedValue("Clicks")
    }
  }, [metric])

  useEffect(() => {
    if (selectedValue === "Impressions") {
      setBidStrategyValue("CPM")
    } else if (selectedValue === "Conversion") {
      setBidStrategyValue("CPA")
    } else if (selectedValue === "Clicks") {
      setBidStrategyValue("CPC")
    }
  }, [selectedValue])

  useEffect(() => {
    if (deviceTarget && flag == 2) {
      const device_target = {
        targets_desktop: Array.isArray(deviceTarget) && deviceTarget.includes("Desktop"),
        targets_mobile_web: Array.isArray(deviceTarget) && deviceTarget.includes("Mobile Web"),
        targets_mobile_app: Array.isArray(deviceTarget) && deviceTarget.includes("Mobile App")
      }
      setDeviceTargetChange(device_target)
    }
  }, [deviceTarget])

  return (
    <>
      <Content className='main_content'>
        {loading === true ? (
          <>
            <LoadingComponent />
          </>
        ) : (
          <>
            <Header>
              <div className='cardTitle'>
                <div>
                  <div className='titleEdit'>
                    <p className='titleCampHeader'>{campName}</p>

                    <div onClick={handleOpen} className='monty-proxy-edit-name'>
                      Edit Name
                    </div>
                  </div>
                </div>
              </div>
            </Header>

            <div className='__dashboard_container'>
              <div className='addRoll__container-area'>
                <Content className='addRoll__container-area-content'>
                  <div className='show-grid'>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          boxSubTitle={"Web"}
                          selectedCardChanel={"Web"}
                          webFlag={"webFlag"}
                          handleOpen={handleOpen}
                          imgS={channelSvg}
                          title={"Campaign Settings"}
                          boxBody={"ChannelsBody"}
                          boxtitle={"Channels"}
                          handleModalInputChange={handleModalInputChange}
                          buttonToolbar={
                            <div className='settingsBox'>
                              <img src={settingsIcon} alt='s' style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                            </div>
                          }
                          selectedCardGoal={selectedCardGoal}
                          setInputValueModal={setInputValueModal}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid'>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={chooseScheduleSvg}
                          boxSubTitle={"Starts immediately upon approval, no end date"}
                          boxtitle={"Choose Schedule"}
                          boxBody={"ScheduleBody"}
                          setSelectedStartDate={setSelectedStartDate}
                          selectedStartDate={selectedStartDate}
                          setSelectedEndDate={setSelectedEndDate}
                          selectedEndDate={selectedEndDate}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid' style={{ paddingTop: "2%" }}>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={webSvg}
                          boxSubTitle={"Optimized for conversions, automatic CPA"}
                          title={"Web Channel"}
                          boxtitle={"Choose Web Settings"}
                          boxBody={"webSettingsBody"}
                          handleBidInput={handleBidInput}
                          selectedValue={selectedValue}
                          bidInput={bidInput}
                          handleSelectChange={handleSelectChange}
                          checkCampaignTargetingType={checkCampaignTargetingType}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid'>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={adgroupSvg}
                          boxSubTitle={"Ad group settings, audience and ads"}
                          boxtitle={"Configure AdGroup"}
                          boxBody={"ConfigureBody"}
                          handleNameChange={handleNameChange}
                          inputValue={inputValue}
                          handleSelectedRowsChange={handleSelectedRowsChange}
                          selectedRowsImg={selectedRowsImg}
                          handleRowSelectedAud={handleRowSelectedAud}
                          setSuggestions={setSuggestions}
                          suggestions={suggestions}
                          selectedCardGoal={selectedCardGoal}
                          dataForContextualTargets={dataForContextualTargets}
                          loading={loading}
                          setDeviceTarget={setDeviceTarget}
                          setLoading={setLoading}
                          deviceTarget={deviceTarget}
                          selectedRowsAud={selectedRowsAud}
                          checkCampaignTargetingType={checkCampaignTargetingType}
                          handleContextualTargetsChange={handleContextualTargetsChange}
                          webAdGroups={webAdGroups}
                          setFlag={setFlag}
                          checkCampaignType={checkCampaignType}
                          webAds={webAds}
                          setWebAds={setWebAds}
                          setSuggestionsSelected={setSuggestionsSelected}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>

                  <div className='show-grid' style={{ paddingTop: "2%" }}>
                    <FlexboxGrid justify='center'>
                      <FlexboxGrid.Item colspan={20}>
                        <BoxPanelUpdated
                          imgS={budget}
                          boxSubTitle={"Smart Budget optimization across channels or separate budgets"}
                          boxtitle={"Choose Budget"}
                          title={"Campaign Budget"}
                          boxBody={"ChooseBudgetBody"}
                          handleNumberChange={handleNumberChange}
                          inputNumberValue={inputNumberValue}
                        />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>
                </Content>
              </div>
            </div>

            <div className='finishBtnTop'>
              <ButtonToolbar>
                <Button appearance='default' className='cancelBtn' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button className='finishBtn' disabled={loading} onClick={handleSubmit}>
                  {loading ? "Updating..." : "Update"}
                </Button>
              </ButtonToolbar>
            </div>
          </>
        )}
      </Content>

      <Modal className='__generic_modalManageCamp' show={open} onHide={handleClose}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Update Manage Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body_camp' style={{ padding: "0px 20px" }}>
          <div>
            <p className='titleCamp'>Campaign Name</p>
            <Input value={campName} onChange={handleModalInputChange} />
            <br />
            <p className='titleCamp'>Campaign Type</p>
            <SelectPicker size='md' appearance='default' defaultValue={selectedCardGoal} disabled cleanable={false} data={cardGoaldata} />
            <div style={{ marginTop: "4%", display: "flex", alignItems: "center" }}>
              <p className='titleCamp'>Campaign Status : </p>

              <RadioGroup name='radioList' inline defaultValue={draft === false ? "Live" : "Draft"} onChange={handleCampStatus}>
                <Radio value='Live'>
                  <p style={{ fontWeight: "500" }}>Live</p>
                </Radio>
                <Radio value='Draft'>
                  <p style={{ fontWeight: "500" }}>Draft</p>
                </Radio>
              </RadioGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "10px" }}>
          <Button onClick={handleClose} style={{ backgroundColor: "#0fae8c", color: "white", padding: "8px 28px" }}>
            Ok
          </Button>
          <Button onClick={handleClose} color='red' appearance='primary'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateAdRoll
