import axiosAdRoll from "src/common/axios-adroll-config"
import axios from "src/common/axios-config"
import { useProviderId } from "src/common/constants"

const AdTechBaseUrl = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE
const WrapperUrl = process.env.REACT_APP_ADTECH_URL_DEV_IMAGE

const AdTechEndPoints = {
  CREATE_ADS: AdTechBaseUrl + "/api/v1/adtech/ads/",
  POST_IMAGE: WrapperUrl + "/api/v1/adtech/resize-image"
}

export const createNativeAd = async (formData: any) => {
  const providerId = await useProviderId()
  const data = await axios.post(AdTechEndPoints.CREATE_ADS + providerId, formData)
  return data
}

export const postImage = async (payload: any) => {
  const data = await axios.post(AdTechEndPoints.POST_IMAGE, payload)
  return data
}
