// // import Roboket_Logo from './Roboket_Logo.svg';
// import group from './group.png';
// import note_icon from './note_icon_ellipse.svg';
// import comment_icon from './comment_icon.svg';
// import email_icon from './email_icon.svg';
// import active_icon from './active_icon.svg';
// import call_icon from './call_icon_ellipse.svg';
// import avatar from './avatar.png';
// import more_filter from './more_filter.svg';
// import red_dot from './red_dot.svg';
// import yellow_dot from './yellow_dot.svg';
// import down_arrow from './down_arrow.svg';
// import right_icon from './right_icon.svg';
// import cross_icon from './cross_icon.svg';
// import eight_dot from './eight_dot.svg';
// // svgs dir
// import arrow from './svgs/arrow.svg';
// import close_gray from './svgs/close_gray.svg';
// import cross_circle from './svgs/cross_circle.svg';
// import compare_mirror from './svgs/compare_mirror.svg';
// import dropdown_white from './svgs/dropdown_white.svg';
// import dropdown_with_bg from './svgs/dropdown_with_bg.svg';
// import edit_gray from './svgs/edit_gray.svg';
// import edit_circle from './svgs/edit_circle.svg';
// import eight_dot_off_white from './svgs/eight_dot_off_white.svg';
// import eight_dot_white from './svgs/eight_dot_white.svg';
// import forward_icon from './svgs/forward.svg';
// import info_cyan from "./svgs/info_cyan.svg"
// import plus_circle from './svgs/plus_circle.svg';
// import statictics from './svgs/statictics.svg';
import cloud from "./svgs/cloud.svg"
import display from "./svgs/display.svg"
import fbImage from "./svgs/fb_image.svg"
import fbVideo from "./svgs/fb_video.svg"
import nativeAdd from "./svgs/native_add.svg"
import pinterastImg from "./svgs/pinterast_image.svg"
import pinterastVideo from "./svgs/pinterast_video.svg"
import titokVideo from "./svgs/tiktok_video.svg"
import webVideoAnother from "./svgs/web_video-another.svg"
import webVideo from "./svgs/web_video.svg"
import webIcon from "./svgs/web.svg"
import webNative from "./svgs/web_native.svg"
import pinterastIcon from "./svgs/pinterast.svg"
import pinterastFacebookIcon from "./svgs/pinterestFacebook.svg"
import connectedApps from "./svgs/apps.svg"
import device_svg from "./svgs/device.svg"
import informationIcon from "./svgs/informationIcon.svg"

// export const logoIcon = Roboket_Logo;
// export const groupIcon = group;
// export const note = note_icon;
// export const commentIcon = comment_icon;
// export const emailIcon = email_icon;
// export const activeIcon = active_icon;
// export const callIcon = call_icon;
// export const noteIcon = note_icon;
// export const avatarIcon = avatar;
// export const moreFilter = more_filter;
// export const redDotIcon = red_dot;
// export const yellowDotIcon = yellow_dot;
// export const downArrowIcon = down_arrow;
// export const rightIcon = right_icon;
// export const crossIcon = cross_icon;
// export const eightDotIcon = eight_dot;
// // svgs dir
// export const arrowIcon = arrow;
// export const closeGray = close_gray;
// export const crossCircle = cross_circle;
// export const compareMirror = compare_mirror;
// export const dropdownWhite = dropdown_white;
// export const dropdownWithBg = dropdown_with_bg;
// export const editGray = edit_gray;
// export const editCircle = edit_circle;
// export const eightDotOffWhite = eight_dot_off_white;
// export const eightDotWhite = eight_dot_white;
// export const forward = forward_icon;
// export const infoCyan = info_cyan
// export const plusCircle = plus_circle;
// export const staticticsIcon = statictics;
export const cloudIcon = cloud
export const displayIcon = display
export const fbImageIcon = fbImage
export const fbVideoIcon = fbVideo
export const nativeAddIcon = nativeAdd
export const pinterastImageIcon = pinterastImg
export const pinterastVideoIcon = pinterastVideo
export const tiktokVideoIcon = titokVideo
export const webVideoAnotherIcon = webVideoAnother
export const webVideoIcon = webVideo

export const web = webIcon
export const web_native = webNative
export const pinterast = pinterastIcon
export const pinterastFacebook = pinterastFacebookIcon
export const noConnectedApps = connectedApps
export const device = device_svg
export const information = informationIcon
