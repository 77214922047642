import flagIcon from "../../../assets/svgs/flagIcon.svg"
import circleIcon from "../../../assets/svgs/cricleBackground.svg"
import threeDot from "../../../assets/svgs/threeDotHorizon.svg"
import heartIconInstagram from "../../../assets/svgs/haertInsta.svg"
import commentIconInstagram from "../../../assets/svgs/commentInsta.svg"
import shareIconInstagram from "../../../assets/svgs/shareInsta.svg"
import shareIcon from "../../../assets/svgs/share.svg"
import leftArrow from "../../../assets/svgs/leftArrow.svg"
import { Panel } from "rsuite"

type InstagramStoryFeedType = {
  file: any
  description: string
  calltoAction: string
  flag?: string
}

const InstagramStoryFeed = (props: InstagramStoryFeedType) => {
  const { file, description, calltoAction, flag } = props
  
  return (
    <>
      <Panel shaded bordered bodyFill className='panelFormInstagram'>
        <div className='ar-ad-manager-instagram-header'>
          <div className='exploreFeed'>
            {" "}
            <div style={{ flex: "0.4" }}>
              <img src={leftArrow} alt='left arrow' />
            </div>{" "}
            <div style={{ flex: "0.5" }}>Explore</div>{" "}
          </div>
        </div>
        <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
          <div className='ar-ad-manager-fb-preview-placeholder'>
            <img
              src={flagIcon}
              alt='flagIcon'
              style={{
                alignItems: "center"
              }}
            />
          </div>

          <div style={{ flex: "0.9" }}>
            <p className='fbTitleInstagram'>Roboket</p>
            <div className='fbSponsor'>
              {" "}
              <div>
                {" "}
                <p className='sponsorTextInstagram'>Sponsored</p>
              </div>{" "}
            </div>
          </div>

          <div className='sponsor' style={{ flex: "0.1" }}>
            <img src={threeDot} alt='three dot' />{" "}
          </div>
        </div>

        <img src={flag === "flag" ? file : URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />

        <div style={{ padding: "8px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ color: "#4267b2", fontWeight: "700" }}>
              {calltoAction === "NO_BUTTON" && <></>}
              {calltoAction === "APPLY_NOW" && <span>Apply Now</span>}
              {calltoAction === "BOOK_TRAVEL" && <span>Book Now</span>}
              {calltoAction === "DOWNLOAD" && <span>Download</span>}
              {calltoAction === "CONTACT_US" && <span>Contact Us</span>}
              {calltoAction === "GET_OFFER" && <span>Get Offer</span>}
              {calltoAction === "LEARN_MORE" && <span>Learn More</span>}
              {calltoAction === "LISTEN_MUSIC" && <span>Listen Now</span>}
              {calltoAction === "MESSAGE_PAGE" && <span>Send Message</span>}
              {calltoAction === "SHOP_NOW" && <span>Shop Now</span>}
              {calltoAction === "SIGN_UP" && <span>Sign Up</span>}
              {calltoAction === "WATCH_MORE" && <span>Watch More</span>}
              {calltoAction === "BUY_NOW" && <span>Buy Now</span>} &nbsp;
              {">"}{" "}
            </div>
          </div>
        </div>

        <hr />

        <div style={{ display: "flex", justifyContent: "space-between", padding: "8px" }}>
          <div className='iconBunch'>
            <img src={heartIconInstagram} alt='heart icon' />
            <img src={commentIconInstagram} alt='comment icon' />
            <img src={shareIconInstagram} alt='share instagram icon' />
          </div>
          <img src={shareIcon} alt='share icon' />
        </div>

        <p style={{ paddingLeft: "3%", paddingBottom: "6%", overflow: "hidden" }}>
          {description.length === 0 ? (
            <>This is your Description</>
          ) : (
            <>
              {description.split("\n").map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </>
          )}
        </p>
      </Panel>
    </>
  )
}

export default InstagramStoryFeed
