import flagIcon from "../../../assets/svgs/flagIcon.svg"
import circleIcon from "../../../assets/svgs/cricleBackground.svg"
import threeDot from "../../../assets/svgs/threeDotHorizon.svg"
import crossIcon from "../../../assets/svgs/crossIcon.svg"
import upIcon from "../../../assets/svgs/upwhite.svg"

type MessengerStoryType = {
  file: any
  description: string
  calltoAction: string
  flag?: string
  widthI?: string
}

const MessengerStory = (props: MessengerStoryType) => {
  const { file, description, calltoAction,flag,widthI } = props
  return (
    <>
      <div className={widthI==='width'?'panelFormInstagramStoryWidthI': 'panelFormInstagramStory'}>
        <div className='ar-ad-manager-facebook-ad-preview'>
          <div className='fbSponsor'>
            <img className='circleAshColorInStory' src={circleIcon} alt='circleAshColor' />
            <img className='flagIconStyleStory' src={flagIcon} alt='flagIcon' />

            <div style={{ flex: "0.9", color: "white", marginLeft: "6px" }}>
              <p>Roboket</p>
            </div>

            <div className='sponsor' style={{ flex: "0.1" }}>
              <img src={threeDot} alt='three dot' />{" "}
            </div>
          </div>
        </div>

        <div>
        <img src={flag === "flag" ? file : URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
        </div>
        <div className='ar-ad-manager-facebook-ad-preview'>
          <div className='bottomDescStory'>
            <div className='descStory' style={{ overflow: "hidden" }}>
              {description.length === 0 ? (
                <>This is your Description</>
              ) : (
                <>
                  {description.split("\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </>
              )}
            </div>

            <div className='ar-ad-manager-instagram-preview-cta'>
              <div>
                <img src={upIcon} alt='up icon' />
              </div>
              <span className='ar-ad-manager-instagram-preview-cta-messenger-text'>
                {calltoAction === "NO_BUTTON" && <></>}
                {calltoAction === "APPLY_NOW" && <span>Apply Now</span>}
                {calltoAction === "BOOK_TRAVEL" && <span>Book Now</span>}
                {calltoAction === "DOWNLOAD" && <span>Download</span>}
                {calltoAction === "CONTACT_US" && <span>Contact Us</span>}
                {calltoAction === "GET_OFFER" && <span>Get Offer</span>}
                {calltoAction === "LEARN_MORE" && <span>Learn More</span>}
                {calltoAction === "LISTEN_MUSIC" && <span>Listen Now</span>}
                {calltoAction === "MESSAGE_PAGE" && <span>Send Message</span>}
                {calltoAction === "SHOP_NOW" && <span>Shop Now</span>}
                {calltoAction === "SIGN_UP" && <span>Sign Up</span>}
                {calltoAction === "WATCH_MORE" && <span>Watch More</span>}
                {calltoAction === "BUY_NOW" && <span>Buy Now</span>} &nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
      <p style={{ fontSize: "12px", padding: "12px" }}>*Font color and background color may vary on Facebook and Instagram.</p>
    </>
  )
}

export default MessengerStory
