import React from "react"
import { Button, FlexboxGrid } from "rsuite"
import DashboardIcon from "@rsuite/icons/Dashboard"
import channel from "../../../assets/svgs/channel.svg"
import "./styles/styles.scss"
type Props = {
  title: string
  boxSubTitle: string
  isCollapsed: boolean
  selectedTitle: string
  handleCustomCollapse: any
  imgage: any
}
const styleCenter = {
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "24px",
  color: "#4c596b"
}

export default function BoxPanelHeader({ title, boxSubTitle, isCollapsed, handleCustomCollapse, imgage }: Props) {
  return (
    <div>
      <div className='addRoll__boxpanelHeaderArea'>
        <div className='collpasible_area'>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={1} style={styleCenter}>
              {/* <DashboardIcon style={{ fontSize: "2.5em" }} /> */}
              {/* <img src={channel} alt="channel svg"/> */}

              <img className='iconSet' src={imgage} alt='icon' />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              colspan={16}
              style={{
                ...styleCenter,
                flexDirection: "column",
                alignItems: "flex-start",
                overflow: "hidden",
                marginLeft: "10px"
              }}
            >
              <div className='titleStyle'> {title} </div>
              <div className='slimText'>
                <div>{boxSubTitle}</div>
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
        <div style={{ marginTop: "33px" }}>
          {isCollapsed !== true && (
            <div className='collapseButton'>
              <Button appearance='default' className='editBtn' onClick={handleCustomCollapse}>
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
