import { Alert, Button, Drawer, Dropdown, Form, Icon, IconButton, Input, InputGroup, Modal } from "rsuite"
import "./channel.scss"
import { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { disconnectProviderAd, postProviderAd } from "./api-channel"
import CustomField from "src/components/custom-field/custom-field.component"
import { Constants } from "src/common/constants"
import DrawerComponent from "./DrawerComponent"
import checkMark from "../../assets/svgs/checkMark.svg"
import disconnectSvg from "../../assets/svgs/disconnectSvg.svg"
import threeDotSvg from "../../assets/svgs/threeDots.svg"

interface IChannelItem {
  userProviderAssociation: any
  id: number
  name: string
  imgSrc: string
  description: string
  classes?: string[]
  flag: any
  setFlag: any
}

function Channel(item: IChannelItem) {
  const formRef: any = useRef(null)
  const [openAdRoll, setOpenAdRoll] = useState(false)
  const [formError, setFormError] = useState<any>({})
  const [token, setToken] = useState("")
  const [apiKey, setApiKey] = useState("")
  const [advertisableId, setAdvertisableId] = useState("")
  const [drawerOpen, setDrwaerOpen] = useState(false)

  const history = useHistory()
  

  const handleClick = () => {
    if (item?.userProviderAssociation != null) {
      if (item?.userProviderAssociation.is_active === false) {
        setDrwaerOpen(true)
      } else {
        history.push("/ads")
        localStorage.setItem("selectedMenuItem", "dashboard")
      }
    } else {
      setDrwaerOpen(true)
    }
  }

  const handleDrawerOpen = () => {
    setDrwaerOpen(true)
  }

  const handleClose = () => {
    setOpenAdRoll(false)
  }

  const handleTokenChange = (value: string) => {
    setToken(value)
  }

  const handleAdvertisableIdChange = (value: string) => {
    setAdvertisableId(value)
  }

  const handleApiKeyChange = (value: string) => {
    setApiKey(value)
  }
  const drawerDisconnectFunc = async () => {
    const payload = {
      is_active: false
    }
    try {
      const result = await disconnectProviderAd(item.id, payload)
      item.setFlag(result.data.status)
    } catch (error) {
      console.error(error)
    } finally {
      setDrwaerOpen(false)
      Alert.info("Provider Disconnected")
    }
  }

  const drawerActiveFunc = async () => {
    const payload = {
      is_active: true
    }
    try {
      const result = await disconnectProviderAd(item.id, payload)
      item.setFlag(result.data.status)
    } catch (error) {
      console.error(error)
    } finally {
      setDrwaerOpen(false)
      Alert.success("Provider Activated Successfully")
    }
  }

  const handleOk = async () => {
    if (!formRef.current.check()) {
      Alert.error("form invalid", Constants.Error_Alert_Constant_Time)
      return
    }

    const payload = {
      provider_id: item.id,
      credential: {
        apikey: apiKey,
        personal_access_token: "Token" + " " + token,
        advertisable: advertisableId
      }
    }
    try {
      const formSubmit = await postProviderAd(payload)
      if (formSubmit.status === 200) {
        Alert.success("Success")
        history.push("/ads")
        return
      }
    } catch (error) {
      Alert.error("Error")
    }
  }

  return (
    <>
      <div className={`${item.classes ? [...item.classes].join(" ") : ""}`}>
        <div className='item'>
          {item.flag === undefined ? (
            <></>
          ) : (
            <>
              {item.name === "AdRoll" &&
                (item.flag === true ? (
                  <>
                    <img className='checkImg' src={checkMark} alt='checkmark' />
                    <div style={{ float: "right" }}>
                      <Dropdown
                        renderTitle={() => {
                          return <img src={threeDotSvg} alt='three dots' />
                        }}
                      >
                        <Dropdown.Item onSelect={handleDrawerOpen}>Details</Dropdown.Item>
                        <Dropdown.Item>
                          {item.flag === false ? (
                            <div onClick={drawerActiveFunc}>Connect</div>
                          ) : (
                            <div onClick={drawerDisconnectFunc}>Disconnect</div>
                          )}
                        </Dropdown.Item>
                      </Dropdown>
                    </div>
                  </>
                ) : (
                  <>
                    <img className='checkImg' src={disconnectSvg} alt='disconnectSvg' />
                    <div style={{ float: "right", padding: "8px !important" }}>
                      <Dropdown
                        renderTitle={() => {
                          return <img src={threeDotSvg} alt='three dots' />
                        }}
                      >
                        <Dropdown.Item onSelect={handleDrawerOpen}>Details</Dropdown.Item>
                        <Dropdown.Item>
                          {item.flag === false ? (
                            <div onClick={drawerActiveFunc}>Connect</div>
                          ) : (
                            <div onClick={drawerDisconnectFunc}>Disconnect</div>
                          )}
                        </Dropdown.Item>
                      </Dropdown>
                    </div>
                  </>
                ))}
            </>
          )}

          <div
            className={
              item?.userProviderAssociation === null && item.name != "AdRoll" ? "item-logo-containerBorderless" : "item-logo-container"
            }
            onClick={handleClick}
          >
            <div className='item-logo-inner'>
              <img width='80' height='80' alt='' src={item.imgSrc} />
              <span className='logo-ico-info'>{item.name}</span>
            </div>
          </div>

          {/* <div className='item-desc'>
            <small>{item.description}</small>
          </div> */}
        </div>
      </div>

      <Modal className='__generic_modal' onHide={handleClose} show={openAdRoll}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>AdRoll</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body_camp'>
          <div style={{ padding: " 0px 30px" }}>
            <Form
              className='form-c'
              fluid
              ref={formRef}
              onCheck={(formError) => {
                setFormError(formError)
              }}
              // onChange={(formValue) => {
              //   setFormValue({
              //     ...formValue
              //   })
              // }}
            >
              <label>Token</label>

              <CustomField name='token' accepter={Input} size='lg' value={token} error={formError.token} onChange={handleTokenChange} />

              <label>Api key</label>
              <CustomField name='apiKey' accepter={Input} size='lg' value={apiKey} error={formError.apiKey} onChange={handleApiKeyChange} />

              <label>Advertisable Id</label>

              <CustomField
                name='advertisableId'
                accepter={Input}
                size='lg'
                value={advertisableId}
                error={formError.advertisableId}
                onChange={handleAdvertisableIdChange}
              />
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "10px" }}>
          <Button style={{ padding: "8px 20px" }} onClick={handleOk} color='green' appearance='primary'>
            Ok
          </Button>
          <Button onClick={handleClose} color='red' appearance='primary'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <DrawerComponent
        drawerOpen={drawerOpen}
        setDrwaerOpen={setDrwaerOpen}
        handleApiKeyChange={handleApiKeyChange}
        handleAdvertisableIdChange={handleAdvertisableIdChange}
        handleTokenChange={handleTokenChange}
        name={item.name}
        description={item.description}
        image={item.imgSrc}
        id={item.id}
        apiKey={apiKey}
        advertisableId={advertisableId}
        token={token}
        flag={item.flag}
        setFlag={item.setFlag}
        drawerActiveFunc={drawerActiveFunc}
        drawerDisconnectFunc={drawerDisconnectFunc}
      />
    </>
  )
}

export default Channel
