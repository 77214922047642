import React, { useEffect, useState } from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js"
import { Line } from "react-chartjs-2"
import { DateRangePicker, Loader } from "rsuite"
import { getCampaignStatsForDashboard } from "src/micro/channel/api-channel"
import "./totalInteractions.scss"
import { formatedStringDate } from "src/common/utils/Utils"
import CommonDateRangePicker from "src/common/commonDateRangePicker/common-dateRangePicker"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

type AdsGraph = {
  type: "impression" | "click"
  title: string
  CommonDateRangePicker?: any
  dateFilterRange?: any
  setDateFilterRange?: any
  handleDateRange?: any
}

const chartData = {
  labels: ["", "", "", "", "", "", "", "", ""],
  datasets: [
    {
      label: "",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      fill: true,
      borderColor: "#00ab86",
      backgroundColor: "rgba(76, 175, 80, 0.2)"
    }
  ]
}
// const { afterToday } = DateRangePicker
const TotalInteractionsCard: React.FC<AdsGraph> = ({ type, title, dateFilterRange, setDateFilterRange, handleDateRange }: AdsGraph) => {
  const [isFetching, setIsFetching] = useState(false)
  const [allowDateRange, setAllowDateRange] = useState(false)
  const [graphData, setGraphData] = useState<any>(chartData)
  const options = {
    scales: {
      y: {
        min: 0,
        // stepSize: 25,
        // max: 100,
        ticks: {
          precision: 0,
          beginAtZero: true
          // stepSize: 2
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      line: {
        tension: 0.4,
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 255, 0.2)",
        borderWidth: 1.5
      }
    },
    responsive: true,
    maintainAspectRatio: false
  }

  const fetchChartData = async (type: string, fromDate: string, toDate: string) => {
    try {
      setIsFetching(true)
      const data = await getCampaignStatsForDashboard(type, fromDate, toDate)
      if (data && Object.hasOwn(data, "hasError")) {
        setGraphData(chartData)
      } else {
        setGraphData({
          labels: data.labels,
          datasets: [
            {
              ...graphData.datasets[0],
              data: type === "click" ? data?.clicks : data.impressions
            }
          ]
        })
      }
      setIsFetching(false)
    } catch (error) {
      setGraphData(chartData)
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (type) {
      const fromDate = title === "Ads Impressions" ? dateFilterRange.fromDateImps : dateFilterRange.fromDateInts
      const toDate = title === "Ads Impressions" ? dateFilterRange.toDateImps : dateFilterRange.toDateInts

      fetchChartData(type, fromDate, toDate)
    }
  }, [dateFilterRange.fromDateInts, dateFilterRange.toDateInts])

  return (
    <div className='web__audience-cardCotnent adsContent-cardArea'>
      <div className='adsContent-card-contentArea'>
        <div className='adsLeftContent'>
          <p className='adsLeftContentTitle'>{title}</p>
          <p className='subText'>
            {" "}
            *Showing data for {formatedStringDate(dateFilterRange?.fromDateInts)} ~ {formatedStringDate(dateFilterRange?.toDateInts)}
          </p>
        </div>
        <div className='adsRightContent'>
          <div className='adsCampaign-header-right'>
            {" "}
            <button onClick={() => setAllowDateRange(!allowDateRange)} className='transparent-button-new'>
              <svg
                style={{ marginRight: "10px" }}
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
              >
                <path
                  d='M1 2.3C1 2.01997 1 1.87996 1.0545 1.773C1.10243 1.67892 1.17892 1.60243 1.273 1.5545C1.37996 1.5 1.51997 1.5 1.8 1.5H10.2C10.48 1.5 10.62 1.5 10.727 1.5545C10.8211 1.60243 10.8976 1.67892 10.9455 1.773C11 1.87996 11 2.01997 11 2.3V2.6347C11 2.7691 11 2.83629 10.9836 2.89878C10.969 2.95415 10.9451 3.00662 10.9128 3.05388C10.8763 3.10722 10.8255 3.15123 10.7239 3.23925L7.52606 6.01075C7.42449 6.09877 7.37371 6.14278 7.33725 6.19612C7.30493 6.24338 7.28098 6.29585 7.26642 6.35122C7.25 6.41371 7.25 6.4809 7.25 6.6153V9.22919C7.25 9.32697 7.25 9.37586 7.23423 9.41813C7.22029 9.45548 7.19763 9.48895 7.16813 9.51576C7.13474 9.54611 7.08934 9.56426 6.99856 9.60058L5.29856 10.2806C5.11478 10.3541 5.0229 10.3908 4.94914 10.3755C4.88463 10.3621 4.82803 10.3238 4.79163 10.2689C4.75 10.2061 4.75 10.1071 4.75 9.90919V6.6153C4.75 6.4809 4.75 6.41371 4.73358 6.35122C4.71902 6.29585 4.69507 6.24338 4.66275 6.19612C4.62629 6.14278 4.57551 6.09877 4.47394 6.01075L1.27606 3.23925C1.17449 3.15123 1.12371 3.10722 1.08725 3.05388C1.05493 3.00662 1.03098 2.95415 1.01642 2.89878C1 2.83629 1 2.7691 1 2.6347V2.3Z'
                  stroke='#1D3D70'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
              Filter
            </button>
            {allowDateRange && (
              <CommonDateRangePicker
                allowDateRange={allowDateRange}
                fromDate={dateFilterRange.fromDateInts}
                toDate={dateFilterRange.toDateInts}
                handleDateRange={handleDateRange}
                additionalParameter='Interactions'
              />
            )}
          </div>
        </div>
      </div>

      <div className='__chart_resize' style={{ height: "75%" }}>
        {isFetching ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Loader />
            <div className='text-muted mt-2'>fetching data.. please wait...</div>
          </div>
        ) : (
          <Line options={options} data={graphData} />
        )}
      </div>
    </div>
  )
}
export default TotalInteractionsCard
