import Channel from "src/micro/channel/Channel"
import "./providers.scss"
import { providerListAd } from "./api-provider"
import { useEffect, useState } from "react"
import LoadingComponent from "src/common/components/loading/Loading"
import { Redirect } from "react-router-dom"

interface AdsProviderType {
  created_at: string | null
  is_active: boolean | null
  id: number
  logo: string
  updated_by_id: number | null
  created_by_id: number | null
  is_deleted: boolean | null
  updated_at: string | null
  name: string
  description: string
}

interface providerTypes {
  UserProviderAssociation: any
  id: number
  name: string
  description: string
  logo: string
}

const Providers = ({ initializing, hasCreds }: { initializing: boolean; hasCreds: boolean }) => {

  const [providerData, setProviderData] = useState<providerTypes[]>([])
  const [flag, setFlag] = useState()
  const [loading, setLoading] = useState(false)

  const providerListFunc = async () => {
    setLoading(true)
    try {
      const result = await providerListAd()
      setProviderData(result.data)
      setFlag(result.data[0]?.UserProviderAssociation?.is_active)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    providerListFunc()
  }, [flag])

  return (
    <div className='__providers_page_container'>
      {!initializing && !hasCreds && (
        <div className='title__div'>
          <h4>Which Ads Providers would you like to connect ?</h4>
        </div>
      )}
      <div className='items'>
        {loading === true || initializing === true ? (
          <>
            {" "}
            <LoadingComponent />{" "}
          </>
        ) : (
          <>
            {!hasCreds ? (
              <>
                {providerData.map((providerDataIndex) => (
                  <Channel
                    userProviderAssociation={providerDataIndex?.UserProviderAssociation}
                    id={providerDataIndex.id}
                    name={providerDataIndex.name}
                    description={providerDataIndex.description}
                    imgSrc={`data:image/png;base64,` + providerDataIndex.logo}
                    classes={["cursor-pointer"]}
                    flag={flag}
                    setFlag={setFlag}
                  />
                ))}
              </>
            ) : (
              <>
                <Redirect to={"/ads"} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Providers
