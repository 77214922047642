import { roboketCrmUi } from "./routes"

export const ViewRoutes = {
  Root: "/",
  RootSub: roboketCrmUi.BASE_URL,
  Contact: roboketCrmUi.BASE_URL + "/contact",
  ContactSegment: roboketCrmUi.BASE_URL + "/contacts/segments",
  Company: roboketCrmUi.BASE_URL + "/company",
  Settings: roboketCrmUi.BASE_URL + "/settings",
  Tickets: roboketCrmUi.BASE_URL + "/tickets",
  Campaign: roboketCrmUi.Ads + "/",
  Import: roboketCrmUi.BASE_URL + "/import",
  Conversion: roboketCrmUi.BASE_URL + "/conversion",
  Account: roboketCrmUi.BASE_URL + "/account",
  Snippets: roboketCrmUi.BASE_URL + "/snippets",
  Inbox: roboketCrmUi.BASE_URL + "/inbox",
  Email: "/email",
  EmailTemplate: "/email-template",
  WebAds: roboketCrmUi.BASE_URL + "/webad",
  Meetings: roboketCrmUi.BASE_URL + "/meetings",
  Products: roboketCrmUi.BASE_URL + "/product",
  Kpi: roboketCrmUi.BASE_URL + "/kpi",
  Tasks: roboketCrmUi.BASE_URL + "/task",
  From: roboketCrmUi.BASE_URL + "/inbox/connect-channel/form",
  Ads: process.env.REACT_APP_ADS_BASE_URL_DEV,
  Dashboard: process.env.REACT_APP_ANALYTICS_BASE_URL_DEV,
  Report: process.env.REACT_APP_ANALYTICS_BASE_URL_DEV + "/create-report",
  Attendance: roboketCrmUi.BASE_URL + "/attendance"
}
