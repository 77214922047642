import { sidebarType } from "./sidebar.types";

export const INITIAL_STATE = {
  activeNav: "basic",
};

const SidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sidebarType.ACTIVE_NAV:
      return {
        ...state,
        activeNav: action.payload,
      };
    default:
      return state;
  }
};

export default SidebarReducer;
