import { useEffect, useRef, useState } from "react"
import { Alert, Button, Drawer, Form, Input, Schema } from "rsuite"
import "./channel.scss"
import CustomField from "src/components/custom-field/custom-field.component"
import { getProviderAd, postProviderAd, updateProviderAd } from "./api-channel"
import { Constants } from "src/common/constants"
import { useHistory } from "react-router-dom"

const { StringType } = Schema.Types

const model = Schema.Model({
  token: StringType().isRequired("Token is required."),
  apiKey: StringType().isRequired("ApiKey is required."),
  advertisableId: StringType().isRequired("Advertisable Id is required.")
})

const DrawerComponent = (props: any) => {
  const { drawerOpen, setDrwaerOpen, name, description, image, id, flag, drawerActiveFunc, drawerDisconnectFunc } = props
  const formRef: any = useRef(null)
  const [formError, setFormError] = useState<any>({})
  const [providerToken, setProviderToken] = useState("")
  const [providerApiKey, setProviderApiKey] = useState("")
  const [providerId, setProviderId] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const drawerCloseFunc = () => {
    setDrwaerOpen(false)
  }

  const handleTokenChange = (value: string) => {
    setProviderToken(value)
  }

  const handleAdvertisableIdChange = (value: string) => {
    setProviderId(value)
  }

  const handleApiKeyChange = (value: string) => {
    setProviderApiKey(value)
  }

  const drawerUpdateFunc = async () => {
    // const result=updateProviderAd(form,id)
    // setDrwaerOpen(false)

    if (!formRef.current.check()) {
      Alert.error("form invalid", Constants.Error_Alert_Constant_Time)
      return
    }

    const payload = {
      apikey: providerApiKey,
      personal_access_token: "Token" + " " + providerToken,
      advertisable: providerId
    }
    try {
      const formSubmit = await updateProviderAd(id, payload)

      if (formSubmit.status === 200) {
        Alert.success("Success")
        history.push("/ads")
        return
      }
    } catch (error: any) {
      Alert.error(error?.response?.data?.detail)
    }
  }

  const handleOk = async () => {
    setIsLoading(true)
    if (!formRef.current.check()) {
      Alert.error("form invalid", Constants.Error_Alert_Constant_Time)
      return
    }

    const payload = {
      provider_id: id,
      credential: {
        apikey: providerApiKey,
        personal_access_token: "Token" + " " + providerToken,
        advertisable: providerId
      }
    }
    try {
      const formSubmit = await postProviderAd(payload)

      if (formSubmit.status === 200) {
        Alert.success("AdRoll Provider Connected Successfully")
        localStorage.setItem("selectedMenuItem", "dashboard")
        history.push("/ads")
        return
      }
    } catch (error: any) {
      Alert.error(error.response.data.detail)
    } finally {
      setIsLoading(false)
    }
  }

  const getProviderById = async () => {
    const result: any = await getProviderAd(id)
    setProviderToken(result.data?.UserProviderAssociation?.credential.personal_access_token)
    setProviderApiKey(result.data?.UserProviderAssociation?.credential.apikey)
    setProviderId(result.data?.UserProviderAssociation?.credential.advertisable)
  }

  useEffect(() => {
    getProviderById()
  }, [])

  return (
    <>
      <Drawer show={drawerOpen} onHide={drawerCloseFunc} className='__generic_modal'>
        <Drawer.Header className='modal-header-custom'>
          <Drawer.Title> {name}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className='text-center' style={{ marginBottom: "8%" }}>
            <img width='80' height='80' alt='' src={image} />
            <p className='drawerLogoTitle'>{name} </p>
            <p>{description} </p>
          </div>
          <Form
            model={model}
            className='form-c'
            fluid
            ref={formRef}
            onCheck={(formError) => {
              setFormError(formError)
            }}
          >
            <label>Token</label>

            <CustomField
              name='token'
              accepter={Input}
              size='lg'
              value={providerToken ? providerToken.split(" ")[1] : providerToken}
              onChange={handleTokenChange}
              error={formError.providerToken}
            />

            <label>Api key</label>
            <CustomField
              name='apiKey'
              accepter={Input}
              size='lg'
              value={providerApiKey}
              onChange={handleApiKeyChange}
              error={formError.providerApiKey}
            />

            <label>Advertisable Id</label>

            <CustomField
              name='advertisableId'
              accepter={Input}
              size='lg'
              value={providerId}
              onChange={handleAdvertisableIdChange}
              error={formError.providerId}
            />
          </Form>
        </Drawer.Body>
        <Drawer.Footer>
          {flag != undefined ? (
            <>
              {flag === true ? (
                <Button onClick={drawerDisconnectFunc} color='red' appearance='primary'>
                  Disconnect
                </Button>
              ) : (
                <Button onClick={drawerActiveFunc} color='green' appearance='primary'>
                  Active
                </Button>
              )}
            </>
          ) : (
            <></>
          )}

          {flag === undefined ? (
            <>
              <Button style={{ padding: "8px 20px" }} disabled={isLoading} onClick={handleOk} color='green' appearance='primary'>
                {isLoading ? "Submiting..." : "Submit"}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={drawerUpdateFunc} appearance='primary'>
                Update
              </Button>
            </>
          )}

          <Button onClick={drawerCloseFunc} appearance='subtle'>
            Cancel
          </Button>
        </Drawer.Footer>
      </Drawer>
    </>
  )
}

export default DrawerComponent
