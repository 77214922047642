import flagIcon from "../../../assets/svgs/flagIcon.svg"
import upIcon from "../../../assets/svgs/upIconFat.svg"

type MessengerStoryType = {
  file: any
  title: string
  flag?: string
  widthI?: string
}

const MarketPlace = (props: MessengerStoryType) => {
  const { file, title, flag,widthI } = props
  
  return (
    <>
      <div className={widthI==='width'? 'adDiffMarketWithI':'adDiffMarket'}>
        <div className='ar-ad-manager-fb-preview-img-wrap'>
        <img src={flag === "flag" ? file : URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "2%" }}>
          <img src={upIcon} alt='up icon' />
          <p>OPENS WEBSITE</p>
        </div>
        <p className='tilePanelMarket'> {title.length === 0 ? "Your Title" : title}</p>

        <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
          <div className='ar-ad-manager-fb-preview-placeholder-tiny'>
            <img
              src={flagIcon}
              alt='flagIcon'
              style={{
                alignItems: "center",
                width: "17px"
              }}
            />
          </div>
          <div className='marketplaceTitle'>
            <p className='marketTitle'>Roboket</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default MarketPlace
