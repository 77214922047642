import React from "react"

import "./insights.scss"

type InsightsProps = {
  isAllowInsights?: boolean
  setIsAllowInsights?: ((value: boolean) => void) | undefined
  loading?: any
  deviceData?: any
  multipleData?: any
  osData?: any
  webData?: any
  referrerData?: any
  countryData?: any
  languageData?: any
}

import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Label, Tooltip } from "recharts"
import { Col, Divider, Loader, Row } from "rsuite"
import CustomProgressBar from "src/common/components/ProgressBar.component"
import { noDataMessage } from "src/common/helpers/helpers"

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]

const Bullet = ({ backgroundColor, size }: { backgroundColor: string; size: string }) => {
  return (
    <div
      className='CirecleBullet'
      style={{
        backgroundColor,
        width: size,
        height: size,
        marginTop: "5px"
      }}
    ></div>
  )
}

const CustomizedLegend = (props: any) => {
  const { payload } = props
  return (
    <ul className='LegendList'>
      {payload.map((entry: any, index: any) => (
        <li key={`item-${index}`}>
          <div className='BulletLabel'>
            <Bullet backgroundColor={entry.payload.fill} size='10px' />
            <div className='BulletLabelText'>{entry.value}</div>
          </div>
        </li>
      ))}
    </ul>
  )
}

const Insights: React.FC<InsightsProps> = (props) => {
  const { loading, deviceData, multipleData, osData, webData, referrerData, countryData, languageData } = props

  return (
    <>
      <div className='web__connectedAppsPageArea'>
        <h5>Technology</h5>
        <Row>
          <Col lg={8}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>Device</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content'>
                  <div style={{ width: "100%", height: 420, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {loading.device ? (
                      <Loader content='Loading...' vertical size='md' />
                    ) : (
                      <ResponsiveContainer>
                        {deviceData.length === 0 ? (
                          <div> {noDataMessage()} </div>
                        ) : (
                          <PieChart>
                            <Legend className='audiences__chartLegend' content={<CustomizedLegend />} />

                            <Pie data={deviceData} dataKey='value' cx={200} cy={200} innerRadius={80} outerRadius={120}>
                              {deviceData?.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>

                            <Tooltip />
                          </PieChart>
                        )}
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>Desktop vs Mobile</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content'>
                  <div
                    style={{
                      width: "100%",
                      height: 420,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    {loading.multi ? (
                      <Loader content='Loading...' vertical size='md' />
                    ) : (
                      <div className='web__progressBarArea'>
                        {multipleData && multipleData.length === 0 ? (
                          <div>{noDataMessage()}</div>
                        ) : (
                          multipleData.map((item: any, index: number) => (
                            <div className='web__progressBarAreaContent' key={index}>
                              <h5>{item?.name}</h5>
                              <CustomProgressBar bgcolor={COLORS[index % COLORS.length]} completed={item?.value} />
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>Operating Systems</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content'>
                  <div style={{ width: "100%", height: 420, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {loading.os ? (
                      <Loader content='Loading...' vertical size='md' />
                    ) : (
                      <ResponsiveContainer>
                        {osData.length === 0 ? (
                          <div> {noDataMessage()} </div>
                        ) : (
                          <PieChart>
                            <Legend content={<CustomizedLegend />} />
                            <Pie data={osData} dataKey='value' cx={200} cy={200} innerRadius={80} outerRadius={120}>
                              {osData?.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                          </PieChart>
                        )}
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>Web Browser</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content'>
                  <div style={{ width: "100%", height: 420, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {loading.web ? (
                      <Loader content='Loading...' vertical size='md' />
                    ) : (
                      <ResponsiveContainer>
                        {webData.length === 0 ? (
                          <div> {noDataMessage()} </div>
                        ) : (
                          <PieChart>
                            <Legend content={<CustomizedLegend />} />
                            <Pie data={webData} dataKey='value' cx={200} cy={200} innerRadius={80} outerRadius={120}>
                              {webData?.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                          </PieChart>
                        )}
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={16}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>Referrer</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content web__insights-card-content-position'>
                  <div
                    style={{
                      width: "100%",
                      height: 420,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    {loading.referrer ? (
                      <Loader content='Loading...' vertical size='md' />
                    ) : (
                      <div className='web__progressBarArea'>
                        {referrerData && referrerData.length === 0 ? (
                          <div>{noDataMessage()}</div>
                        ) : (
                          referrerData?.map((item: any, index: number) => (
                            <div className='web__progressBarAreaContent' key={index}>
                              <h5>{item?.name}</h5>
                              <CustomProgressBar bgcolor={COLORS[index % COLORS.length]} completed={item?.value} />
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className='web__connectedAppsPageArea' style={{ marginTop: "30px" }}>
        <h5>Demographics</h5>
        <Row>
          <Col lg={12}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>Country</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content web__insights-card-content-position'>
                  <div
                    style={{
                      width: "100%",
                      height: 420,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    {loading.country ? (
                      <Loader content='Loading...' vertical size='md' />
                    ) : (
                      <div className='web__progressBarArea'>
                        {countryData && countryData.length === 0 ? (
                          <div>{noDataMessage()}</div>
                        ) : (
                          countryData?.map((item: any, index: number) => (
                            <div className='web__progressBarAreaContent' key={index}>
                              <h5>{item?.name}</h5>
                              <CustomProgressBar bgcolor={COLORS[index % COLORS.length]} completed={item?.value} />
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>Language</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content web__insights-card-content-position'>
                  <div
                    style={{
                      width: "100%",
                      height: 420,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    {loading.language ? (
                      <Loader content='Loading...' vertical size='md' />
                    ) : (
                      <div className='web__progressBarArea'>
                        {countryData && countryData.length === 0 ? (
                          <div>{noDataMessage()}</div>
                        ) : (
                          languageData?.map((item: any, index: number) => (
                            <div className='web__progressBarAreaContent' key={index}>
                              <h5>{item?.name}</h5>
                              <CustomProgressBar bgcolor={COLORS[index % COLORS.length]} completed={item?.value} />
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {/* <Col lg={24}>
            {" "}
            <div className='web__insights-area'>
              <div className='web__insights-card-area'>
                <h5>World</h5>
                <div className='web__insight-dividers'>
                  {" "}
                  <Divider />
                </div>
                <div className='web__insights-card-content'>
                  <div style={{ width: "100%", height: 420 }}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Legend content={<CustomizedLegend />} />
                        <Pie data={referrerData} dataKey='value' cx={200} cy={200} innerRadius={80} outerRadius={100}>
                          {referrerData?.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                          <Label content={<CustomLabel labelText='ICPs' value={15} viewBox={undefined} />} position='center' />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  )
}

export default Insights
