// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__providers_page_container {
  min-height: calc(100vh - 120px);
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.__providers_page_container .title__div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.parent {
  text-align: center;
}

.title {
  margin-top: 30px;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 35px;
}`, "",{"version":3,"sources":["webpack://./src/pages/provider/providers.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAAF","sourcesContent":[".__providers_page_container {\n  min-height: calc(100vh - 120px);\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  .title__div {\n    display: flex;\n    justify-content: center;\n    margin-top: 30px;\n  }\n}\n\n.parent {\n  text-align: center;\n}\n\n.title {\n  margin-top: 30px;\n}\n\n.items {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  margin-top: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
