import React from "react"
import { intersection } from "lodash"
import { RoleViewMapperConfig } from "../config/RoleViewMapperConfig"
import { Dropdown, Toggle } from "rsuite"
import { GetAllPermissionList, GetCurrentUserPermissionList } from "../components/api-common"

export function isLoggedIn() {
  return !!localStorage.getItem("token")
}

export function isArrayWithLength(arr: any) {
  return Array.isArray(arr) && arr.length
}

async function getAllPermission() {
  const permissions = await GetAllPermissionList()
  if (permissions) {
    let permissionNames = permissions.data.map((s: any) => s.name)
    return permissionNames
  }
  return []
}

export async function getAllowedRoutesNav(allowedPermissions: string[], history: any) {
  const allPermissions = await getAllPermission()
  const filtered = allowedPermissions.filter(({ permission_names, method }: any) => {
    if (!permission_names) {
      return true
    } else if (!isArrayWithLength(permission_names)) {
      return true
    } else {
      let val = intersection(permission_names, allPermissions)
      return val.length
    }
  })

  const mappers = [] as any[]

  RoleViewMapperConfig.forEach((mapper) => {
    if (filtered.includes(mapper.name)) {
      mappers.push(mapper)
    }
  })

  const parentNavs = mappers.filter((mapper) => {
    if (mapper && !("parent" in mapper) && mapper.navVisibility) {
      return true
    }
    return false
  })

  var navsMappers = [] as any[]
  parentNavs.forEach((nav) => {
    const navChilds = mappers.filter((s) => s?.parent === nav?.name)
    navsMappers.push({ nav: nav, childs: navChilds })
  })

  var navs = navsMappers.map((navMap, index: number) => {
    return (
      <Dropdown title={navMap.nav.title} key={index} className='DropDownMod'>
        {navMap.childs.map((childMap: any, index: number) => (
          <Dropdown.Item
            key={index}
            onSelect={() => {
              let path = ""

              if (Array.isArray(childMap.path)) {
                path = childMap.path[0]
              } else {
                path = childMap.path
              }

              if (childMap.hyperlink) {
                window.location.href = path
              } else {
                history.push(path)
              }
            }}
          >
            {childMap.title}
          </Dropdown.Item>
        ))}
      </Dropdown>
    )
  })

  return navs
}

export const getUserPermissions = async () => {
  const permissions = await GetCurrentUserPermissionList()
  if (permissions !== null && permissions.permission_names.length) {
    var perms = permissions.permission_names
    if (process.env.REACT_APP_PERMISSION_TEST_MODE) {
      perms = permissions.permission_names.concat([
        "import_access",
        "import_google_access",
        "import_file_access",
        "tour_access",
        "notification_access",
        "contact_company_setting_access",
        "contact_property_assign_access",
        "company_property_assign_access",
        "setting_access",
        "conversation_menu_access",
        "email_connect_access",
        "page_connect_access",
        "website_chat_connect_access",
        "webform_connect_access",
        "chat_flow_setting_access",
        "chat_flow_access",
        "sales_menu_access",
        "fb_page_for_chat_access",
        "account_access",
        "integrations_setting_access",
        "role_permission_setting_access",
        "email_campaign",
        "fb_campaign"
      ])
    }
    return perms
  }
  return []
}

export const usePrevious = (value: any) => {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const getCurrentUserToken = () => {
  const token = localStorage.getItem("token")
  return token
}

export const resizeImage = (image: HTMLImageElement, width: number, height: number): Promise<HTMLCanvasElement> => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")

    canvas.width = width
    canvas.height = height

    ctx?.drawImage(image, 0, 0, width, height)
    resolve(canvas)
  })
}

export const convertImageToBase64 = async (imageFile: any, width: number, height: number) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = async () => {
      const base64String = reader.result?.toString()

      if (!base64String) {
        reject(new Error("Invalid base64 format"))
        return
      }

      const validExtensions = ["jpg", "jpeg", "png", "svg"]
      const extensionRegex = /\.(jpg|jpeg|png|svg)$/i
      const isValidExtension = imageFile.name.match(extensionRegex)

      if (isValidExtension) {
        const img = new Image()
        img.src = base64String as string

        img.onload = async () => {
          const canvas = await resizeImage(img, width, height)
          const resizedBase64String = canvas.toDataURL().replace(/^data:image\/(jpeg|jpg|png|svg);base64,/, "")
          resolve(resizedBase64String)
        }

        img.onerror = reject
      } else {
        reject(new Error("Invalid file format. Please upload a jpg, jpeg, png, or svg file."))
      }
    }

    reader.onerror = reject
    reader.readAsDataURL(imageFile)
  })
}

export const convertImageToBase64Ad = async (imageFile: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const base64String = reader.result?.toString()

      if (!base64String) {
        reject(new Error("Invalid base64 format"))
        return
      }

      const validExtensions = ["jpg", "jpeg", "png", "svg"]
      const extensionRegex = /\.(jpg|jpeg|png|svg)$/i
      const isValidExtension = imageFile.name.match(extensionRegex)

      if (isValidExtension) {
        const trimmedBase64Image = base64String.replace(/^data:image\/(jpeg|jpg|png|svg);base64,/, "")
        resolve(trimmedBase64Image)
      } else {
        reject(new Error("Invalid file format. Please upload a jpg, jpeg, or png file."))
      }
    }

    reader.onerror = reject

    reader.readAsDataURL(imageFile)
  })
}

export const AdTechAdvertisableEID: string = process.env.REACT_APP_ADTECH_DEFAULT_ADVERTISER_ID || ""
export const AdTechToken: string = process.env.REACT_APP_ADTECH_API_KEY || ""

export const columnsForAudienceList = [
  {
    name: "Audience Name",
    sortable: true,
    selector: (row: any) => {
      return <div>{row?.name}</div>
    },
    width: "180px"
  },
  {
    name: "Target of Exclude",
    sortable: true,
    selector: (row: any) => {
      return (
        <div>
          {" "}
          <Toggle defaultChecked={row?.is_active} /> Targeting{" "}
        </div>
      )
    },
    width: "180px"
  },

  {
    name: "Audience Size",
    sortable: true,
    selector: (row: any) => {
      return <div>{row?.total_emails ? <> {row?.total_emails} </> : <> - </>}</div>
    }
  },

  {
    name: "Duration",
    sortable: true,
    selector: (row: any) => {
      return <div>{row?.duration}</div>
    }
  }
]

export const columnsForAudienceListFb = [
  {
    name: "Audience Name",
    sortable: true,
    selector: (row: any) => {
      return <div>{row?.name}</div>
    },
    width: "180px"
  },

  {
    name: "Audience Size",
    sortable: true,
    selector: (row: any) => {
      return <div>{row?.approximate_count}</div>
    }
  },

  {
    name: "Duration",
    sortable: true,
    selector: (row: any) => {
      return <div>{row?.retention_days}</div>
    }
  }
]

export const formatedStringDate = (dateString: any) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const date = new Date(dateString)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  const suffix = day === 1 || day === 21 || day === 31 ? "st" : day === 2 || day === 22 ? "nd" : day === 3 || day === 23 ? "rd" : "th"

  return `${day}${suffix} ${month} ${year}`
}
export const getMetricName = (reportType: string) => {
  switch (reportType) {
    case "clicks":
      return "Clicks"
    case "impressions":
      return "Impressions"
    case "cost":
      return "Spend"
    case "cpc":
      return "Cpc"
    case "cpm":
      return "Cpm"
    case "bounceRate":
      return "Bounce Rate"
    default:
      return reportType
  }
}
export const getTodayDate = () => {
  const date: string = new Date().toISOString()
  const dateObject = new Date(date)
  return dateObject.toISOString().split("T")[0]
}
export const getTomorrowDate = () => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  const formattedTomorrow = tomorrow.toISOString().split("T")[0]
  return formattedTomorrow
}
export const getLast30DaysDate = () => {
  const today = new Date()
  const thirtyDaysAgo = new Date(today)
  thirtyDaysAgo.setDate(today.getDate() - 30)

  const formattedDate = thirtyDaysAgo.toISOString().split("T")[0]
  return formattedDate
}

export const getLastNDaysDate = (daysAgo: any) => {
  const today = new Date()
  const pastDate = new Date(today)
  pastDate.setDate(today.getDate() - daysAgo)

  const formattedDate = pastDate.toISOString().split("T")[0]
  return formattedDate
}

export const getFormattedDate = (date: string) => {
  const dateString = date
  const dateObject = new Date(dateString)
  return dateObject.toISOString().split("T")[0]
}
export const getFirstDayOfMonth = () => {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  return getFormattedDate(firstDayOfMonth.toISOString())
}

export const getDaysAgoFromFormattedDate = (formattedDate: string) => {
  const pastDate = new Date(formattedDate)
  const today = new Date()
  const daysAgo = Math.floor((today.getTime() - pastDate.getTime()) / (1000 * 60 * 60 * 24))

  return ("" + daysAgo) as string
}

const getWeekRange = () => {
  const today = new Date()
  const currentDay = today.getDay()
  const diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1) // Adjust for Sunday
  const startOfCurrentWeek = new Date(today.setDate(diff))
  const endOfCurrentWeek = new Date(startOfCurrentWeek)
  endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() + 6)

  // Calculate start of last week
  const startOfLastWeek = new Date(startOfCurrentWeek)
  startOfLastWeek.setDate(startOfLastWeek.getDate() - 7)

  // Calculate end of last week
  const endOfLastWeek = new Date(endOfCurrentWeek)
  endOfLastWeek.setDate(endOfLastWeek.getDate() - 7)

  return {
    currentWeek: {
      start: getFormattedDate(startOfCurrentWeek.toISOString()),
      end: getFormattedDate(endOfCurrentWeek.toISOString())
    },
    lastWeek: {
      start: getFormattedDate(startOfLastWeek.toISOString()),
      end: getFormattedDate(endOfLastWeek.toISOString())
    }
  }
}
export const weekRange = getWeekRange()

export const formatDate = (date: string) => {
  const formattedDate = new Date(date)
  const year = formattedDate.getFullYear()
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0")
  const day = String(formattedDate.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const stringToSubstringSpreed = (value: string, count: number) => {
  if (value.length > count) return value.substring(0, count - 1) + " . . ."
  return value
}
